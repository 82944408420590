@font-face {
  font-family: tablet_gothic_compressed;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "tablet_gothic_condensed", arial, Helvetica, sans-serif;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: tablet_gothic_condensed_extra;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  color: #16212d;
  overflow-anchor: none;
}

a {
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  text-decoration: underline;
}

div {
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.sectionHeader {
  border-top: 4px solid red;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 10px 0;
  position: relative;
  overflow: visible;
}

.banner_frantiska {
  float: left;
  margin: 10px 0;
  position: relative;
}

.banner_frantiska .imageWrapper {
  width: 620px;
  height: 126px;
}

.banner_zpravy {
  float: left;
  margin: 10px 0;
  position: relative;
}

#sticky-bottom-right-absolute > div {
  margin-top: 20px;
  float: left !important;
  position: relative !important;
  bottom: auto !important;
}

#article a {
  text-decoration: underline;
  transition: all .3s;
}

#article a:hover {
  text-decoration: none;
}

.container.whitecolor h3.title.bg {
  background: #fff !important;
}

.container.tvblack h3.title.bg {
  background: #16212d !important;
}

.container.pureBlack h3.title.bg {
  background: #000 !important;
}

.container.tvblacklighter h3.title.bg {
  background: #232b3a !important;
}

.container.redChristmas h3.title.bg {
  background: #b00 !important;
}

.container.pinkChristmas h3.title.bg {
  background: #ff8a8a !important;
}

.container.default h3.title.bg {
  background: #000 !important;
}

.container.grey h3.title.bg {
  background: #c9d3df !important;
}

.container.system h3.title.bg {
  background: red !important;
}

.container.colorHoverMenu h3.title.bg {
  background: #540101 !important;
}

.container.auto h3.title.bg, .container.pro-zeny h3.title.bg {
  background: red !important;
}

.container.sport h3.title.bg {
  background: #91c837 !important;
}

.container.svet h3.title.bg {
  background: #6e78dc !important;
}

.container.tvbox h3.title.bg {
  background: #fff !important;
}

.container.celebrity h3.title.bg {
  background: red !important;
}

.container.greyLight h3.title.bg {
  background: #c0ced5 !important;
}

.container.grey-comments h3.title.bg {
  background: #98a3af !important;
}

.container.bleskRed h3.title.bg, .container.colorTesty h3.title.bg {
  background: red !important;
}

.container.heliotrope h3.title.bg {
  background: #b469f5 !important;
}

.container.chetwode h3.title.bg {
  background: #083e89 !important;
}

.container.robinblue h3.title.bg {
  background: #00c8be !important;
}

.container.hotpink h3.title.bg {
  background: #fe60b2 !important;
}

.container.tyrkys h3.title.bg {
  background: #2dccd3 !important;
}

.container.saffron h3.title.bg {
  background: #f7b135 !important;
}

.container.jade h3.title.bg {
  background: #00b373 !important;
}

.container.scooter h3.title.bg {
  background: #3cc3dc !important;
}

.container.dodger h3.title.bg {
  background: #23aaff !important;
}

.container.domino h3.title.bg {
  background: #ff5a00 !important;
}

.container.atlantis h3.title.bg {
  background: #91c837 !important;
}

.container.homeorange h3.title.bg {
  background: #ff7300 !important;
}

.container.commercial h3.title.bg {
  background: #f1e9c1 !important;
}

.whitecolor .category-title, .whitecolor a.color, .whitecolor .cat-name, .whitecolor a.hoverColor:hover, .whitecolor .article-container:hover, .whitecolor .article-container:hover a.hoverColor {
  color: #fff !important;
}

.whitecolor .sectionHeader {
  border-top-color: #fff !important;
}

.whitecolor .alternative a.color {
  color: #fff !important;
}

.whitecolor .bg {
  background-color: #fff !important;
}

.tvblack .category-title, .tvblack a.color, .tvblack .cat-name, .tvblack a.hoverColor:hover, .tvblack .article-container:hover, .tvblack .article-container:hover a.hoverColor {
  color: #16212d !important;
}

.tvblack .sectionHeader {
  border-top-color: #16212d !important;
}

.tvblack .alternative a.color {
  color: #fff !important;
}

.tvblack .bg {
  background-color: #16212d !important;
}

.pureBlack .category-title, .pureBlack a.color, .pureBlack .cat-name, .pureBlack a.hoverColor:hover, .pureBlack .article-container:hover, .pureBlack .article-container:hover a.hoverColor {
  color: #000 !important;
}

.pureBlack .sectionHeader {
  border-top-color: #000 !important;
}

.pureBlack .alternative a.color {
  color: #fff !important;
}

.pureBlack .bg {
  background-color: #000 !important;
}

.tvblacklighter .category-title, .tvblacklighter a.color, .tvblacklighter .cat-name, .tvblacklighter a.hoverColor:hover, .tvblacklighter .article-container:hover, .tvblacklighter .article-container:hover a.hoverColor {
  color: #232b3a !important;
}

.tvblacklighter .sectionHeader {
  border-top-color: #232b3a !important;
}

.tvblacklighter .alternative a.color {
  color: #fff !important;
}

.tvblacklighter .bg {
  background-color: #232b3a !important;
}

.redChristmas .category-title, .redChristmas a.color, .redChristmas .cat-name, .redChristmas a.hoverColor:hover, .redChristmas .article-container:hover, .redChristmas .article-container:hover a.hoverColor {
  color: #b00 !important;
}

.redChristmas .sectionHeader {
  border-top-color: #b00 !important;
}

.redChristmas .alternative a.color {
  color: #fff !important;
}

.redChristmas .bg {
  background-color: #b00 !important;
}

.pinkChristmas .category-title, .pinkChristmas a.color, .pinkChristmas .cat-name, .pinkChristmas a.hoverColor:hover, .pinkChristmas .article-container:hover, .pinkChristmas .article-container:hover a.hoverColor {
  color: #ff8a8a !important;
}

.pinkChristmas .sectionHeader {
  border-top-color: #ff8a8a !important;
}

.pinkChristmas .alternative a.color {
  color: #fff !important;
}

.pinkChristmas .bg {
  background-color: #ff8a8a !important;
}

.default .category-title, .default a.color, .default .cat-name, .default a.hoverColor:hover, .default .article-container:hover, .default .article-container:hover a.hoverColor {
  color: #000 !important;
}

.default .sectionHeader {
  border-top-color: #000 !important;
}

.default .alternative a.color {
  color: #fff !important;
}

.default .bg {
  background-color: #000 !important;
}

.grey .category-title, .grey a.color, .grey .cat-name, .grey a.hoverColor:hover, .grey .article-container:hover, .grey .article-container:hover a.hoverColor {
  color: #c9d3df !important;
}

.grey .sectionHeader {
  border-top-color: #c9d3df !important;
}

.grey .alternative a.color {
  color: #fff !important;
}

.grey .bg {
  background-color: #c9d3df !important;
}

.system .category-title, .system a.color, .system .cat-name, .system a.hoverColor:hover, .system .article-container:hover, .system .article-container:hover a.hoverColor {
  color: red !important;
}

.system .sectionHeader {
  border-top-color: red !important;
}

.system .alternative a.color {
  color: #fff !important;
}

.system .bg {
  background-color: red !important;
}

.colorHoverMenu .category-title, .colorHoverMenu a.color, .colorHoverMenu .cat-name, .colorHoverMenu a.hoverColor:hover, .colorHoverMenu .article-container:hover, .colorHoverMenu .article-container:hover a.hoverColor {
  color: #540101 !important;
}

.colorHoverMenu .sectionHeader {
  border-top-color: #540101 !important;
}

.colorHoverMenu .alternative a.color {
  color: #fff !important;
}

.colorHoverMenu .bg {
  background-color: #540101 !important;
}

.auto .category-title, .auto a.color, .auto .cat-name, .auto a.hoverColor:hover, .auto .article-container:hover, .auto .article-container:hover a.hoverColor {
  color: red !important;
}

.auto .sectionHeader {
  border-top-color: red !important;
}

.auto .alternative a.color {
  color: #fff !important;
}

.auto .bg {
  background-color: red !important;
}

.pro-zeny .category-title, .pro-zeny a.color, .pro-zeny .cat-name, .pro-zeny a.hoverColor:hover, .pro-zeny .article-container:hover, .pro-zeny .article-container:hover a.hoverColor {
  color: red !important;
}

.pro-zeny .sectionHeader {
  border-top-color: red !important;
}

.pro-zeny .alternative a.color {
  color: #fff !important;
}

.pro-zeny .bg {
  background-color: red !important;
}

.sport .category-title, .sport a.color, .sport .cat-name, .sport a.hoverColor:hover, .sport .article-container:hover, .sport .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

.sport .sectionHeader {
  border-top-color: #91c837 !important;
}

.sport .alternative a.color {
  color: #fff !important;
}

.sport .bg {
  background-color: #91c837 !important;
}

.svet .category-title, .svet a.color, .svet .cat-name, .svet a.hoverColor:hover, .svet .article-container:hover, .svet .article-container:hover a.hoverColor {
  color: #6e78dc !important;
}

.svet .sectionHeader {
  border-top-color: #6e78dc !important;
}

.svet .alternative a.color {
  color: #fff !important;
}

.svet .bg {
  background-color: #6e78dc !important;
}

.tvbox .category-title, .tvbox a.color, .tvbox .cat-name, .tvbox a.hoverColor:hover, .tvbox .article-container:hover, .tvbox .article-container:hover a.hoverColor {
  color: #fff !important;
}

.tvbox .sectionHeader {
  border-top-color: #fff !important;
}

.tvbox .alternative a.color {
  color: #fff !important;
}

.tvbox .bg {
  background-color: #fff !important;
}

.celebrity .category-title, .celebrity a.color, .celebrity .cat-name, .celebrity a.hoverColor:hover, .celebrity .article-container:hover, .celebrity .article-container:hover a.hoverColor {
  color: red !important;
}

.celebrity .sectionHeader {
  border-top-color: red !important;
}

.celebrity .alternative a.color {
  color: #fff !important;
}

.celebrity .bg {
  background-color: red !important;
}

.greyLight .category-title, .greyLight a.color, .greyLight .cat-name, .greyLight a.hoverColor:hover, .greyLight .article-container:hover, .greyLight .article-container:hover a.hoverColor {
  color: #c0ced5 !important;
}

.greyLight .sectionHeader {
  border-top-color: #c0ced5 !important;
}

.greyLight .alternative a.color {
  color: #fff !important;
}

.greyLight .bg {
  background-color: #c0ced5 !important;
}

.grey-comments .category-title, .grey-comments a.color, .grey-comments .cat-name, .grey-comments a.hoverColor:hover, .grey-comments .article-container:hover, .grey-comments .article-container:hover a.hoverColor {
  color: #98a3af !important;
}

.grey-comments .sectionHeader {
  border-top-color: #98a3af !important;
}

.grey-comments .alternative a.color {
  color: #fff !important;
}

.grey-comments .bg {
  background-color: #98a3af !important;
}

.bleskRed .category-title, .bleskRed a.color, .bleskRed .cat-name, .bleskRed a.hoverColor:hover, .bleskRed .article-container:hover, .bleskRed .article-container:hover a.hoverColor {
  color: red !important;
}

.bleskRed .sectionHeader {
  border-top-color: red !important;
}

.bleskRed .alternative a.color {
  color: #fff !important;
}

.bleskRed .bg {
  background-color: red !important;
}

.colorTesty .category-title, .colorTesty a.color, .colorTesty .cat-name, .colorTesty a.hoverColor:hover, .colorTesty .article-container:hover, .colorTesty .article-container:hover a.hoverColor {
  color: red !important;
}

.colorTesty .sectionHeader {
  border-top-color: red !important;
}

.colorTesty .alternative a.color {
  color: #fff !important;
}

.colorTesty .bg {
  background-color: red !important;
}

.heliotrope .category-title, .heliotrope a.color, .heliotrope .cat-name, .heliotrope a.hoverColor:hover, .heliotrope .article-container:hover, .heliotrope .article-container:hover a.hoverColor {
  color: #b469f5 !important;
}

.heliotrope .sectionHeader {
  border-top-color: #b469f5 !important;
}

.heliotrope .alternative a.color {
  color: #fff !important;
}

.heliotrope .bg {
  background-color: #b469f5 !important;
}

.chetwode .category-title, .chetwode a.color, .chetwode .cat-name, .chetwode a.hoverColor:hover, .chetwode .article-container:hover, .chetwode .article-container:hover a.hoverColor {
  color: #083e89 !important;
}

.chetwode .sectionHeader {
  border-top-color: #083e89 !important;
}

.chetwode .alternative a.color {
  color: #fff !important;
}

.chetwode .bg {
  background-color: #083e89 !important;
}

.robinblue .category-title, .robinblue a.color, .robinblue .cat-name, .robinblue a.hoverColor:hover, .robinblue .article-container:hover, .robinblue .article-container:hover a.hoverColor {
  color: #00c8be !important;
}

.robinblue .sectionHeader {
  border-top-color: #00c8be !important;
}

.robinblue .alternative a.color {
  color: #fff !important;
}

.robinblue .bg {
  background-color: #00c8be !important;
}

.hotpink .category-title, .hotpink a.color, .hotpink .cat-name, .hotpink a.hoverColor:hover, .hotpink .article-container:hover, .hotpink .article-container:hover a.hoverColor {
  color: #fe60b2 !important;
}

.hotpink .sectionHeader {
  border-top-color: #fe60b2 !important;
}

.hotpink .alternative a.color {
  color: #fff !important;
}

.hotpink .bg {
  background-color: #fe60b2 !important;
}

.tyrkys .category-title, .tyrkys a.color, .tyrkys .cat-name, .tyrkys a.hoverColor:hover, .tyrkys .article-container:hover, .tyrkys .article-container:hover a.hoverColor {
  color: #2dccd3 !important;
}

.tyrkys .sectionHeader {
  border-top-color: #2dccd3 !important;
}

.tyrkys .alternative a.color {
  color: #fff !important;
}

.tyrkys .bg {
  background-color: #2dccd3 !important;
}

.saffron .category-title, .saffron a.color, .saffron .cat-name, .saffron a.hoverColor:hover, .saffron .article-container:hover, .saffron .article-container:hover a.hoverColor {
  color: #f7b135 !important;
}

.saffron .sectionHeader {
  border-top-color: #f7b135 !important;
}

.saffron .alternative a.color {
  color: #fff !important;
}

.saffron .bg {
  background-color: #f7b135 !important;
}

.jade .category-title, .jade a.color, .jade .cat-name, .jade a.hoverColor:hover, .jade .article-container:hover, .jade .article-container:hover a.hoverColor {
  color: #00b373 !important;
}

.jade .sectionHeader {
  border-top-color: #00b373 !important;
}

.jade .alternative a.color {
  color: #fff !important;
}

.jade .bg {
  background-color: #00b373 !important;
}

.scooter .category-title, .scooter a.color, .scooter .cat-name, .scooter a.hoverColor:hover, .scooter .article-container:hover, .scooter .article-container:hover a.hoverColor {
  color: #3cc3dc !important;
}

.scooter .sectionHeader {
  border-top-color: #3cc3dc !important;
}

.scooter .alternative a.color {
  color: #fff !important;
}

.scooter .bg {
  background-color: #3cc3dc !important;
}

.dodger .category-title, .dodger a.color, .dodger .cat-name, .dodger a.hoverColor:hover, .dodger .article-container:hover, .dodger .article-container:hover a.hoverColor {
  color: #23aaff !important;
}

.dodger .sectionHeader {
  border-top-color: #23aaff !important;
}

.dodger .alternative a.color {
  color: #fff !important;
}

.dodger .bg {
  background-color: #23aaff !important;
}

.domino .category-title, .domino a.color, .domino .cat-name, .domino a.hoverColor:hover, .domino .article-container:hover, .domino .article-container:hover a.hoverColor {
  color: #ff5a00 !important;
}

.domino .sectionHeader {
  border-top-color: #ff5a00 !important;
}

.domino .alternative a.color {
  color: #fff !important;
}

.domino .bg {
  background-color: #ff5a00 !important;
}

.atlantis .category-title, .atlantis a.color, .atlantis .cat-name, .atlantis a.hoverColor:hover, .atlantis .article-container:hover, .atlantis .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

.atlantis .sectionHeader {
  border-top-color: #91c837 !important;
}

.atlantis .alternative a.color {
  color: #fff !important;
}

.atlantis .bg {
  background-color: #91c837 !important;
}

.homeorange .category-title, .homeorange a.color, .homeorange .cat-name, .homeorange a.hoverColor:hover, .homeorange .article-container:hover, .homeorange .article-container:hover a.hoverColor {
  color: #ff7300 !important;
}

.homeorange .sectionHeader {
  border-top-color: #ff7300 !important;
}

.homeorange .alternative a.color {
  color: #fff !important;
}

.homeorange .bg {
  background-color: #ff7300 !important;
}

.dobry-vyrobek {
  width: auto;
  height: 40px;
  position: absolute;
  right: 11px;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.gam-wrapper--padding {
  padding: 20px 0;
}

.gam-wrapper.article-ad-wrapper {
  width: 620px;
  margin-left: -80px;
}

.icon-arrow-left-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-left-small.png") no-repeat;
  width: 8px;
  height: 13px;
}

.icon-arrow-right-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-right-big.png") no-repeat;
  width: 15px;
  height: 25px;
}

.icon-arrow-left-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-left-big.png") no-repeat;
  width: 15px;
  height: 25px;
}

.icon-arrow-right-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/arrow-right-small.png") no-repeat;
  width: 8px;
  height: 13px;
}

.icon-blesk_logo_medium {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_logo_medium.png") no-repeat;
  width: 154px;
  height: 70px;
}

.icon-blesk_mobil {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_mobil.png") no-repeat;
  width: 52px;
  height: 34px;
}

.icon-blesk_logo_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_logo_big.png") no-repeat;
  width: 232px;
  height: 113px;
}

.icon-blesk_srdce {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_srdce.png") no-repeat;
  width: 44px;
  height: 35px;
}

.icon-blesk_vyhledavac {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/blesk_vyhledavac.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-bleskove_zpravy_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/bleskove_zpravy_logo.png") no-repeat;
  width: 83px;
  height: 27px;
}

.icon-dnes_na_autocz {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dnes_na_autocz.png") no-repeat;
  width: 306px;
  height: 46px;
}

.icon-dnes_na_isportcz {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dnes_na_isportcz.png") no-repeat;
  width: 138px;
  height: 57px;
}

.icon-dobry_telefon {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dobry_telefon.png") no-repeat;
  width: 66px;
  height: 46px;
}

.icon-dobry_vyrobek {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/dobry_vyrobek.png") no-repeat;
  width: 66px;
  height: 46px;
}

.icon-hobby-log-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/hobby-log-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-icon-notification {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon-notification.png") no-repeat;
  width: 50px;
  height: 63px;
}

.icon-icon_camera_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_camera_big.png") no-repeat;
  width: 42px;
  height: 41px;
}

.icon-icon_grey_camera {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_camera.png") no-repeat;
  width: 14px;
  height: 11px;
}

.icon-icon_grey_comments {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_comments.png") no-repeat;
  width: 15px;
  height: 13px;
}

.icon-frantiska-icon-s-podpisem-big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/frantiska-icon-s-podpisem-big.png") no-repeat;
  width: 312px;
  height: 298px;
}

.icon-icon_grey_share {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_grey_share.png") no-repeat;
  width: 14px;
  height: 12px;
}

.icon-icon_horoskopy {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_horoskopy.png") no-repeat;
  width: 24px;
  height: 19px;
}

.icon-icon_tv_program {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_tv_program.png") no-repeat;
  width: 29px;
  height: 21px;
}

.icon-icon_white_eye {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/icon_white_eye.png") no-repeat;
  width: 15px;
  height: 9px;
}

.icon-isport-gray {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/isport-gray.png") no-repeat;
  width: 125px;
  height: 42px;
}

.icon-jakzhubnout-logo-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/jakzhubnout-logo-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-jasno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/jasno.png") no-repeat;
  width: 40px;
  height: 39px;
}

.icon-lesk_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/lesk_logo.png") no-repeat;
  width: 61px;
  height: 24px;
}

.icon-logo24_40x40 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_40x40.png") no-repeat;
  width: 40px;
  height: 40px;
}

.icon-logo24_inverze {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_inverze.png") no-repeat;
  width: 40px;
  height: 40px;
}

.icon-logo24_sikme_behavioral {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_behavioral.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-logo24_sikme_header {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_header.png") no-repeat;
  width: 33px;
  height: 33px;
}

.icon-logo24_sikme_rubrika {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_rubrika.png") no-repeat;
  width: 21px;
  height: 20px;
}

.icon-logo24_sikme_rubrikaHP {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/logo24_sikme_rubrikaHP.png") no-repeat;
  width: 38px;
  height: 37px;
}

.icon-mainlogo24hodin_HPrubric {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mainlogo24hodin_HPrubric.png") no-repeat;
  width: 223px;
  height: 69px;
}

.icon-mainlogo_24hodin {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mainlogo_24hodin.png") no-repeat;
  width: 223px;
  height: 69px;
}

.icon-mlha-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha-dest.png") no-repeat;
  width: 36px;
  height: 33px;
}

.icon-mlha-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha-snih.png") no-repeat;
  width: 37px;
  height: 33px;
}

.icon-mlha {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/mlha.png") no-repeat;
  width: 36px;
  height: 32px;
}

.icon-oblacno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-bourka.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-oblacno-dest-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-dest-snih.png") no-repeat;
  width: 37px;
  height: 41px;
}

.icon-oblacno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-dest.png") no-repeat;
  width: 39px;
  height: 40px;
}

.icon-oblacno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno-snih.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-oblacno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/oblacno.png") no-repeat;
  width: 41px;
  height: 32px;
}

.icon-play-small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play-small.png") no-repeat;
  width: 18px;
  height: 19px;
}

.icon-play_icon_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play_icon_big.png") no-repeat;
  width: 42px;
  height: 41px;
}

.icon-play_icon_small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/play_icon_small.png") no-repeat;
  width: 29px;
  height: 28px;
}

.icon-polojasno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-bourka.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-polojasno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-dest.png") no-repeat;
  width: 39px;
  height: 40px;
}

.icon-polojasno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno-snih.png") no-repeat;
  width: 36px;
  height: 42px;
}

.icon-polojasno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/polojasno.png") no-repeat;
  width: 37px;
  height: 35px;
}

.icon-search-cross {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/search-cross.png") no-repeat;
  width: 21px;
  height: 21px;
}

.icon-supermamy-logo-106x46 {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/supermamy-logo-106x46.png") no-repeat;
  width: 106px;
  height: 46px;
}

.icon-test_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_big.png") no-repeat;
  width: 100px;
  height: 93px;
}

.icon-test_biggest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_biggest.png") no-repeat;
  width: 130px;
  height: 121px;
}

.icon-test_small {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/test_small.png") no-repeat;
  width: 69px;
  height: 64px;
}

.icon-tv_blesk_logo_middle {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo_middle.png") no-repeat;
  width: 141px;
  height: 40px;
}

.icon-tv_blesk_logo_big {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo_big.png") no-repeat;
  width: 190px;
  height: 54px;
}

.icon-tv_blesk_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_blesk_logo.png") no-repeat;
  width: 96px;
  height: 28px;
}

.icon-tv_isport_logo {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/tv_isport_logo.png") no-repeat;
  width: 80px;
  height: 30px;
}

.icon-zatazeno-bourka {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-bourka.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno-dest-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-dest-snih.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno-dest {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-dest.png") no-repeat;
  width: 34px;
  height: 36px;
}

.icon-zatazeno-snih {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno-snih.png") no-repeat;
  width: 34px;
  height: 37px;
}

.icon-zatazeno {
  background: url("https://img2.cncenter.cz/images/blesk/dist/icons/zatazeno.png") no-repeat;
  width: 36px;
  height: 28px;
}

.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
  float: left;
  margin: 0;
  display: block;
}

.container .grid_1 {
  width: 80px;
}

.container .grid_2 {
  width: 160px;
}

.container .grid_3 {
  width: 240px;
}

.container .grid_4 {
  width: 320px;
}

.container .grid_5 {
  width: 400px;
}

.container .grid_6 {
  width: 480px;
}

.container .grid_7 {
  width: 560px;
}

.container .grid_8 {
  width: 640px;
}

.container .grid_9 {
  width: 720px;
}

.container .grid_10 {
  width: 800px;
}

.container .grid_11 {
  width: 880px;
}

.container .grid_12 {
  width: 960px;
}

.inner_1, .inner_2, .inner_3, .inner_4, .inner_5, .inner_6, .inner_7, .inner_8, .inner_9, .inner_10, .inner_11, .inner_12 {
  float: left;
  margin: 0 0 10px;
  padding: 10px 10px 0;
  display: block;
}

.outer_1, .outer_2, .outer_3, .outer_4, .outer_5, .outer_6, .outer_7, .outer_8, .outer_9, .outer_10, .outer_11, .outer_12 {
  float: left;
  margin: 0 10px;
  display: block;
}

.container .outer_1, .container .inner_1 {
  width: 60px;
}

.container .outer_2, .container .inner_2 {
  width: 140px;
}

.container .outer_3, .container .inner_3 {
  width: 220px;
}

.container .outer_4, .container .inner_4 {
  width: 300px;
}

.container .outer_5, .container .inner_5 {
  width: 380px;
}

.container .outer_6, .container .inner_6 {
  width: 460px;
}

.container .outer_7, .container .inner_7 {
  width: 540px;
}

.container .outer_8, .container .inner_8 {
  width: 620px;
}

.container .outer_9, .container .inner_9 {
  width: 700px;
}

.container .outer_10, .container .inner_10 {
  width: 780px;
}

.container .outer_11, .container .inner_11 {
  width: 860px;
}

.container .outer_12, .container .inner_12 {
  width: 940px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.p-155 {
  padding: 155px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.p-160 {
  padding: 160px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.p-165 {
  padding: 165px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.p-170 {
  padding: 170px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.p-175 {
  padding: 175px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.p-180 {
  padding: 180px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.p-185 {
  padding: 185px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.p-190 {
  padding: 190px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.p-195 {
  padding: 195px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.p-200 {
  padding: 200px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.top-article-wrap-move {
  margin-top: 420px;
}

.top-article-wrap {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

.top-article-wrap h2.title, .top-article-wrap h3.title {
  float: left;
  letter-spacing: 0;
  padding: 6px 20px 4px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: visible;
}

.top-article-wrap h2.title a, .top-article-wrap h3.title a {
  color: #000;
  transition: all .2s ease-out;
}

.top-article-wrap .whitecolor:hover h2.title a, .top-article-wrap .whitecolor:hover h3.title a, .top-article-wrap .whitecolor:hover .cat-name {
  color: #fff !important;
}

.top-article-wrap .tvblack:hover h2.title a, .top-article-wrap .tvblack:hover h3.title a, .top-article-wrap .tvblack:hover .cat-name {
  color: #16212d !important;
}

.top-article-wrap .pureBlack:hover h2.title a, .top-article-wrap .pureBlack:hover h3.title a, .top-article-wrap .pureBlack:hover .cat-name {
  color: #000 !important;
}

.top-article-wrap .tvblacklighter:hover h2.title a, .top-article-wrap .tvblacklighter:hover h3.title a, .top-article-wrap .tvblacklighter:hover .cat-name {
  color: #232b3a !important;
}

.top-article-wrap .redChristmas:hover h2.title a, .top-article-wrap .redChristmas:hover h3.title a, .top-article-wrap .redChristmas:hover .cat-name {
  color: #b00 !important;
}

.top-article-wrap .pinkChristmas:hover h2.title a, .top-article-wrap .pinkChristmas:hover h3.title a, .top-article-wrap .pinkChristmas:hover .cat-name {
  color: #ff8a8a !important;
}

.top-article-wrap .default:hover h2.title a, .top-article-wrap .default:hover h3.title a, .top-article-wrap .default:hover .cat-name {
  color: #000 !important;
}

.top-article-wrap .grey:hover h2.title a, .top-article-wrap .grey:hover h3.title a, .top-article-wrap .grey:hover .cat-name {
  color: #c9d3df !important;
}

.top-article-wrap .system:hover h2.title a, .top-article-wrap .system:hover h3.title a, .top-article-wrap .system:hover .cat-name {
  color: red !important;
}

.top-article-wrap .colorHoverMenu:hover h2.title a, .top-article-wrap .colorHoverMenu:hover h3.title a, .top-article-wrap .colorHoverMenu:hover .cat-name {
  color: #540101 !important;
}

.top-article-wrap .auto:hover h2.title a, .top-article-wrap .auto:hover h3.title a, .top-article-wrap .auto:hover .cat-name, .top-article-wrap .pro-zeny:hover h2.title a, .top-article-wrap .pro-zeny:hover h3.title a, .top-article-wrap .pro-zeny:hover .cat-name {
  color: red !important;
}

.top-article-wrap .sport:hover h2.title a, .top-article-wrap .sport:hover h3.title a, .top-article-wrap .sport:hover .cat-name {
  color: #91c837 !important;
}

.top-article-wrap .svet:hover h2.title a, .top-article-wrap .svet:hover h3.title a, .top-article-wrap .svet:hover .cat-name {
  color: #6e78dc !important;
}

.top-article-wrap .tvbox:hover h2.title a, .top-article-wrap .tvbox:hover h3.title a, .top-article-wrap .tvbox:hover .cat-name {
  color: #fff !important;
}

.top-article-wrap .celebrity:hover h2.title a, .top-article-wrap .celebrity:hover h3.title a, .top-article-wrap .celebrity:hover .cat-name {
  color: red !important;
}

.top-article-wrap .greyLight:hover h2.title a, .top-article-wrap .greyLight:hover h3.title a, .top-article-wrap .greyLight:hover .cat-name {
  color: #c0ced5 !important;
}

.top-article-wrap .grey-comments:hover h2.title a, .top-article-wrap .grey-comments:hover h3.title a, .top-article-wrap .grey-comments:hover .cat-name {
  color: #98a3af !important;
}

.top-article-wrap .bleskRed:hover h2.title a, .top-article-wrap .bleskRed:hover h3.title a, .top-article-wrap .bleskRed:hover .cat-name, .top-article-wrap .colorTesty:hover h2.title a, .top-article-wrap .colorTesty:hover h3.title a, .top-article-wrap .colorTesty:hover .cat-name {
  color: red !important;
}

.top-article-wrap .heliotrope:hover h2.title a, .top-article-wrap .heliotrope:hover h3.title a, .top-article-wrap .heliotrope:hover .cat-name {
  color: #b469f5 !important;
}

.top-article-wrap .chetwode:hover h2.title a, .top-article-wrap .chetwode:hover h3.title a, .top-article-wrap .chetwode:hover .cat-name {
  color: #083e89 !important;
}

.top-article-wrap .robinblue:hover h2.title a, .top-article-wrap .robinblue:hover h3.title a, .top-article-wrap .robinblue:hover .cat-name {
  color: #00c8be !important;
}

.top-article-wrap .hotpink:hover h2.title a, .top-article-wrap .hotpink:hover h3.title a, .top-article-wrap .hotpink:hover .cat-name {
  color: #fe60b2 !important;
}

.top-article-wrap .tyrkys:hover h2.title a, .top-article-wrap .tyrkys:hover h3.title a, .top-article-wrap .tyrkys:hover .cat-name {
  color: #2dccd3 !important;
}

.top-article-wrap .saffron:hover h2.title a, .top-article-wrap .saffron:hover h3.title a, .top-article-wrap .saffron:hover .cat-name {
  color: #f7b135 !important;
}

.top-article-wrap .jade:hover h2.title a, .top-article-wrap .jade:hover h3.title a, .top-article-wrap .jade:hover .cat-name {
  color: #00b373 !important;
}

.top-article-wrap .scooter:hover h2.title a, .top-article-wrap .scooter:hover h3.title a, .top-article-wrap .scooter:hover .cat-name {
  color: #3cc3dc !important;
}

.top-article-wrap .dodger:hover h2.title a, .top-article-wrap .dodger:hover h3.title a, .top-article-wrap .dodger:hover .cat-name {
  color: #23aaff !important;
}

.top-article-wrap .domino:hover h2.title a, .top-article-wrap .domino:hover h3.title a, .top-article-wrap .domino:hover .cat-name {
  color: #ff5a00 !important;
}

.top-article-wrap .atlantis:hover h2.title a, .top-article-wrap .atlantis:hover h3.title a, .top-article-wrap .atlantis:hover .cat-name {
  color: #91c837 !important;
}

.top-article-wrap .homeorange:hover h2.title a, .top-article-wrap .homeorange:hover h3.title a, .top-article-wrap .homeorange:hover .cat-name {
  color: #ff7300 !important;
}

.top-article-wrap .articleContext {
  margin-top: 15px;
  font-family: arial;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 20px;
}

.top-article-wrap .articleContext a, .top-article-wrap .articleContext .videoTitle {
  color: #98a3af;
}

.top-article-wrap .articleContext .forumTitle, .top-article-wrap .articleContext .galleryTitle, .top-article-wrap .articleContext .videoTitle {
  padding: 0 3px;
}

.top-article-wrap .articleContext .forumCount, .top-article-wrap .articleContext .galleryCount, .top-article-wrap .articleContext .videoCount {
  color: #98a3af;
  margin-right: 10px;
}

.top-article-wrap .articleContext .forumIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -800px no-repeat;
  width: 13px;
  height: 11px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.top-article-wrap .articleContext .galleryIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -800px no-repeat;
  width: 12px;
  height: 9px;
  display: inline-block;
}

.top-article-wrap .cat-name {
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 14px;
}

.top-article-wrap .item-middle-top {
  background: #fff;
  width: 300px;
  height: 330px;
  margin: 10px 10px 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .preview {
  width: 300px;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .preview img {
  width: 300px;
  min-height: 190px;
  position: relative;
  top: -10px;
}

.top-article-wrap .item-middle-top .content {
  width: 300px;
  margin-top: 10px;
  position: relative;
}

.top-article-wrap .item-middle-top .content .cat-name {
  background-color: #fff;
  padding: 8px 10px 5px 20px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -30px;
}

.top-article-wrap .item-middle-top .content .cat-name.two-lines {
  max-width: 105px;
  top: -50px;
}

.top-article-wrap .item-middle-top .content .title {
  max-width: 300px;
  min-height: 175px;
  max-height: 195px;
  padding: 3px 20px 0;
  line-height: 35px;
  overflow: hidden;
}

.top-article-wrap .item-middle-top .media-icon {
  position: absolute;
  top: 150px;
  left: 135px;
}

.top-article-wrap .item-middle {
  clear: both;
  background: #fff;
  width: 620px;
  height: 180px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle h2.title a, .top-article-wrap .item-middle h3.title a {
  line-height: 29px;
}

.top-article-wrap .item-middle .preview {
  float: left;
  width: 300px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-middle .content {
  float: left;
  width: 310px;
  margin-left: 10px;
}

.top-article-wrap .item-middle .content .cat-name {
  padding: 20px 0 0 20px;
  font-size: 14px;
  display: block;
  position: relative;
}

.top-article-wrap .item-middle .content .cat-name.insideImage {
  text-align: right;
  width: 300px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-article-wrap .item-middle .content .cat-name.insideImage span {
  white-space: nowrap;
  background-color: #fff;
  padding: 9px 10px 5px;
  display: inline-block;
}

.top-article-wrap .item-middle .content .cat-name:hover, .top-article-wrap .item-middle .content .cat-name:hover span {
  text-decoration: underline;
}

.top-article-wrap .item-middle .content .title {
  max-width: 320px;
  min-height: 110px;
  max-height: 145px;
  padding-top: 16px;
  line-height: 29px;
  display: inline-block;
  overflow: hidden;
}

.top-article-wrap .item-middle .media-icon {
  position: absolute;
  top: 70px;
  left: 280px;
}

.top-article-wrap .item-big {
  background: #fff;
  width: 620px;
  height: 390px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-big .preview {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.top-article-wrap .item-big .preview img {
  width: 620px;
  position: relative;
  top: -4px;
}

.top-article-wrap .item-big .content {
  width: 600px;
  position: relative;
}

.top-article-wrap .item-big .content .cat-name {
  letter-spacing: .5px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 8px 10px 5px 20px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -18px;
}

.top-article-wrap .item-big .content .title {
  width: 100%;
  max-width: 600px;
  min-height: 98px;
  padding-top: 19px;
  font-size: 40px;
  line-height: 106%;
}

.top-article-wrap .item-big .media-icon {
  position: absolute;
  top: 216px;
  left: 290px;
}

.tabsArea-move {
  position: absolute;
  top: 0;
}

.tabsArea#behavioral-tab {
  color: #fff;
  width: 620px;
  margin: 20px 10px;
  position: relative;
}

.tabsArea#behavioral-tab li, .tabsArea#behavioral-tab ul {
  list-style: none;
}

.tabsArea#behavioral-tab .tabsNavigation {
  height: 40px;
  overflow: hidden;
}

.tabsArea#behavioral-tab .tabsNavigation li {
  float: left;
  box-sizing: border-box;
  margin: 0;
  display: block;
}

.tabsArea#behavioral-tab .tabsNavigation li.celebrity a.active {
  color: #fff;
  background-color: red;
}

.tabsArea#behavioral-tab .tabsNavigation li.pro-zeny a.active {
  color: #fff;
  background-color: #fe60b2;
}

.tabsArea#behavioral-tab .tabsNavigation li.sport a.active {
  color: #fff;
  background-color: #91c837;
}

.tabsArea#behavioral-tab .tabsNavigation li.zpravy a.active {
  color: #fff;
  background-color: #083e89;
}

.tabsArea#behavioral-tab .tabsNavigation li:nth-child(3) a {
  z-index: 2 !important;
}

.tabsArea#behavioral-tab .tabsNavigation li:nth-child(4) a {
  z-index: 3 !important;
}

.tabsArea#behavioral-tab .tabsNavigation li a {
  text-align: center;
  color: #000;
  text-transform: uppercase;
  box-sizing: border-box;
  z-index: 0;
  background-color: #fff;
  width: 155px;
  height: 40px;
  padding: 5px;
  font-family: tablet_gothic_condensed;
  font-size: 28px;
  line-height: 1.4;
  text-decoration: none;
  transition: all .2s ease-out;
  display: block;
  position: relative;
}

.tabsArea#behavioral-tab .tabsNavigation li a.active {
  z-index: 1;
}

.tabsArea#behavioral-tab .tabsNavigation li a.active:after {
  content: "";
  z-index: -1;
  background: none;
  width: 155px;
  max-width: 260px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 9px #000;
}

.tabsArea#behavioral-tab .tabsNavigation li.zpravy a .blesk24logoBehav {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  left: 120px;
}

.tabsArea .behavioral-tabcontent-wrap {
  box-sizing: border-box;
  width: 620px;
  height: 340px;
  padding: 20px 0 20px 15px;
  font-family: tablet_gothic_condensed;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle {
  float: left;
  width: 280px;
  height: 73px;
  max-height: 73px;
  margin: 0 0 18px 20px;
  padding: 0 0 15px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle h3.title {
  float: left;
  letter-spacing: 0;
  width: 140px;
  height: 72px;
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle h3.title a {
  color: #fff;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle .preview {
  float: left;
  width: 120px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-middle .content {
  float: left;
  width: 160px;
  position: relative;
}

.tabsArea .behavioral-tabcontent-wrap .item-big {
  float: left;
  width: 295px;
  height: 316px;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big h3.title {
  float: left;
  letter-spacing: 0;
  width: 280px;
  margin: 5px 0 20px;
  padding-top: 6px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big h3.title a {
  color: #fff;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .preview {
  width: 280px;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .preview img {
  width: 100%;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content {
  width: 305px;
  position: relative;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content .cat-name {
  letter-spacing: .5px;
  text-transform: uppercase;
  max-width: 110px;
  padding: 8px 10px 5px 0;
  font-size: 14px;
  position: absolute;
  bottom: -5px;
}

.tabsArea .behavioral-tabcontent-wrap .item-big .content .icon.behabox {
  position: absolute;
  top: -27px;
  left: 119px;
}

.tabsArea .behavioral-tabcontent-wrap .item-big.item-big-last {
  border: 0 solid rgba(0, 0, 0, 0);
  width: 274px;
  margin-left: 15px;
}

.celebrity .behavioral-tabcontent-wrap {
  background-color: red;
}

.pro-zeny .behavioral-tabcontent-wrap {
  background-color: #fe60b2;
}

.sport .behavioral-tabcontent-wrap {
  background-color: #91c837;
}

.zpravy .behavioral-tabcontent-wrap {
  background-color: #083e89;
}

.celebrity .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.celebrity .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.celebrity .behavioral-tabcontent-wrap .cat-name {
  background-color: red;
}

.celebrity .behavioral-tabcontent-wrap .cat-name a {
  color: #f1adad;
}

.pro-zeny .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.pro-zeny .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.pro-zeny .behavioral-tabcontent-wrap .cat-name {
  background-color: #fe60b2;
}

.pro-zeny .behavioral-tabcontent-wrap .cat-name a {
  color: #efcbdc;
}

.sport .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.sport .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.sport .behavioral-tabcontent-wrap .cat-name {
  background-color: #91c837;
}

.sport .behavioral-tabcontent-wrap .cat-name a {
  color: #e1ffb0;
}

.zpravy .behavioral-tabcontent-wrap .item-big {
  border-right: 1px solid rgba(255, 255, 255, .2);
}

.zpravy .behavioral-tabcontent-wrap .item-middle {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.zpravy .behavioral-tabcontent-wrap .cat-name {
  background-color: #083e89;
}

.zpravy .behavioral-tabcontent-wrap .cat-name a {
  color: #b7b7f9;
}

.behavioral-tabcontent-wrap .item-middle.position-4 {
  border-bottom: 0 solid #000;
}

#muzi .sectionHeader {
  border-top-color: #91c837 !important;
}

#muzi a.color {
  color: #91c837 !important;
}

#muzi .article-container {
  border-top-color: #91c837 !important;
}

#muzi .article-container a.hoverColor:hover, #muzi .article-container:hover a.hoverColor {
  color: #91c837 !important;
}

#zeny .sectionHeader {
  border-top-color: #fe60b2 !important;
}

#zeny a.color {
  color: #fe60b2 !important;
}

#zeny .article-container {
  border-top-color: #fe60b2 !important;
}

#zeny .article-container a.hoverColor:hover, #zeny .article-container:hover a.hoverColor {
  color: #fe60b2 !important;
}

.headerMenu {
  margin-bottom: 0;
  padding-top: 0;
  position: relative;
}

.headerMenu .topPanel {
  border: none;
  width: 940px;
}

.headerMenu .topPanel .profileActions {
  font-weight: bold;
}

.headerMenu .topPanel .profileActions .registration, .headerMenu .topPanel .profileActions .login {
  border: none;
}

.headerMenu .topPanel .linkMagazines {
  float: right;
  text-align: left;
  min-width: 425px;
}

.headerMenu .forecastHP {
  width: 85px;
}

.headerMenu .forecastHP .forecast {
  min-width: 80px;
  margin-left: 5px !important;
}

.headerMenu .forecastHP a {
  color: #16212d;
  font-size: 16px;
}

.headerMenu .calendarContHP {
  float: left;
  height: 40px;
  line-height: 36px;
  width: 620px !important;
}

.headerMenu .calendarContHP .calendar {
  color: #5c6773;
  float: left;
  width: auto;
  height: 38px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 36px;
  padding: 4px 10px 0 5px !important;
}

.headerMenu .calendarContHP .nameDay {
  float: left;
  color: #5c6773;
  align-items: center;
  height: 36px;
  font-family: arial, sans-serif;
  display: flex;
  max-width: 350px !important;
  padding-top: 2px !important;
  font-size: 13px !important;
}

.headerMenu .calendarContHP .nameDay a {
  color: #707b87;
  font-weight: bold;
  text-decoration: none;
}

.headerMenu .calendarContHP .nameDay a:hover {
  text-decoration: underline;
}

.headerMenu .wrapper.head {
  margin-top: -68px;
}

.headerMenu .wrapper.head #menu #menu-section-container {
  top: 68px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu {
  position: relative;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu .icon-icon_tv_program, .headerMenu .wrapper.head #menu ul.topLevelMenu .icon-icon_horoskopy {
  margin: -4px auto 0;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li a.blesk24logoMainMenu {
  position: absolute;
  top: 16px;
  right: 9px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li a.category {
  border-right: 1px solid #cad5df;
  height: 53px;
  margin-top: 15px;
  padding-top: 0;
  font-size: 41px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li a.category.smallText {
  font-size: 20px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu .shadowDown.bottom {
  top: auto;
  bottom: -10px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.celebrity {
  width: 185px;
  height: 68px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.celebrity a.category:after {
  display: none;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.zpravy {
  width: 140px;
  height: 68px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.zpravy a.category:after {
  display: none;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.sport {
  width: 135px;
  height: 68px;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.sport a.category:after {
  display: none;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.program {
  width: 120px;
  height: 68px;
  padding: 0;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.program a.category {
  text-indent: 0;
  background: none;
  margin-top: 15px;
  padding: 0 0 4px;
  line-height: 31px;
  position: relative;
  top: 0;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.horoskopy {
  width: 110px;
  height: 68px;
  padding: 0;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.horoskopy:hover {
  background: #fff;
}

.headerMenu .wrapper.head #menu ul.topLevelMenu li.horoskopy a.category {
  text-indent: 0;
  background: none;
  border-right: none;
  padding: 0 0 4px;
  line-height: 35px;
  position: relative;
  top: 0;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu {
  background: none;
  height: 50px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu li {
  border: none;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu li a {
  color: #5c6773;
  font-size: 16px;
  line-height: 50px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu ul.srdceProVas {
  top: 2px;
  right: 128px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu ul.bleskMobil {
  top: 5px;
  right: 58px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu li.search {
  cursor: pointer;
  border-left: 1px solid #cad5df;
  position: absolute;
  top: 15px;
  right: 0;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu li.search .icon-header-search {
  background: url("https://img2.cncenter.cz/images/blesk/redesign-2015/header-search.png") no-repeat;
  width: 19px;
  height: 19px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu .searchBox {
  display: none;
  position: absolute;
  top: 2px;
  right: 39px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu .searchBox .searchField {
  width: 344px;
  height: 40px;
}

.headerMenu .wrapper.head #menu ul.secondLevelMenu .searchBox .colorBtn {
  height: 44px;
}

.headerMenu .wrapper #menu ul.topLevelMenu li.chetwode.zpravy.hover a.category, .headerMenu .wrapper #menu ul.topLevelMenu li.chetwode #menu-section-container .article-container:hover, .headerMenu .wrapper #menu ul.topLevelMenu li.chetwode #menu-section-container .article-container:hover .title a, .headerMenu .wrapper #menu ul.topLevelMenu li.chetwode #menu-section-container .menuInMenu li a:hover {
  color: #083e89 !important;
}

.container.article .profileActions a {
  color: #707b87;
  width: 89px;
  padding-left: 12px;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  display: inline-block;
}

.container.article .profileActions a.user {
  width: auto;
  padding-left: 0;
}

.container.article .headerMenu .logoBlesk {
  float: left;
  margin: 0;
}

.container.article .headerMenu .logoBlesk a {
  background: none;
  width: auto;
  height: auto;
}

.container.article .headerMenu .topPanel {
  float: left;
  width: 786px;
  height: 29px;
  padding: 0;
  position: relative;
}

.container.article .headerMenu .topPanel .calendar {
  color: #5c6773;
  float: left;
  width: auto;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 25px;
  padding: 4px 10px 0 25px !important;
}

.container.article .headerMenu .topPanel .nameDay {
  color: #5c6773;
  float: left;
  border: none;
  width: 470px;
  margin: 0;
  font-family: arial, sans-serif;
  line-height: 24px;
  max-width: 470px !important;
  padding: 4px 10px 0 0 !important;
  font-size: 13px !important;
}

.container.article .headerMenu .topPanel .profileActions {
  float: right;
  width: 130px;
}

.container.article .headerMenu .topPanel .profileActions .login {
  color: #f50000;
  text-transform: uppercase;
  border-left: 1px solid #cad5df;
  padding: 0 0 0 20px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin: 8px 10px 0 !important;
}

.container.article .headerMenu .topPanel .search {
  float: right;
  cursor: pointer;
  width: 130px;
  padding: 4px 5px 0;
  list-style: none;
}

.container.article .headerMenu .topPanel .search .icon {
  float: right;
}

.container.article .headerMenu .shadowDown.relative.bottom {
  z-index: 1;
  top: auto;
  bottom: -29px;
  left: 0;
  right: auto;
}

.container.article .headerMenu .searchBox {
  display: none;
  position: absolute;
  top: -1px;
  right: 35px;
}

.container.article .headerMenu .wrapper {
  float: left;
  width: 786px;
}

.container.article .headerMenu .wrapper #menu {
  margin-left: 0;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.celebrity a.category:after, .container.article .headerMenu .wrapper #menu ul.topLevelMenu li.zpravy a.category:after, .container.article .headerMenu .wrapper #menu ul.topLevelMenu li.sport a.category:after, .container.article .headerMenu .wrapper #menu ul.topLevelMenu li.dalsi a.category:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -200px no-repeat content-box content-box;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li a.category {
  font-size: 26px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.celebrity {
  width: 140px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.celebrity a.category {
  width: 139px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.zpravy {
  width: 110px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.zpravy a.category {
  width: 109px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.zpravy.hover a.category {
  color: #083e89 !important;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.sport {
  width: 110px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.sport a.category {
  width: 109px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.dalsi {
  width: 110px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.dalsi a.category {
  width: 109px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.program {
  width: 150px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.program a.category {
  text-indent: 0;
  color: #16212d;
  text-transform: uppercase;
  background: none;
  border-right: 1px solid #cad5df;
  width: auto;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 0 0;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 20px;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.horoskopy {
  width: 150px;
  height: 40px;
  margin: 0;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.horoskopy:hover {
  background: #fff;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.horoskopy:hover a.category {
  text-decoration: none;
}

.container.article .headerMenu .wrapper #menu ul.topLevelMenu li.horoskopy a.category {
  text-indent: 0;
  color: #16212d;
  text-transform: uppercase;
  text-align: center;
  background: none;
  border: none;
  width: auto;
  height: auto;
  margin: 10px 0;
  padding: 5px 0 0;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 20px;
}

.container.article #menu .menuSubpages .menuSection {
  z-index: 200;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
}

.container.article #menu .menuSubpages .menuSection .menuInMenu {
  min-height: auto;
}

.container.article #menu .menuSubpages .active .menuSection {
  display: block;
}

.container.article #menu .menuSubpages .separator-top:before {
  content: "";
  border-top: 1px solid #3e4853;
  margin: 8px 0;
  display: block;
}

#body-wrapper #breadcrumbs {
  background: none;
}

#body-wrapper.body-wrapper--white {
  background-color: #fff !important;
}

.promoBox2015.top {
  padding-bottom: 10px;
}

.promoBox2015.top.whiteBackground {
  background: #fff;
}

.promoBox2015.top .promoboxPosition-0 {
  border-top: 1px solid #c0ced5;
  border-bottom: 1px solid #c0ced5;
  width: 910px;
  margin: 17px 0;
  padding: 15px 15px 0;
  overflow: hidden;
}

.promoBox2015.top .promoboxPosition-0 .title {
  text-align: center;
  text-transform: uppercase;
  width: 910px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 72px;
  display: none;
}

.promoBox2015.top .promoboxPosition-0 .title a {
  color: #16212d;
  margin-top: 5px;
  display: block;
}

.promoBox2015.top .promoboxPosition-1 {
  width: 940px;
}

.promoBox2015.top .promoboxPosition-1 .promoLink .promoImage {
  width: 940px;
  max-height: 380px;
}

.promoBox2015.top .promoboxPosition-1A {
  float: left;
  width: 620px;
  height: 380px;
  margin-right: 10px;
}

.promoBox2015.top .promoboxPosition-1A .promoImage {
  width: 620px;
  height: 380px;
}

.promoBox2015.top .promoboxPosition-1B {
  float: left;
  width: 300px;
  height: 380px;
  margin-left: 10px;
}

.promoBox2015.top .promoboxPosition-1B .promoImage {
  width: 300px;
  height: 380px;
}

.promoBox2015.bottom .promoboxPosition-2 {
  background: #fff;
  height: 340px;
  position: relative;
}

.promoBox2015.bottom .promoboxPosition-2:hover .promoLink .title {
  color: red;
}

.promoBox2015.bottom .promoboxPosition-2:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.sport:hover .promoLink .title {
  color: #91c837;
}

.promoBox2015.bottom .promoboxPosition-2.sport:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.sport .header {
  color: #91c837;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy:hover .promoLink .title {
  color: #083e89;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy:hover .promoLink .title:hover {
  text-decoration: underline;
}

.promoBox2015.bottom .promoboxPosition-2.zpravy .header {
  color: #083e89;
}

.promoBox2015.bottom .promoboxPosition-2 .promoImage {
  width: 300px;
}

.promoBox2015.bottom .promoboxPosition-2 .header {
  color: red;
  text-transform: uppercase;
  letter-spacing: .5px;
  background-color: #fff;
  height: 12px;
  padding: 8px 15px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.1;
  position: absolute;
  top: 159px;
  left: 0;
}

.promoBox2015.bottom .promoboxPosition-2 .promoLink {
  color: #16212d;
}

.promoBox2015.bottom .promoboxPosition-2 .promoLink .title {
  color: #16212d;
  z-index: 2;
  background: #fff;
  max-height: 136px;
  padding: 18px 15px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 34px;
  position: absolute;
  top: 179px;
  left: 0;
  overflow: hidden;
}

.promoBox2015.bottom .promoboxPosition-2 .meta {
  height: 34px;
  padding-left: 15px;
  line-height: 34px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .icon {
  float: left;
  margin-top: 12px;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .photo {
  color: #c9d3df;
  margin: 0 0 0 5px;
  font-size: 12px;
}

.promoBox2015.bottom .promoboxPosition-2 .meta .value {
  color: #16212d;
  font-size: 12px;
}

.article-container {
  background-color: #fff;
  border: none;
  padding: 0 10px;
  position: relative;
}

.article-container .category-title {
  text-transform: uppercase;
  color: red;
  word-spacing: 3px;
  font-family: tablet_gothic_compressed;
  font-size: 14px;
}

.article-container .category-title a {
  color: #000;
}

.outer_4.blackBg, .outer_4.greyBg {
  margin: 10px;
}

.outer_4.blackBg a span .icon-play, .outer_4.greyBg a span .icon-play {
  position: absolute;
}

.outer_4.blackBg .linkCol, .outer_4.greyBg .linkCol {
  margin: auto;
}

.outer_4.blackBg .linkCol .videoFirstTitle, .outer_4.greyBg .linkCol .videoFirstTitle {
  float: left;
  width: 280px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 0;
  font-family: tablet_gothic_condensed;
  line-height: 32px;
  overflow: hidden;
  font-size: 30px !important;
}

.outer_4.blackBg .linkCol .videoFirstTitle a, .outer_4.greyBg .linkCol .videoFirstTitle a {
  color: #fff;
  font-size: 30px !important;
}

.outer_4.greyBg {
  background-color: #232b3a;
}

.outer_4.greyBg .video-header {
  background: #232b3a;
  height: 29px;
  margin: 0;
  padding: 0;
  position: relative;
}

.outer_4.greyBg .video-header a.logoTV {
  position: absolute;
  left: 0;
}

.outer_4.greyBg .video-header .icon-tv_isport_logo {
  margin-left: 10px;
}

.outer_4.greyBg .video-header h4.header {
  margin: auto;
}

.outer_4.greyBg .video-header h4.header a {
  color: #fff;
  padding: 9px;
  font-family: tablet_gothic_condensed;
  font-size: 15px;
  position: absolute;
  right: 0;
}

.outer_4.greyBg .vertVideoBig, .outer_4.greyBg .vertVideoSmall {
  width: 300px;
  font-family: tablet_gothic_condensed;
}

.outer_4.greyBg .vertVideoBig .linkCol, .outer_4.greyBg .vertVideoSmall .linkCol {
  width: 280px;
  padding-top: 0;
  position: relative;
}

.outer_4.greyBg .vertVideoBig .linkCol:hover a, .outer_4.greyBg .vertVideoSmall .linkCol:hover a {
  text-decoration: underline;
}

.outer_4.greyBg .vertVideoBig .linkCol a span.icon-play, .outer_4.greyBg .vertVideoSmall .linkCol a span.icon-play {
  position: absolute;
}

.outer_4.greyBg .vertVideoBig .linkCol {
  border-bottom: none;
}

.outer_4.greyBg .vertVideoBig .linkCol div {
  height: 72px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstPic {
  width: 280px;
  height: 158px;
  margin-bottom: 10px;
  overflow: hidden;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstPic a img {
  width: 280px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstTitle {
  height: 120px;
  margin-bottom: 8px;
}

.outer_4.greyBg .vertVideoBig .linkCol .videoFirstTitle a {
  font-size: 30px;
  line-height: 32px;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big {
  float: right;
  width: 60px;
  height: 17px;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big .icon-eye {
  margin-top: 2px;
  position: absolute;
}

.outer_4.greyBg .vertVideoBig .linkCol .watched-big span.numOfWatch {
  float: right;
  text-align: right;
  color: #fff;
  margin-right: 10px;
  font-family: arial;
  font-size: 12px;
  display: block;
}

.outer_4.greyBg .vertVideoBig .linkCol a span.icon-play {
  margin: auto;
  top: 128px;
  left: 4%;
  right: 4%;
}

.outer_4.greyBg .vertVideoSmall {
  float: left;
}

.outer_4.greyBg .vertVideoSmall .linkCol {
  border-top: 1px solid rgba(255, 255, 255, .2);
  border-bottom: none;
  height: 73px;
  padding: 10px 0;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle {
  float: left;
  width: 150px;
  height: 68px;
  margin-left: 10px;
  line-height: 17px;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle:hover a {
  text-decoration: underline;
}

.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle a {
  color: #fff;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
}

.outer_4.greyBg .vertVideoSmall .linkCol a img {
  float: left;
  width: 120px;
  max-height: 72px;
}

.outer_4.greyBg .vertVideoSmall .linkCol a span.icon-play {
  top: 63px;
  left: 47px;
}

.outer_4.blackBg {
  background-color: #232b3a;
  margin: 20px 10px 0;
}

.outer_4.blackBg .vertVideo .top {
  background: #232b3a;
  height: 40px;
  margin: 0;
  padding: 0;
  position: relative;
}

.outer_4.blackBg .vertVideo .top a.logo_TVBlesk {
  position: absolute;
  top: 0;
  left: 0;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol {
  width: 300px;
  margin: 0;
  padding-top: 0;
  position: relative;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol:hover .videoFirstTitle a {
  text-decoration: underline;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstPic {
  width: 300px;
  height: 158px;
  margin-bottom: 15px;
  overflow: hidden;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstPic a img {
  width: 300px;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol .videoFirstTitle {
  height: 120px;
  margin: 0 10px 8px;
  padding: 5px 0;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol #category-title {
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #fff;
  background-color: #232b3a;
  max-width: 110px;
  height: auto;
  padding: 8px 10px 5px;
  font-family: tablet_gothic_condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1;
  display: block;
  position: absolute;
  bottom: 144px;
}

.outer_4.blackBg .paidPlaceholder-new .linkCol a span.icon-play {
  margin: auto;
  position: absolute;
  top: 132px;
  left: 4%;
  right: 4%;
}

.tv-carousel-container * {
  box-sizing: border-box;
}

.tv-carousel-container {
  float: left;
  width: 300px;
  height: 100%;
  margin: 0 10px 20px;
  display: block;
  position: relative;
}

.tv-carousel-container #premiere-today-title {
  letter-spacing: .5px;
  color: red;
  background-color: #232b3a;
  width: 300px;
  padding: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 14px;
}

.tv-carousel-container .owl-carousel {
  z-index: 0;
}

.tv-carousel-container .today-butt-box {
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: absolute;
  right: 35px;
}

.tv-carousel-container .today-butt-box .today-butt {
  color: #b5c7e5;
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  top: 5px;
}

.tv-carousel-container .today-butt-box .today-butt:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 40px 0 50px -70px #0e1520, inset -40px 0 50px -40px #0e1520;
}

.tv-carousel-container .carousel-control {
  width: 27px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}

.tv-carousel-container .carousel-control.tv-carousel-next {
  right: -40px;
}

.tv-carousel-container .carousel-control.tv-carousel-prev {
  left: -40px;
}

.tv-carousel-container .carousel-control .caret-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("/images/blesk-tv/caret_left.png");
  width: 27px;
  height: 51px;
  display: inline-block;
}

.tv-carousel-container .carousel-control .caret-next {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("/images/blesk-tv/caret_right.png");
  width: 27px;
  height: 51px;
  display: inline-block;
}

.tv-carousel-container .tv-carousel {
  float: left;
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.tv-carousel-container .tv-carousel .owl-item.active:last-child .day-container {
  border-right: none;
}

.tv-carousel-container .tv-carousel .owl-stage-outer .owl-stage {
  margin-top: 20px;
}

.tv-carousel-container .tv-carousel .day-container {
  background: #232b38;
  border-right: 1px dotted #5d6b84;
  max-width: 300px;
  margin-top: 0;
  position: relative;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder {
  color: #b5c7e5;
  text-transform: uppercase;
  background: #232b38;
  width: 100%;
  padding: 12px 10px 8px;
  font-size: 12px;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder .left {
  float: left;
  text-align: left;
  width: 60%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-placeholder .right {
  float: left;
  text-align: right;
  width: 40%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-program {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li {
  width: 100%;
  height: 100%;
  min-height: 55px;
  margin: 0;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a {
  background: none;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 55px;
  padding: 5px 10px;
  transition: background .5s ease-out;
  display: block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a:hover {
  background: #4e5869;
  transition: background .5s ease-in;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a:hover .picture .hover-play {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info {
  float: left;
  width: 83%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span {
  width: 100%;
  display: inline-block;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span.time {
  color: #b5c7e5;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 11px;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .info span.name {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture {
  float: left;
  width: 35px;
  height: 35px;
  margin: 5px 4px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture .hover-play {
  opacity: 0;
  z-index: 100;
  background: rgba(22, 30, 42, .5);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture .hover-play .icon {
  z-index: 100;
  margin-top: -9px;
  margin-left: -9px;
  position: absolute;
  top: 52%;
  left: 52%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li a .picture img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.tv-carousel-container .tv-carousel .day-container .day-program li:nth-child(2n) {
  background: #232b39;
}

.tv-carousel-container .tv-carousel .day-container .day-program li:nth-child(odd) {
  background: #161e2a;
}

.browsehappy {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

.next-broadcast-info {
  float: left;
  border-top: 2px solid #232b39;
  width: 100%;
  max-width: 940px;
  margin: 0 10px;
  padding: 20px 0;
  font-size: 20px;
}

.next-broadcast-info .line {
  background: none;
  height: auto;
  padding: 5px 0;
}

.next-broadcast-info .line .banner-text {
  color: #fff;
}

.next-broadcast-info .line .broadcast-name {
  color: #b5c7e5;
  text-transform: uppercase;
}

.next-broadcast-info .line .date {
  color: #b5c7e5;
}

.next-broadcast-info .line .prev-broadcasts-text {
  color: #b5c7e5;
  font-size: 18px;
}

.modniPolicie .article-container {
  background-color: #16212d;
  border-top: none;
  height: 676px;
  position: relative;
}

.modniPolicie .article-container h3 {
  width: 270px;
  margin-top: 20px;
  padding-top: 10px;
  padding-right: 10px;
  position: relative;
}

.modniPolicie .article-container h3 a {
  height: 120px;
  font-family: tablet_gothic_condensed;
  font-size: 30px;
  line-height: 30px;
  transition: all .1s ease-out;
  display: block;
  color: #c0ced5 !important;
}

.modniPolicie .article-container h3 a:hover {
  color: #00c8be !important;
}

.modniPolicie .article-container .category-title {
  background-color: #16212d;
  padding: 8px 10px 5px 0;
  position: absolute;
  top: 502px;
}

.modniPolicie .article-container .category-title a {
  color: #00c8be !important;
}

.modniPolicie .article-container .imageTop {
  background-color: rgba(0, 0, 0, 0);
  width: 280px;
  height: 510px;
  margin: auto;
  padding-top: 10px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.bigOneArticle {
  padding: 0;
  height: auto !important;
}

.article-container.bigOneArticle h3.title.bg {
  float: left;
  width: 320px;
  height: 328px;
  margin: 0;
  padding: 0;
  font-family: tablet_gothic_condensed;
  font-size: 50px;
  line-height: 52px;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #fff !important;
}

.article-container.bigOneArticle h3.title.bg a {
  color: #000;
  width: 280px;
  height: auto;
  min-height: 252px;
  padding: 25px 10px 0;
  font-size: 40px;
  font-weight: 400;
  line-height: 118%;
  display: block;
  position: relative;
  overflow: hidden;
}

.article-container.bigOneArticle h3.title.bg a:hover {
  color: red;
}

.article-container.bigOneArticle h3.title.bg a:hover span {
  text-decoration: underline;
}

.article-container.bigOneArticle h3.title.bg .category-title {
  height: 25px;
  padding: 0;
  color: #fff !important;
}

.article-container.bigOneArticle h3.title.bg .category-title a {
  color: red;
  min-height: 17px;
  padding: 10px 10px 0;
  font-size: 16px;
}

.article-container.bigOneArticle h3.title.bg .articleContext {
  color: #98a3af;
  float: left;
  width: auto;
  height: 14px;
  margin-top: 0;
  padding: 5px 10px 10px;
  font-family: arial;
  line-height: 15px;
  position: static;
  bottom: 10px;
  left: 10px;
}

.article-container.bigOneArticle h3.title.bg .articleContext span {
  float: left;
  color: #232b3a;
  padding: 0 2px;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
}

.article-container.bigOneArticle h3.title.bg .articleContext span a {
  padding: 0;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle:hover, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle:hover {
  color: #232b3a !important;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle a, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle a {
  color: #98a3af;
  padding: 0;
  font-family: arial;
  font-size: 12px;
  display: inline;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumTitle a:hover, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryTitle a:hover {
  color: #232b3a !important;
}

.article-container.bigOneArticle h3.title.bg .articleContext span.forumCount, .article-container.bigOneArticle h3.title.bg .articleContext span.galleryCount {
  color: #232b3a;
}

.article-container.bigOneArticle h3.title.bg .articleContext .forumIcon, .article-container.bigOneArticle h3.title.bg .articleContext .galleryIcon {
  display: inline-block;
  position: relative;
}

.container .grid_12 .fb_autocz_banner {
  float: left;
  margin-top: 15px;
  position: relative;
}

.system .article-container.smallArticle .wrapper .articleContext {
  left: 10px !important;
}

.system .xmlcolauto_paid .item.article-container.last {
  border-bottom: none;
}

.system .xmlcolauto_paid .item.article-container {
  border-bottom: 1px solid #cad5df;
  height: 66px;
  padding: 10px 0 11px;
  position: relative;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: 0 !important;
}

.system .xmlcolauto_paid .item.article-container .title {
  float: left;
  width: 150px;
  margin-left: 0;
}

.system .xmlcolauto_paid .item.article-container .title a {
  color: #16212d;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
}

.system .xmlcolauto_paid .item.article-container .image a {
  width: 120px;
  height: 68px;
  display: block;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.system .article-container.middleArticle .wrapper .articleContext, .system .article-container.smallArticle .wrapper .articleContext {
  color: #c9d3df;
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  left: 10px !important;
}

.system .article-container.middleArticle .wrapper .articlePhotoAuto, .system .article-container.smallArticle .wrapper .articlePhotoAuto {
  width: 300px;
  height: 180px;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.system .article-container.middleArticle .wrapper a img, .system .article-container.smallArticle .wrapper a img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.system .article-container.middleArticle .wrapper .category-title a, .system .article-container.smallArticle .wrapper .category-title a, .system .article-container.middleArticle .wrapper h3.title a:hover, .system .article-container.smallArticle .wrapper h3.title a:hover {
  color: red;
}

.recentArticles.outer_4 h4.header {
  text-transform: uppercase;
  color: #fff;
  background-color: red;
  height: 35px;
  margin-bottom: 0;
  padding-left: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
  line-height: 35px;
}

.recentArticles.outer_4 h4.header a {
  color: #fff;
  display: block;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container.first {
  padding-top: 10px;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container.last {
  border-bottom: none;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container {
  border-bottom: 1px solid #cad5df;
  height: 70px;
  padding: 10px 0 11px;
  position: relative;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: 0 !important;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .title {
  float: left;
  width: 165px;
  margin-left: 0;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .title a {
  color: #16212d;
  font-family: arial;
  font-size: 15px;
  font-weight: 700;
  transition: all .1s ease-out;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image {
  float: left;
  width: 120px;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a.iSport {
  width: 120px;
  height: 68px;
  display: block;
  position: relative;
  overflow: hidden;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a.iSport img {
  width: 120px;
  height: 80px;
}

.recentArticles.outer_4 .rec_vertical_paid .item.article-container .image a img {
  width: 120px;
  height: 68px;
}

.recentArticles.outer_4 .footer.more {
  margin: 10px 0;
}

.heightFix .recentArticles .rec_vertical_paid .item.article-container.first {
  padding: 20px 0 10px;
}

.blackBackground {
  float: left;
  position: relative;
  top: -10px;
}

.blackBackground .top3Container {
  background-color: #000;
}

.blackBackground .top3Container .inner_4 .top3Soci-article h3 a {
  color: #fff;
}

.blackBackground .top3Container .noBorderBox.position-3 {
  margin-left: 30px;
}

.sectionHeader .blesk24logorubrikaHP {
  position: absolute;
  top: 10px;
  left: 126px;
}

.synot-liga-video {
  margin-top: 10px;
  margin-bottom: 10px;
}

.linkArticle.article-container .wrapper {
  height: 180px;
  padding-left: 320px;
  overflow: hidden;
  margin-top: 0 !important;
}

.linkArticle.article-container .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.linkArticle.article-container .wrapper h3.title {
  float: left;
  letter-spacing: 0;
  width: 290px;
  padding: 24px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  overflow: visible;
  top: -8px !important;
}

.linkArticle.article-container .content .articleContext {
  color: #c9d3df;
  margin-top: 15px;
  font-family: arial, sans-serif;
  font-size: 11px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 20px;
}

.top3-presentstips {
  background-color: #b00;
  margin-top: 20px;
}

.top3-presentstips a.title {
  color: #fff;
  padding: 5px 10px;
  font-family: arial;
  font-size: 14px;
  position: absolute;
  left: 0;
}

.top3-presentstips a.title:hover {
  color: #000;
}

.top3-presentstips .inner_4 {
  width: 313px;
  margin-bottom: 5px;
  padding: 22px 0 0 7px;
}

.top3-presentstips .inner_4:hover h3 a {
  color: #000 !important;
}

.top3-presentstips .inner_4:first-of-type {
  padding-left: 10px;
}

.top3-presentstips .inner_4 img {
  float: left;
  position: relative;
}

.top3-presentstips .inner_4 h3 {
  float: left;
  border-bottom: 1px solid #ff8a8a;
  width: 138px;
  min-height: 67px;
  margin-left: 5px;
}

.top3-presentstips .inner_4 h3 a {
  color: #fff;
  font-size: 14px;
  line-height: 122%;
}

.top3-presentstips .inner_4 h3 a:hover {
  color: #000;
}

.top3-presentstips .inner_4 .cena {
  color: #fff;
  float: left;
  width: 77px;
  padding: 5px 0 5px 5px;
  position: relative;
}

.top3-presentstips .inner_4 a.buy {
  color: #fff;
  float: left;
  padding: 5px 0;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.top3-presentstips .inner_4 a.buy:hover {
  color: #000;
}

.forumBoxReturn .article-container.middleArticle .wrapper {
  margin: 0;
}

.forumBoxReturn .article-container.middleArticle .wrapper h3.title {
  max-height: 160px;
  padding: 18px 20px 4px 10px;
}

.relatedArticles .article {
  float: left;
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.relatedArticles .article .wrapper {
  height: 180px;
  overflow: hidden;
}

.relatedArticles .article .wrapper span.icon {
  position: absolute;
  top: 70px;
  left: 280px;
  margin: 0 !important;
}

.relatedArticles .article .wrapper h3.title, .relatedArticles .article .wrapper a.title {
  float: left;
  letter-spacing: 0;
  height: auto;
  min-height: 110px;
  padding: 24px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
  width: 270px !important;
}

.relatedArticles .article .wrapper a.title {
  display: block;
}

.relatedArticles .article a {
  width: 270px;
}

.relatedArticles .article a img {
  width: auto !important;
  height: auto !important;
}

.relatedArticles .article.last {
  border-bottom: none !important;
}

.cont-24h-HP {
  float: left;
  background-color: #fff;
  width: 620px;
  margin: 10px;
  position: relative;
}

.cont-24h-HP .header24 {
  height: 40px;
  position: relative;
}

.cont-24h-HP .header24 a.icon-logo24_inverze {
  float: left;
  margin-left: 10px;
  position: relative;
}

.cont-24h-HP .header24 a.nws-more-news {
  color: red;
  text-align: right;
  width: auto;
  margin: 8px 20px 8px 10px;
  padding: 0;
  font-family: tablet_gothic_condensed;
  font-size: 20px;
  line-height: 158%;
  position: absolute;
  right: 0;
}

.cont-24h-HP .header24 .grey-line {
  float: left;
  background-color: #cad5df;
  width: 552px;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: 8px;
}

.cont-24h-HP .nws-list {
  width: 620px;
  height: 17px;
  margin: 5px 0;
}

.cont-24h-HP .nws-list .nws-ts {
  color: #98a3af;
  float: left;
  margin: 3px 18px 0 10px;
  font-size: 13px;
  position: relative;
}

.cont-24h-HP .nws-list a.nws24-title {
  color: #000;
  float: left;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2em;
  position: relative;
}

.cont-24h-HP .nws-list a.nws24-title:hover {
  color: red;
}

.cont-24h-HP .nws-list a.nws24-title:before, .cont-24h-HP .nws-list a.nws24-title:after {
  content: " ";
  pointer-events: none;
  border: solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.cont-24h-HP .nws-list a.nws24-title:before {
  border-width: 11px;
  border-color: rgba(0, 0, 0, 0);
  margin-top: -11px;
}

.cont-24h-HP .nws-list a.nws24-title:after {
  border-width: 5px;
  border-color: rgba(255, 0, 0, 0) rgba(255, 0, 0, 0) rgba(255, 0, 0, 0) #000;
  margin-top: -5px;
}

.cont-24h-HP .nws-list a.nws24-title:hover:after {
  border-left-color: red;
}

.middleArticles {
  position: relative;
  left: -90px;
}

.latestArticles {
  margin-bottom: 0;
}

.latestArticles .categoryArrow .arrow {
  background: none;
}

.toparticles-vert.outer_4 {
  margin-bottom: 10px;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container {
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom: 1px solid #cad5df;
  padding: 10px 0 9px;
  position: relative;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .image {
  float: left;
  width: 120px;
  height: 68px;
  overflow: hidden;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .title {
  float: right;
  width: 155px;
  padding-right: 15px;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container .title a {
  color: #16212d;
  font-family: tablet_gothic_condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  transition: all .1s ease-out;
}

.toparticles-vert.outer_4 .top_vertical_paid .item.article-container.article-container--noBorderBottom {
  border-bottom: 0;
}

.toparticles-vert.outer_4 .categoryArrow .arrow {
  background: none;
}

.maminka-logo {
  background: url("/images/blesk/dist/svg/logo-maminka.svg") 0 0 / 170px 30px no-repeat;
  width: 170px;
  height: 30px;
  margin-top: 30px;
  display: block;
}

.article-container, .item-middle {
  border: 0 #fff;
  overflow: hidden;
}

.article-container h3, .article-container h2, .item-middle h3, .item-middle h2 {
  height: auto;
  margin-top: 0;
  overflow: hidden;
}

.article-container h3 a, .article-container h2 a, .item-middle h3 a, .item-middle h2 a {
  color: #16212d;
  letter-spacing: 0;
  font-family: tablet_gothic_condensed;
  font-size: 30px;
  line-height: 32px;
  transition: all .2s ease-out;
}

.article-container .category-title, .item-middle .category-title {
  text-transform: uppercase;
  color: #000;
  word-spacing: 3px;
  z-index: 1;
  padding: 10px 0 0;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
  line-height: 1;
  position: relative;
}

.article-container .category-title.insideImage, .item-middle .category-title.insideImage {
  text-align: right;
  width: 300px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.article-container .category-title.insideImage > a, .article-container .category-title.insideImage > span, .article-container .category-title.insideImage > a.cat-name, .item-middle .category-title.insideImage > a, .item-middle .category-title.insideImage > span, .item-middle .category-title.insideImage > a.cat-name {
  white-space: nowrap;
  background-color: #fff;
  padding: 9px 10px 5px;
  display: inline-block;
}

.article-container.smallArticle, .item-middle.smallArticle {
  height: 330px;
  padding: 0;
}

.article-container.smallArticle .category-title, .item-middle.smallArticle .category-title {
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: .5px;
  background-color: #fff;
  max-width: 110px;
  padding: 8px 10px 5px;
  font-size: 14px;
  line-height: 1.1;
  position: absolute;
  bottom: 139px;
  left: 0;
}

.article-container.smallArticle .wrapper, .item-middle.smallArticle .wrapper {
  max-height: 138px;
  padding-top: 190px;
  overflow: hidden;
}

.article-container.smallArticle .wrapper a.articlePhoto, .item-middle.smallArticle .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.smallArticle .wrapper a.articlePhoto img, .item-middle.smallArticle .wrapper a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.smallArticle .wrapper h3, .article-container.smallArticle .wrapper h2, .item-middle.smallArticle .wrapper h3, .item-middle.smallArticle .wrapper h2 {
  max-width: 300px;
  height: auto;
  min-height: 126px;
  max-height: 195px;
  margin-top: 0;
  padding: 3px 20px 0;
  font-size: 40px;
}

.article-container.smallArticle .wrapper h3 a, .article-container.smallArticle .wrapper h2 a, .item-middle.smallArticle .wrapper h3 a, .item-middle.smallArticle .wrapper h2 a {
  line-height: 35px;
  display: block;
}

.article-container.smallArticle .wrapper .articlePhoto, .item-middle.smallArticle .wrapper .articlePhoto {
  width: 280px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.smallArticle .wrapper .articleContext, .item-middle.smallArticle .wrapper .articleContext {
  margin-top: 14px;
  font-size: 12px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.smallArticle .wrapper .media-icon, .item-middle.smallArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 155px;
  left: 135px;
}

.article-container.smallArticle.frantiska, .item-middle.smallArticle.frantiska {
  overflow: visible;
}

.article-container.smallArticle.frantiska .wrapper, .item-middle.smallArticle.frantiska .wrapper {
  height: 138px;
  padding-top: 192px;
  overflow: hidden;
}

.article-container.smallArticle.frantiska .articlePhoto, .item-middle.smallArticle.frantiska .articlePhoto {
  width: 320px;
  height: 270px;
  position: absolute;
  top: -80px;
  left: -30px;
  overflow: hidden;
}

.article-container.middleArticle, .item-middle.middleArticle {
  height: 180px;
  padding: 0;
  overflow: hidden;
}

.article-container.middleArticle .wrapper, .item-middle.middleArticle .wrapper {
  height: 180px;
  padding-left: 320px;
  overflow: hidden;
}

.article-container.middleArticle .wrapper a.articlePhoto, .item-middle.middleArticle .wrapper a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.middleArticle .wrapper a.articlePhoto img, .item-middle.middleArticle .wrapper a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.middleArticle .wrapper a.articlePhotoAuto, .item-middle.middleArticle .wrapper a.articlePhotoAuto {
  width: 300px;
  height: 179px;
}

.article-container.middleArticle .wrapper a.articlePhotoAuto img, .item-middle.middleArticle .wrapper a.articlePhotoAuto img {
  width: 300px;
}

.article-container.middleArticle .wrapper h3.title, .article-container.middleArticle .wrapper h2.title, .item-middle.middleArticle .wrapper h3.title, .item-middle.middleArticle .wrapper h2.title {
  float: left;
  letter-spacing: 0;
  width: 276px;
  height: auto;
  min-height: 97px;
  padding: 20px 20px 4px 10px;
  font-size: 30px;
  line-height: 32px;
  position: relative;
}

.article-container.middleArticle .wrapper h3.title a, .article-container.middleArticle .wrapper h2.title a, .item-middle.middleArticle .wrapper h3.title a, .item-middle.middleArticle .wrapper h2.title a {
  max-height: 145px;
  padding-top: 6px;
  line-height: 29px;
  display: inline-block;
  overflow: hidden;
}

.article-container.middleArticle .wrapper h3 .titleInArtFix, .article-container.middleArticle .wrapper h2 .titleInArtFix, .item-middle.middleArticle .wrapper h3 .titleInArtFix, .item-middle.middleArticle .wrapper h2 .titleInArtFix {
  height: 0 !important;
}

.article-container.middleArticle .wrapper .articleContext, .item-middle.middleArticle .wrapper .articleContext {
  float: left;
  margin-top: 23px;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.middleArticle .wrapper .category-title, .item-middle.middleArticle .wrapper .category-title {
  text-transform: uppercase;
  letter-spacing: .5px;
  padding: 20px 10px 1px;
  font-size: 14px;
  line-height: 1;
}

.article-container.middleArticle .wrapper .category-title.insideImage, .item-middle.middleArticle .wrapper .category-title.insideImage {
  padding: 0;
}

.article-container.middleArticle .wrapper .media-icon, .item-middle.middleArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 70px;
  left: 280px;
}

.article-container.middleArticle.searchResult img, .item-middle.middleArticle.searchResult img {
  width: 300px;
  height: 180px;
  top: 0;
  left: 0;
}

.article-container.bigArticle, .item-middle.bigArticle {
  height: 390px;
  padding: 0;
  overflow: hidden;
}

.article-container.bigArticle .category-title, .item-middle.bigArticle .category-title {
  background-color: #fff;
  padding: 10px 10px 6px;
  position: absolute;
  top: 220px;
  left: 0;
}

.article-container.bigArticle .wrapper, .item-middle.bigArticle .wrapper {
  height: 180px;
  padding: 250px 0 0;
  overflow: hidden;
}

.article-container.bigArticle .wrapper .articlePhoto a, .article-container.bigArticle .wrapper .articlePhoto a.autofun, .item-middle.bigArticle .wrapper .articlePhoto a, .item-middle.bigArticle .wrapper .articlePhoto a.autofun {
  width: 620px;
  height: 240px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.bigArticle .wrapper .articlePhoto a img, .article-container.bigArticle .wrapper .articlePhoto a.autofun img, .item-middle.bigArticle .wrapper .articlePhoto a img, .item-middle.bigArticle .wrapper .articlePhoto a.autofun img {
  width: 620px;
  position: absolute;
  top: 0;
  left: 0;
}

.article-container.bigArticle .wrapper h3.title, .article-container.bigArticle .wrapper h2.title, .item-middle.bigArticle .wrapper h3.title, .item-middle.bigArticle .wrapper h2.title {
  float: left;
  letter-spacing: 0;
  height: auto;
  min-height: 100px;
  max-height: 116px;
  padding: 10px;
  position: relative;
}

.article-container.bigArticle .wrapper h3.title a, .article-container.bigArticle .wrapper h2.title a, .item-middle.bigArticle .wrapper h3.title a, .item-middle.bigArticle .wrapper h2.title a {
  font-size: 40px;
  line-height: 107%;
}

.article-container.bigArticle .wrapper .articleContext, .item-middle.bigArticle .wrapper .articleContext {
  margin-top: 10px;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  bottom: 8px;
  left: 10px;
}

.article-container.bigArticle .wrapper .articleContext .forumIcon, .item-middle.bigArticle .wrapper .articleContext .forumIcon {
  position: relative;
  top: 2px;
}

.article-container.bigArticle .wrapper .media-icon, .item-middle.bigArticle .wrapper .media-icon {
  z-index: 10;
  position: absolute;
  top: 220px;
  left: 290px;
}

.item-middle h2.title a {
  line-height: 29px;
}

.xmlcolauto_paid .title {
  padding-right: 10px;
}

.article-container.littleArticle h3.title a, .article-container.littleArticle h2.title a {
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
}

.tab-supermamy-box {
  background-color: #fff;
  margin: 20px 10px;
}

.tab-supermamy-box .box {
  float: left;
  width: 33.33%;
  height: 123px;
  position: relative;
  overflow: hidden;
}

.tab-supermamy-box .box .top3Container {
  background-color: rgba(255, 255, 255, 0);
}

.tab-supermamy-box .box .top3Container .noBorderBox {
  padding: 0;
}

.tab-supermamy-box .box .top3Container .noBorderBox .top3Soci-article {
  padding-left: 10px;
}

.tab-supermamy-box .box.jakzhubnout .noBorderBox {
  padding-left: 6px;
}

.tab-supermamy-box .box.hobby .noBorderBox {
  padding-left: 13px;
}

#weather .three-vertical {
  background: #fff;
  padding-bottom: 10px;
}

#weather .three-vertical .list-article-vertical-middle {
  padding: 20px 10px;
}

#weather .three-vertical .list-article-vertical-middle .title-container .title a {
  color: #16212d;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 24px;
  transition: all .1s ease-out;
}

#weather .three-vertical .link {
  text-align: center;
  color: red;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

#weather .three-vertical .link .dataimg-arrow-right {
  width: 5px;
  margin-left: 5px;
  display: inline-block;
}

.new-project-popup {
  z-index: 99999;
  background: #fff;
  width: 550px;
  height: 560px;
  margin-left: -290px;
  display: none;
  position: fixed;
  top: 50px;
  left: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, .55);
}

.new-project-popup .head {
  color: #fff;
  text-align: center;
  background-color: #e81e25;
  height: 34px;
  padding: 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 37px;
}

.new-project-popup .head .close {
  z-index: 999999;
  text-indent: -9999px;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/blesk-2013/close_14x14.png") no-repeat;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-project-popup .content_wrapper {
  background: url("https://img2.cncenter.cz/images/blesk/karlovyvary-popup.png") 50% 125px no-repeat;
  height: 414px;
}

.new-project-popup .content_wrapper:before, .new-project-popup .content_wrapper:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .description {
  color: #16212d;
  text-align: center;
  width: 100%;
  margin: 20px 0 9px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.new-project-popup .description a {
  width: 235px;
  font-size: 28px;
  display: inline-block;
}

.new-project-popup .description a:after {
  content: "";
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -215px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 15px;
  padding-right: 0;
  position: relative;
  left: -10px;
}

.new-project-popup .logo {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/logo_testy.png?v=1") right 20px no-repeat;
  width: 200px;
  height: 160px;
  margin: 0 auto;
  display: none;
}

.new-project-popup .goto_project {
  text-transform: uppercase;
  color: #fff;
  background: #e81e25;
  width: 172px;
  height: 33px;
  margin: 0 auto;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 39px;
  position: absolute;
  bottom: 117px;
  left: 0;
  right: 0;
}

.new-project-popup .goto_project a {
  color: #fff;
  margin-left: 17px;
}

.new-project-popup .goto_project a:hover {
  text-decoration: none;
}

.new-project-popup .goto_project:before, .new-project-popup .goto_project:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .goto_project svg {
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px;
}

.new-project-popup .popup_favor_like {
  color: #98a3af;
  text-align: center;
  border-top: 1px solid #cad5df;
  width: 504px;
  height: 101px;
  margin: 0 23px;
  overflow: visible;
}

.new-project-popup .popup_favor_like a {
  color: #98a3af;
}

.new-project-popup .popup_favor_like .popup_favor {
  float: left;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/favorites.png?v=1") top no-repeat;
  width: 180px;
  height: 58px;
  margin: 23px 0 0 50px;
  font-size: 13px;
}

.new-project-popup .popup_favor_like .popup_favor a {
  color: #848f9b;
  margin-top: 30px;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.new-project-popup .popup_favor_like .popup_like {
  float: right;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/share.png?v=1") top no-repeat;
  width: 180px;
  height: 58px;
  margin: 23px 62px 0 0;
  font-size: 13px;
}

.new-project-popup .popup_favor_like .popup_like a {
  color: #848f9b;
  margin-top: 30px;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.new-project-popup .popup_favor_like:before, .new-project-popup .popup_favor_like:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.new-project-popup .sharer {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  width: 500px;
  height: 100px;
  padding-top: 30px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 40px;
}

.new-project-popup .sharebtn {
  background: url("https://img2.cncenter.cz/images/blesk/24hodin/sprites.png?v=3") 0 -65px no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.new-project-popup .like-container {
  width: 150px;
  display: inline-block;
  overflow: hidden;
}

.new-project-popup.projekt-regiony .content_wrapper {
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/projekt-regiony-background.png?v=2") center bottom 20px no-repeat;
}

.new-project-popup.projekt-regiony .logo {
  background: url("https://img2.cncenter.cz/images/blesk-2013/popup-new-projects/projekt-regiony-logo.png?v=2") right 20px no-repeat;
  width: 224px;
  margin: 15px 0 0 40px;
}

.new-project-popup.projekt-regiony .goto_project {
  display: none;
}

.new-project-popup.projekt-regiony .description {
  width: 250px;
  padding: 36px 20px 20px 0;
  font-size: 24px;
  line-height: 21px;
}

.new-project-popup.projekt-regiony .popup_favor_like {
  border-top: 1px solid #cad5df;
  width: 486px;
  margin: 10px auto;
}

.new-project-popup.projekt-regiony .popup_favor_like a {
  font-size: 13px;
  font-weight: bold;
}

.new-project-popup.projekt-regiony .popup_favor_like .popup_favor {
  float: left;
  margin-top: 15px;
  margin-left: 90px;
}

.new-project-popup.projekt-regiony .popup_favor_like .popup_like {
  float: right;
  margin-top: 15px;
  margin-right: 90px;
}

.overlay_layer_popup {
  z-index: 9999;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cb {
  clear: both;
}

.socialButtonsfor24hod {
  text-align: center;
}

.socialButtonsfor24hod #fb-like {
  width: 150px;
  display: inline-block;
}

.socialButtonsfor24hod #g-plus {
  width: 90px;
  display: inline-block;
}

.socialButtonsfor24hod #tweet {
  width: 80px;
  display: inline-block;
}

.article-container.littleArticle.redesigned {
  border: none;
  height: 193px;
  padding: 0;
}

.article-container.littleArticle.redesigned .wrapper {
  width: 140px;
  height: 111px;
  margin: 0;
  padding-top: 82px;
  overflow: hidden;
}

.article-container.littleArticle.redesigned .wrapper .title {
  height: 91px;
  padding: 10px;
}

.article-container.littleArticle.redesigned .wrapper .title a {
  height: 91px;
  font-family: arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

.article-container.littleArticle.redesigned .wrapper .articlePhoto {
  width: 140px;
  height: 82px;
}

.article-container.littleArticle.redesigned .wrapper .articlePhoto img {
  width: 140px;
  height: 94px;
}

.article-container.middleArticle.redesigned {
  height: 180px;
}

.article-container.middleArticle.redesigned .wrapper .category-title {
  letter-spacing: .5px;
  padding: 20px 0 0 10px;
  font-size: 14px;
  font-family: tablet_gothic_condensed !important;
}

.article-container.middleArticle.redesigned .wrapper h3.title a {
  font-size: 30px;
  line-height: 32px;
}

.article-container.middleArticle.redesigned .wrapper .articlePhoto .articlePhotoAuto {
  width: 300px;
  height: 180px;
}

.article-container.middleArticle.redesigned .wrapper .articlePhoto .articlePhotoAuto img {
  width: 300px;
}

.article-container.middleArticle a.articlePhoto {
  width: 300px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.middleArticle a.articlePhoto img {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.crossPromo .outer_4 {
  margin: 0 10px;
}

.ksHp .latestArticles {
  padding-top: 0;
}

.ksHp .category-title {
  padding-top: 3px;
}

.sticky-wrapper, .sticky-wrapper-polyfill {
  margin-bottom: 20px;
  padding: 0 15px;
  display: none;
  position: relative;
}

.sticky-wrapper.sticky-bottom, .sticky-wrapper-polyfill.sticky-bottom {
  display: block;
}

.sticky-wrapper .fixed, .sticky-wrapper-polyfill .fixed {
  position: fixed;
  top: 80px;
}

.sticky-wrapper .sticky-content.polyfill-sticky, .sticky-wrapper-polyfill .sticky-content.polyfill-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.sticky-wrapper-polyfill {
  display: block;
}

.synot-liga-video .video-wrapper:hover .title-hidden {
  top: 25px !important;
}

.sc-gLLuof.fRdbir h2 {
  color: #191919;
  border-bottom: 1px solid #e7e7e7;
  font-family: Vafle Classic, arial, Helvetica, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.sc-gLLuof.fRdbir label {
  gap: 0;
  flex-direction: column !important;
}

.sc-gLLuof.fRdbir label .eycUNu {
  color: #888;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.sc-gLLuof.fRdbir label .keVjxN {
  padding: 4px 12px 0 0;
}

.sc-gLLuof.fRdbir label .keVjxN input {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sc-gLLuof.fRdbir label .keVjxN textarea {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  flex-direction: column;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  display: flex;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN {
  clear: both;
  padding: 0 12px 0 0;
  position: relative;
  top: -25px;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
  padding: 5px 0 0 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu {
  color: #191919;
  z-index: 10;
  padding: 0 0 0 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu a {
  text-decoration: underline;
}

@media screen and (max-width: 520px) {
  .sc-gLLuof.fRdbir label.leimcV {
    height: 100px;
    position: relative;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 100px;
    position: absolute;
    top: 4px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 35px;
  }
}

@media screen and (max-width: 320px) {
  .sc-gLLuof.fRdbir label.leimcV, .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 130px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 60px;
  }
}

.mainPhotoTesty {
  float: left;
  width: 940px;
  height: 135px;
  margin: 10px 10px 0;
  position: relative;
}

.imgspekacky {
  background-image: url("/images/blesk/testypotravin/test_spekacky.jpg");
}

.imgnapdetske {
  background-image: url("/images/blesk/testypotravin/test_detske_napoje.jpg");
}

.imgmasla {
  background-image: url("/images/blesk/testypotravin/test_masla.jpg");
}

.imglightjog {
  background-image: url("/images/blesk/testypotravin/test_light_jogurty.jpg");
}

.imgmilkzmrz {
  background-image: url("/images/blesk/testypotravin/test_mlecne_zmrzliny.jpg");
}

.imgjunior {
  background-image: url("/images/blesk/testypotravin/test_junior.jpg");
}

.imgveprsunky {
  background-image: url("/images/blesk/testypotravin/test_vepr_sunky_standart.jpg");
}

.imgveprstava {
  background-image: url("/images/blesk/testypotravin/test_vepr_ve_vl_stave.jpg");
}

.imgmedy {
  background-image: url("/images/blesk/testypotravin/test_medy.jpg");
}

.imgmajonezy {
  background-image: url("/images/blesk/testypotravin/test_majonezy.jpg");
}

.imgkecupy {
  background-image: url("/images/blesk/testypotravin/test_kecupy.jpg");
}

.imgtestoviny {
  background-image: url("/images/blesk/testypotravin/test_testoviny.jpg");
}

.alternative .liveArticleHomeBig:hover .hoverColor {
  color: #540101;
}

.outer_12.alternative.tests {
  height: 74px;
}

.outer_12.alternative.tests .sectionHeader {
  height: 50px;
  background-color: red !important;
  border-top-color: #ffd348 !important;
}

.outer_12.alternative.tests .sectionHeader .dropdown {
  z-index: 100;
  cursor: pointer;
  width: 250px;
  padding-top: 20px;
  display: none;
  position: absolute;
  top: 55px;
  right: 0;
  background-color: red !important;
}

.outer_12.alternative.tests .sectionHeader .dropdown li div {
  border-top: 1px solid #fff;
}

.outer_12.alternative.tests .sectionHeader .dropdown li div:hover {
  text-decoration: underline;
}

.outer_12.alternative.tests .sectionHeader h2 a {
  float: left;
}

.outer_12.alternative.tests .sectionHeader .icon-test_big {
  float: left;
  margin: 0 20px;
  position: relative;
  top: -27px;
}

.outer_12.alternative.tests .sectionHeader .subSections li {
  border: none;
}

.outer_12.alternative.tests .sectionHeader .subSections li:hover .dropdown {
  display: block;
}

.outer_12.alternative.tests .sectionHeader .subSections li div {
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  padding: 0 20px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 23px;
  display: block;
}

.outer_12.alternative.tests .sectionHeader .subSections li div:hover {
  text-decoration: underline;
  color: #540101 !important;
}

.article-container.middleArticle .wrapper .icon.icon-test_small, .article-container.smallArticle .wrapper .icon.icon-test_small {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sportVertical .recentArticles .iconImage {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -450px no-repeat content-box content-box;
  border-top: 0 #cad5df;
  border-bottom: 0 #cad5df;
  height: 52px;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 87px;
  display: block;
}

.sportVertical .recentArticles .isport-logo-top {
  text-align: center;
  height: 42px;
  padding-top: 2px;
  display: block;
}

.sportVertical .recentArticles .isport-logo-top .icon {
  display: inline-block;
}

.sportVertical.article-border-bottom .recentArticles.outer_4 .rec_vertical_paid .item.article-container.last {
  border-bottom: 1px solid #cad5df;
}

.art-wrapp-astro-vestec.w-620 {
  background-color: #fff;
  width: 620px;
  height: 140px;
  margin: 20px 10px;
  font-family: tablet_gothic_compressed, sans-serif;
  position: relative;
}

.art-wrapp-astro-vestec.w-620 .body {
  float: left;
  width: 460px;
  margin: 20px 0 0 20px;
}

.art-wrapp-astro-vestec.w-620 .body .title {
  color: #16212d;
  border-bottom: 1px solid #e0e4f3;
  padding-bottom: 10px;
  font-size: 30px;
}

.art-wrapp-astro-vestec.w-620 .body .phone {
  color: red;
  float: left;
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
}

.art-wrapp-astro-vestec.w-620 .body .name {
  color: red;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 30px;
  font-weight: bold;
}

.art-wrapp-astro-vestec.w-620 .body .sms {
  color: #16212d;
  margin-top: 10px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

.art-wrapp-astro-vestec.w-620 .body .red {
  color: red;
}

.art-wrapp-astro-vestec.w-620 .photo {
  float: left;
  margin-left: 10px;
  position: relative;
}

.art-wrapp-astro-vestec.w-620 .photo img {
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= 0, M12= 0, M21= 0, M22= 0, sizingMethod= "auto expand");
  zoom: 1;
  transform: rotate(-5deg);
  box-shadow: -5px 8px 30px rgba(0, 0, 0, .4);
}

.art-wrapp-astro-vestec.w-300 {
  background-color: #fff;
  width: 300px;
  height: 290px;
  margin: 10px 10px 0;
  font-family: tablet_gothic_compressed, sans-serif;
  position: relative;
}

.art-wrapp-astro-vestec.w-300 .body {
  float: left;
  width: 270px;
  margin: 20px 0 0 20px;
}

.art-wrapp-astro-vestec.w-300 .body .title {
  color: #16212d;
  border-bottom: 1px solid #e0e4f3;
  padding-bottom: 10px;
  font-size: 30px;
}

.art-wrapp-astro-vestec.w-300 .body .phone {
  color: red;
  margin-top: 65px;
  font-size: 30px;
  font-weight: bold;
}

.art-wrapp-astro-vestec.w-300 .body .name {
  color: red;
  margin: 20px 0 40px;
  font-size: 30px;
  font-weight: bold;
}

.art-wrapp-astro-vestec.w-300 .body .sms {
  color: #16212d;
  margin-top: 5px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.5;
}

.art-wrapp-astro-vestec.w-300 .body .red {
  color: red;
}

.art-wrapp-astro-vestec.w-300 .photo {
  float: left;
  margin-left: 10px;
  position: absolute;
  top: 70px;
  left: 190px;
}

.art-wrapp-astro-vestec.w-300 .photo img {
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= 0, M12= 0, M21= 0, M22= 0, sizingMethod= "auto expand");
  zoom: 1;
  transform: rotate(5deg);
  box-shadow: -5px 8px 30px rgba(0, 0, 0, .4);
}

.commercial-info {
  z-index: 1899;
  text-align: center;
  color: #5a5550;
  background: #f0f1f4;
  width: 100%;
  height: 20px;
  font: 12px / 20px arial, Helvetica, sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
}

.galleryInArticle {
  background: #16212d;
  padding: 10px;
}

.galleryInArticle.onlineReport {
  margin-left: -140px !important;
}

.galleryInArticle .header {
  padding: 0 40px 10px;
}

.galleryInArticle .header a {
  text-transform: uppercase;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.galleryInArticle .header .galleryCount {
  float: right;
  color: #e9eff4;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
}

.galleryInArticle .header .title {
  color: #fff;
  padding: 0 5px;
}

.galleryInArticle .description {
  color: #e9eff4;
  padding: 10px 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .imgAuthor {
  color: #e9eff4;
  margin: 0 40px;
  font-size: 12px;
  line-height: 15px;
}

.galleryInArticle .smallPhoto {
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn {
  float: left;
  margin: 0 0 0 20px;
  position: relative;
}

.galleryInArticle .smallPhoto .photoIn img {
  width: 158px;
  height: 100px;
}

.galleryInArticle .smallPhoto .galleryPrev {
  text-indent: 9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -232px no-repeat content-box content-box;
  border-left: 1px solid #3d4952;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  transform: rotate(180deg);
}

.galleryInArticle .smallPhoto .galleryNext {
  text-indent: 9999px;
  float: left;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  width: 10px;
  height: 10px;
  margin: 5px 0 0;
  padding: 40px 0 40px 10px;
  display: block;
  position: relative;
  top: 0;
  right: 5px;
}

.galleryInArticle .photoFrame {
  background-color: #16212d;
}

.exchange-rate-list {
  color: #707b87;
  text-align: center;
  border: 1px solid #cad5df;
  align-items: center;
  width: 316px;
  height: 30px;
  line-height: 30px;
  display: flex;
  position: relative;
}

.exchange-rate-list:before, .exchange-rate-list:after {
  content: " ";
  visibility: hidden;
  clear: both;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.exchange-rate-list a {
  color: #707b87;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 30px;
  line-height: 30px;
  display: inline-flex;
}

.exchange-rate-list a img {
  margin-left: 2px;
  margin-right: 4px;
}

.exchange-rate-list .title {
  text-transform: uppercase;
  width: 72px;
  margin: 3px 7px 0 6px;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
}

.exchange-rate-list .rate {
  font-size: 12px;
}

.exchange-rate-list .currency {
  display: none;
}

.picmenu .sectionHeader > ul {
  box-sizing: border-box;
  max-width: 70%;
}

.picmenu .sectionHeader > .subSections > li {
  vertical-align: middle;
  max-width: 19%;
  height: 50px;
  line-height: normal;
  display: table-cell;
  position: relative;
}

.picmenu .sectionHeader > .subSections > li a {
  padding: 6px 10px 0;
  line-height: 23px;
}

.picmenu .sectionHeader > .subSections > li a.dropdownMenu:after {
  top: 23px;
  right: 8px;
}

.picmenu .sectionHeader > .subSections > li:first-of-type {
  border-left: 0;
}

.picmenu .sectionHeader .dropdown {
  margin-top: -15px;
  padding-top: 20px;
}

.picmenu .sectionHeader .dropdown li {
  line-height: 50px;
}

.picmenu ul {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-position: inside;
}

.picmenu .pic_submenu_item {
  text-transform: uppercase;
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #e2e5e7;
  width: 20%;
  height: 40px;
  margin: 0 0 10px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 16px;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}

.picmenu .pic_submenu_item a {
  color: #000;
  padding-left: 45px;
  line-height: 44px;
  display: block;
}

.picmenu .pic_submenu_item:nth-child(5n+1) {
  border-left: 0;
}

.picmenu .article-container:hover a.hoverColor {
  color: #000 !important;
}

.picmenu .ico {
  position: relative;
}

.picmenu .ico:before {
  content: "";
  background: url("https://img2.cncenter.cz/images/blesk/sprites-cestovani.png") 0 0 / 165px no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.picmenu .ico:after {
  content: "";
  width: 8px;
  height: 10px;
  position: absolute;
  top: 14px;
  right: 10px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -216px no-repeat !important;
}

.picmenu .ico.ico_A_1:before {
  background-position: 0 0;
}

.picmenu .ico.ico_A_2:before {
  background-position: -35px 0;
}

.picmenu .ico.ico_A_3:before {
  background-position: -70px 0;
}

.picmenu .ico.ico_A_4:before {
  background-position: -105px 0;
}

.picmenu .ico.ico_A_5:before {
  background-position: -140px 0;
}

.picmenu .ico.ico_B_1:before {
  background-position: 0 -35px;
}

.picmenu .ico.ico_B_2:before {
  background-position: -35px -35px;
}

.picmenu .ico.ico_B_3:before {
  background-position: -70px -35px;
}

.picmenu .ico.ico_B_4:before {
  background-position: -105px -35px;
}

.picmenu .ico.ico_B_5:before {
  background-position: -140px -35px;
}

.picmenu .ico.ico_C_1:before {
  background-position: 0 -70px;
}

.picmenu .ico.ico_C_2:before {
  background-position: -35px -70px;
}

.picmenu .ico.ico_C_3:before {
  background-position: -70px -70px;
}

.picmenu .ico.ico_C_4:before {
  background-position: -105px -70px;
}

.picmenu .ico.ico_C_5:before {
  background-position: -140px -70px;
}

.picmenu .ico.ico_D_1:before {
  background-position: 0 -105px;
}

.picmenu .ico.ico_D_2:before {
  background-position: -35px -105px;
}

.picmenu .ico.ico_D_3:before {
  background-position: -70px -105px;
}

.picmenu .ico.ico_D_4:before {
  background-position: -105px -105px;
}

.picmenu .ico.ico_D_5:before {
  background-position: -140px -105px;
}

.topCatArt {
  border-bottom: 1px solid #cad5df;
  margin-bottom: 20px;
}

.topCatArt article {
  color: #272424;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3em;
}

.topCatArt article h1 {
  height: 50px;
  margin-bottom: 18px;
  position: relative;
  overflow: hidden;
}

.topCatArt article h1 a {
  color: #fff;
  padding: 0 15px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 30px;
  line-height: 54px;
}

.topCatArt article h1:after {
  content: "";
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 18px;
  right: 15px;
}

.topCatArt article > img {
  float: right;
  background-color: #fff;
  width: 200px;
  min-height: 100px;
  margin: 2px 20px 20px 35px;
}

.topCatArt article > .more {
  text-transform: uppercase;
  clear: left;
  float: left;
  width: auto;
  margin: 15px 0;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 20px;
  position: relative;
}

.topCatArt article > .more:after {
  content: "";
  width: 8px;
  height: 10px;
  position: absolute;
  top: 4px;
  right: -14px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -216px no-repeat !important;
}

img {
  max-width: 100%;
}

.kw .articleContainer .relativeObject {
  margin-left: 0;
}

.box-gallery {
  float: left;
  width: 620px;
  height: 328px;
}

.box-gallery .gallery-main-container .image-description {
  display: none;
}

.box-gallery .gallery-main-container .gallery {
  height: 100%;
}

.box-gallery .gallery-main-container .gallery .icon-loading {
  background: url("https://img2.cncenter.cz/images/blesk-2013/loading.svg?v=1") center no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn {
  height: 100%;
  top: 0;
}

.box-gallery .gallery-main-container .gallery .btn.prev .dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -950px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn.next .dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1000px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn .icon-arrow {
  border-left: 5px solid rgba(0, 0, 0, 0);
}

.box-gallery .gallery-main-container .gallery .next .image-count {
  display: none;
}

.box-gallery .gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

#article .topGallery {
  margin-bottom: 20px;
}

#article .topGallery .photoCarousel {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

#article .topGallery .photoCarousel .jcarousel {
  padding: 0;
}

#article .topGallery .photoCarousel .jcarousel ul.jcarouselList {
  list-style-type: none !important;
}

#article.listed .photoCarousel {
  width: 100%;
  margin: 10px 0;
  display: inline-block;
  position: relative;
  left: 0;
}

#article.listed .photoCarousel a.jcarousel-prev, #article.listed .photoCarousel a.jcarousel-next {
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  border-left: 1px solid #98a3af;
  top: 0;
}

#article.listed .photoCarousel .jcarousel {
  width: 540px;
  margin: 0;
  padding: 0;
  left: 40px;
}

#article.listed .photoCarousel .jcarousel ul.jcarouselList {
  list-style-type: none !important;
}

#article.listed .photoCarousel .jcarousel ul li img {
  margin: 0 10px;
}

#article .recommended .list-article--horizontal-small {
  margin: 0 0 10px;
}

#article .recommended .list-article--horizontal-small .list-article__category-name {
  text-transform: none;
}

#article .recommended .list-article .list-article__title:hover {
  color: #000;
}

.infobox {
  box-sizing: border-box;
  float: left;
  background-color: #fff;
  width: 300px;
  margin: 10px 10px 20px;
  padding: 15px 20px 20px;
}

.infobox .infobox__title {
  text-transform: uppercase;
  color: red;
  word-spacing: 1px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 14px;
}

.infobox .infobox__body {
  float: left;
  font-size: 15px;
  line-height: 1.2em;
  position: relative;
}

.infobox .infobox__body ul {
  float: left;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.infobox .infobox__body ul li {
  float: left;
  border-right: 1px solid #aaa;
  margin-right: 14px;
  padding-right: 14px;
  font-weight: bold;
  line-height: 15px;
  position: relative;
}

.infobox .infobox__body ul li:last-child {
  float: left;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  position: relative;
}

.infobox .infobox__body ul li a {
  color: red;
  font-size: 12px;
}

.br-info {
  box-sizing: border-box;
  float: left;
  background-color: #fff;
  width: 300px;
  margin: 10px 10px 20px;
  padding: 15px 20px 20px;
}

.br-info .br-info-header {
  float: left;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}

.br-info .br-info-header .br-info-title {
  text-transform: uppercase;
  color: red;
  word-spacing: 1px;
  float: left;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 14px;
  position: relative;
}

.br-info .br-info-logo {
  width: 11px;
  display: block;
  position: absolute;
  top: -10px;
  right: 0;
}

.br-info .br-info-content {
  float: left;
  font-size: 15px;
  line-height: 1.2em;
  position: relative;
}

.br-info .br-info-content ul {
  float: left;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.br-info .br-info-content ul li {
  float: left;
  border-right: 1px solid #aaa;
  margin-right: 14px;
  padding-right: 14px;
  font-weight: bold;
  line-height: 15px;
  position: relative;
}

.br-info .br-info-content ul li:last-child {
  float: left;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  position: relative;
}

.br-info .br-info-content ul li a {
  color: red;
  font-size: 12px;
}

.br-header-menu-category {
  width: 940px;
  height: 200px;
  margin: 0 10px 10px;
  position: relative;
  overflow: hidden;
}

.br-header-menu-category .br-links {
  width: 356px;
  height: 43px;
  position: absolute;
  top: 137px;
  left: 21px;
}

.br-header-menu-category .br-links .br-link {
  color: #fff;
  float: left;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  border-right: 2px solid #fff;
  width: 107px;
  height: 43px;
  padding: 0 5px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 15px;
  line-height: 50px;
  display: block;
  overflow: hidden;
}

.br-header-menu-category .br-links .br-link:last-child {
  border-right: none;
}

.br-video-thumbnails {
  float: left;
  background-color: #5d6773;
  width: 280px;
  margin: 10px;
  padding: 10px 10px 0;
}

.br-video-thumbnails .br-separator {
  float: left;
  width: 100%;
  height: 10px;
  position: relative;
}

.br-video-thumbnails .br-more {
  color: #fff;
  float: left;
  text-align: center;
  width: 100%;
  height: 34px;
  font-size: 15px;
  font-weight: bold;
  line-height: 32px;
  display: block;
}

.sport-color-hover.title-link {
  color: #1e1e1e;
}

.breadcrumbs {
  float: left;
  width: 940px;
  margin: 15px 10px;
  position: relative;
}

.breadcrumbs ol {
  width: 100%;
  height: 15px;
  position: relative;
}

.breadcrumbs ol li {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 340px;
  height: 15px;
  margin: 0 10px 0 0;
  line-height: 15px;
  display: inline-block;
  overflow: hidden;
}

.breadcrumbs ol li a {
  color: #aaa;
}

.breadcrumbs ol li a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li:last-of-type a, .breadcrumbs ol li:last-of-type span {
  font-weight: bold;
}

.breadcrumbs ol li span {
  color: #aaa;
  font-size: 13px;
}

.breadcrumbs ol .arrow-icon {
  float: left;
  width: 5px;
  height: 9px;
  margin: 2px 10px 0 0;
  display: inline-block;
  position: relative;
}

.dataimg-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 5 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23AAAAAA' d='M5,4.3L1.3,0L0,0.7l3.1,3.7L0,8.2L1.3,9L5,4.6V4.3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

article#data-brand table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: none;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: normal;
  overflow-x: scroll;
  width: auto !important;
  height: auto !important;
}

article#data-brand tbody {
  width: 100%;
  max-width: 690px;
  display: block;
  overflow-x: auto;
}

article#data-brand tr:first-child td {
  color: #fff;
  vertical-align: middle;
  background: #16212d;
  border: 0;
  font-weight: bold;
  height: 100% !important;
}

article#data-brand tr:first-child td:before, article#data-brand tr:first-child td:after {
  display: none;
}

article#data-brand td {
  vertical-align: middle;
  background: #fff;
  padding: 5px 0 5px 30px;
  position: relative;
  height: 100% !important;
}

article#data-brand td:before {
  content: " ";
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

article#data-brand td:first-child {
  z-index: 1;
  padding-right: 30px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

article#data-brand td:first-child:before {
  width: calc(100% - 20px);
}

article#data-brand td:first-child:after {
  content: " ";
  background: #dcdcdc;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

article#data-brand td:last-child {
  padding-right: 30px;
}

article#data-brand td:last-child:before {
  width: calc(100% - 20px);
  left: 0;
  right: auto;
}

article#data-brand td strong {
  font-weight: normal;
}

@font-face {
  font-family: Bitter;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/dist/assets/default/fonts/bitter.woff2") format("woff2"), url("https://img2.cncenter.cz/dist/assets/default/fonts/bitter.woff") format("woff");
}

@font-face {
  font-family: Tablet Gothic Condensed;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/dist/assets/default/fonts/open_sans.woff2") format("woff2"), url("https://img2.cncenter.cz/dist/assets/default/fonts/open_sans.woff2") format("woff");
}

#piano-zamek-za-registraci-custom {
  position: relative;
}

#piano-zamek-za-registraci-custom:empty {
  display: none;
}

#piano-zamek-za-registraci-custom:before {
  content: "";
  background: linear-gradient(rgba(234, 239, 243, .5) 0%, #eaeff3 100%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

@media (max-width: 600px) {
  #piano-zamek-za-registraci-custom {
    position: relative;
  }

  #piano-zamek-za-registraci-custom:empty {
    display: none;
  }

  #piano-zamek-za-registraci-custom:before {
    content: "";
    background: linear-gradient(rgba(255, 255, 255, .5) 0%, #fff 100%);
    width: 100%;
    height: 200px;
    display: block;
    position: absolute;
    top: -200px;
  }
}

.body-wrapper--white #piano-zamek-za-registraci-custom:before {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
}

.piano-registration {
  background: #fff;
  border: 1px solid #f6f6f6;
  flex-direction: column;
  align-items: center;
  width: 538px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 6px -2px rgba(25, 25, 25, .04), 0 12px 16px -4px rgba(25, 25, 25, .08);
}

.piano-registration__content {
  color: #191919;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
}

.piano-registration__text {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 32px 24px 0;
  display: flex;
}

.piano-registration__locked {
  background: #ffdde3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.piano-registration__titles-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
}

.piano-registration__titles {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.piano-registration__titles-text {
  letter-spacing: 0;
  text-align: center;
  font-family: Tablet Gothic Condensed, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

.piano-registration__subtitle {
  letter-spacing: 0;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.piano-registration__benefits {
  align-items: left;
  color: #191919;
  letter-spacing: 0;
  text-align: left;
  flex-direction: column;
  gap: 12px;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
}

.piano-registration__benefits li {
  align-items: center;
  gap: 8px;
  display: flex;
}

.piano-registration__benefits li strong {
  font-family: Arial, sans-serif !important;
  font-weight: 700 !important;
}

.piano-registration__button {
  box-sizing: border-box;
  color: #fff;
  letter-spacing: .01em;
  text-align: left;
  background: red;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 296px;
  height: 52px;
  padding: 12px 24px;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  text-decoration: none !important;
}

.piano-registration__button:hover {
  background: #af0523;
}

.piano-registration__login {
  background: #f6f6f6;
  border-top: 1px solid #e7e7e7;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 492px;
  padding: 32px 24px 40px;
  display: flex;
}

.piano-registration__login-link {
  color: #191919;
  letter-spacing: 0;
  text-align: left;
  align-items: flex-start;
  gap: 8px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
}

.piano-registration__socials {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.piano-registration__social {
  color: #191919;
  letter-spacing: .32px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 296px;
  padding: 10px 16px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  text-decoration: none !important;
}

.piano-registration__email {
  color: #191919;
  text-decoration: none !important;
}

.piano-registration__email .underline {
  text-decoration: underline !important;
}

.piano-registration__email:hover .underline {
  text-decoration: none !important;
}

@media (max-width: 600px) {
  .piano-registration {
    width: 100%;
  }

  .piano-registration__content {
    gap: 24px;
  }

  .piano-registration__text {
    padding: 24px 16px 0;
  }

  .piano-registration__titles-text {
    font-size: 26px;
    line-height: 32px;
  }

  .piano-registration__benefits {
    text-align: left;
    letter-spacing: 0 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  .piano-registration__benefits li strong {
    text-align: left;
    letter-spacing: 0 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
  }

  .piano-registration__benefits li {
    align-items: flex-start;
  }

  .piano-registration__benefits li svg {
    flex-shrink: 0;
    margin-top: 4px;
  }

  .piano-registration__button {
    width: calc(100% - 48px);
  }

  .piano-registration__login {
    width: calc(100% - 32px);
    padding: 24px 16px 32px;
  }

  .piano-registration__socials {
    align-items: center;
  }

  .piano-registration__social {
    width: 100%;
  }
}

.articles-bottom {
  clear: both;
  padding: 0 10px;
}

.articles-bottom .header-small {
  text-transform: uppercase;
  box-sizing: border-box;
  color: #fff;
  background: red;
  width: 100%;
  height: 30px;
  margin: 10px 0;
  padding-left: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 15px;
  font-weight: bold;
  line-height: 36px;
}

.articles-bottom .articles__list--recombee {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.articles-bottom .articles__item {
  background-color: #fff;
  width: 140px;
  min-height: 193px;
}

.articles-bottom article.articles__item {
  margin-bottom: 35px;
}

.articles-bottom .list-article__img-wrapper {
  position: relative;
}

.articles-bottom .list-article__img-wrapper img {
  max-width: 100%;
  height: auto;
  display: block;
}

.articles-bottom .list-article__category-name {
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  text-align: left;
  background-color: rgba(152, 163, 175, .8);
  width: 100%;
  height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.articles-bottom .list-article__category-name a {
  color: #fff;
}

.articles-bottom .list-article__title {
  color: #000;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
}

.articles-bottom .list-article__title:hover {
  color: red;
}

.articles-bottom .list-article__title a {
  color: inherit;
  text-decoration: none;
}

.articles-bottom .list-article__title a:hover {
  text-decoration: underline;
}

.social-container-bottom {
  clear: both;
  width: 620px;
  margin: 20px 0 0 -80px;
}

.listed .social-container-bottom {
  margin-left: 0;
}

.social-container-bottom .author {
  float: left;
  color: #98a3af;
  max-width: 50%;
  font-size: 15px;
}

.social-container-bottom .socialMedia {
  float: left;
  width: auto;
  margin: 10px 0 0;
}

#article .social-container-bottom .author {
  float: none;
}

#article .metaArticle {
  border-bottom: 1px solid #cad5df;
  width: 620px;
  height: auto;
  margin-left: -80px;
  padding-bottom: 10px;
  line-height: 23px;
  position: relative;
}

#article .metaArticle .dateTime, #article .metaArticle .commercial-article {
  float: left;
  text-align: left;
  margin: 0;
  font-weight: normal;
  line-height: 23px;
}

#article .metaArticle .commercial-article {
  margin-right: 3px;
}

#article .metaArticle .dateTimeUpdated {
  color: red;
  font-size: 13px;
  line-height: 18px;
  position: absolute;
  top: -18px;
  left: 0;
}

#article .metaArticle.articleDetails {
  width: 100%;
  padding: 0 0 10px;
}

#article .metaArticle .socialMedia, #article .metaArticle .socialMediaNoad {
  float: right;
}

#article .commercial-text {
  color: #16212d;
  background-color: #f1e9c1;
  margin-top: 10px;
  margin-left: -80px;
  padding: 2px 13px;
  font-size: 15px;
}

.socialMedia .share-text, .socialMediaNoad .share-text {
  color: #98a3af;
  font-size: 15px;
}

.metaArticle .socialMedia, .metaArticle .socialMediaNoad {
  width: auto;
}

span[class^="dataimg-social-line"] {
  background-position: center;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  display: block;
}

span[class^="dataimg-social-line"] svg {
  width: 24px;
  height: 24px;
}

.dataimg-social-line-twitter {
  height: 29px;
}

.dataimg-social-line-mail {
  height: 20px;
}

.list-article-small {
  float: left;
  background-color: #fff;
  width: 300px;
  height: 90px;
  margin: 0 10px 10px;
  position: relative;
}

.list-article-small * {
  position: relative;
}

.list-article-small .list-article-small-title {
  color: #000;
  float: left;
  width: 130px;
  height: 75px;
  margin: 8px 10px 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  display: block;
  overflow: hidden;
}

.list-article-small .list-article-small-title:hover {
  color: red;
}

.list-article-small .list-article-small-thumbnail {
  float: right;
  width: 150px;
  height: 90px;
  display: block;
}

.list-article-small .list-article-small-thumbnail img {
  float: left;
  width: 100%;
  height: 100%;
  display: block;
}

.list-article-small .list-article-small-supertitle {
  color: #fff;
  box-sizing: border-box;
  background-color: rgba(152, 163, 175, .8);
  width: 50%;
  height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-article-small.textonly {
  height: auto;
}

.list-article-small.textonly .list-article-small-title {
  width: 270px;
  height: auto;
}

.section-header-small {
  float: left;
  width: 300px;
  height: 30px;
  margin: 10px;
  line-height: 30px;
  position: relative;
}

.section-header-small * {
  position: relative;
}

.section-header-small .section-header-small-title {
  color: #fff;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  height: 30px;
  margin-left: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  display: block;
  overflow: hidden;
}

.section-header-small .section-header-small-logo {
  float: right;
  height: 20px;
  margin: 5px 5px 5px 0;
  display: block;
}

.section-header-small .section-header-small-logo img {
  float: left;
  height: 100%;
  display: block;
}

.unimenu-section {
  box-sizing: border-box;
  z-index: 6;
  border-top: 4px solid #083e89;
  padding: 10px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  position: relative;
}

.unimenu-section__title {
  float: left;
  text-transform: uppercase;
  font-family: tablet_gothic_compressed, arial, Helvetica, sans-serif;
  font-size: 50px;
}

.unimenu-section__title a {
  color: #16212d;
}

.unimenu-section__icon-image {
  float: left;
}

.unimenu-section__menu {
  float: right;
  height: 50px;
  margin: 0;
}

.unimenu-section__item {
  float: left;
  height: 50px;
  padding-bottom: 10px;
  position: relative;
}

.unimenu-section__item:hover .unimenu-section__list {
  display: block;
}

.unimenu-section__item + .unimenu-section__item a {
  border-left: 1px solid #cad5df;
}

.unimenu-section__item + .unimenu-section__item .unimenu-section__list a {
  border-left: none;
}

.unimenu-section__link {
  float: left;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #083e89;
  height: 50px;
  padding: 0 20px;
  font-family: tablet_gothic_compressed, arial, Helvetica, sans-serif;
  font-size: 23px;
  line-height: 50px;
}

.unimenu-section__link.unimenu-section__link--arrow {
  padding: 0 30px 0 20px;
}

.unimenu-section__link.unimenu-section__link--arrow:after {
  content: "";
  border-top: 8px solid #6e78dc;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 20px;
  right: 10px;
}

.unimenu-section__list {
  box-sizing: border-box;
  z-index: 9999;
  background-color: #083e89;
  padding: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
}

.unimenu-section__list .unimenu-section__link {
  box-sizing: border-box;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  border-bottom: 1px solid #cad5df;
  width: 100%;
  height: 50px;
  font-size: 23px;
  line-height: 50px;
  overflow: hidden;
}

.unimenu-section__list .unimenu-section__link:hover {
  color: #191e56;
}

.unimenu-section__list .unimenu-section__item {
  width: 100%;
  padding: 0;
}

.unimenu-section__list .unimenu-section__item:last-child .unimenu-section__link {
  border-bottom: none;
}

.unimenu-section--news {
  border-color: #083e89;
}

.unimenu-section--news .unimenu-section__link {
  color: #083e89;
}

.unimenu-section--news .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #083e89;
}

.unimenu-section--news .unimenu-section__list {
  background-color: #083e89;
}

.unimenu-section--news .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--news .unimenu-section__list .unimenu-section__link:hover {
  color: #021229;
}

.unimenu-section--regions {
  border-color: #083e89;
}

.unimenu-section--regions .unimenu-section__link {
  color: #083e89;
}

.unimenu-section--regions .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #083e89;
}

.unimenu-section--regions .unimenu-section__list {
  background-color: #083e89;
}

.unimenu-section--regions .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--regions .unimenu-section__list .unimenu-section__link:hover {
  color: #021229;
}

.unimenu-section--celebrities {
  border-color: red;
}

.unimenu-section--celebrities .unimenu-section__link {
  color: red;
}

.unimenu-section--celebrities .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: red;
}

.unimenu-section--celebrities .unimenu-section__list {
  background-color: red;
}

.unimenu-section--celebrities .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--celebrities .unimenu-section__list .unimenu-section__link:hover {
  color: #900;
}

.unimenu-section--tests {
  border-color: red;
}

.unimenu-section--tests .unimenu-section__link {
  color: red;
}

.unimenu-section--tests .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: red;
}

.unimenu-section--tests .unimenu-section__list {
  background-color: red;
}

.unimenu-section--tests .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--tests .unimenu-section__list .unimenu-section__link:hover {
  color: #900;
}

.unimenu-section--tv-blesk {
  z-index: 10;
  border-color: #b469f5;
}

.unimenu-section--tv-blesk .unimenu-section__icon-image {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -750px -800px no-repeat content-box content-box;
  width: 190px;
  height: 60px;
  overflow: visible;
}

.unimenu-section--tv-blesk .unimenu-section__link {
  color: #b469f5;
}

.unimenu-section--tv-blesk .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #b469f5;
}

.unimenu-section--tv-blesk .unimenu-section__list {
  background-color: #b469f5;
}

.unimenu-section--tv-blesk .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--tv-blesk .unimenu-section__list .unimenu-section__link:hover {
  color: #8410e9;
}

.unimenu-section--travel {
  border-color: #3cc3dc;
}

.unimenu-section--travel .unimenu-section__link {
  color: #3cc3dc;
}

.unimenu-section--travel .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #3cc3dc;
}

.unimenu-section--travel .unimenu-section__list {
  background-color: #3cc3dc;
}

.unimenu-section--travel .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--travel .unimenu-section__list .unimenu-section__link:hover {
  color: #1b8497;
}

.unimenu-section--fashion {
  border-color: #00c8be;
}

.unimenu-section--fashion .unimenu-section__link {
  color: #00c8be;
}

.unimenu-section--fashion .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #00c8be;
}

.unimenu-section--fashion .unimenu-section__list {
  background-color: #00c8be;
}

.unimenu-section--fashion .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--fashion .unimenu-section__list .unimenu-section__link:hover {
  color: #00625d;
}

.unimenu-section--sport {
  border-color: #91c837;
}

.unimenu-section--sport .unimenu-section__link {
  color: #91c837;
}

.unimenu-section--sport .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #91c837;
}

.unimenu-section--sport .unimenu-section__list {
  background-color: #91c837;
}

.unimenu-section--sport .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--sport .unimenu-section__list .unimenu-section__link:hover {
  color: #577821;
}

.unimenu-section--for-women {
  border-color: #fe60b2;
}

.unimenu-section--for-women .unimenu-section__link {
  color: #fe60b2;
}

.unimenu-section--for-women .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #fe60b2;
}

.unimenu-section--for-women .unimenu-section__list {
  background-color: #fe60b2;
}

.unimenu-section--for-women .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--for-women .unimenu-section__list .unimenu-section__link:hover {
  color: #f60281;
}

.unimenu-section--housing {
  border-color: #ff7300;
}

.unimenu-section--housing .unimenu-section__link {
  color: #ff7300;
}

.unimenu-section--housing .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #ff7300;
}

.unimenu-section--housing .unimenu-section__list {
  background-color: #ff7300;
}

.unimenu-section--housing .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--housing .unimenu-section__list .unimenu-section__link:hover {
  color: #994500;
}

.unimenu-section--horoscopes {
  border-color: #b469f5;
}

.unimenu-section--horoscopes .unimenu-section__link {
  color: #b469f5;
}

.unimenu-section--horoscopes .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #b469f5;
}

.unimenu-section--horoscopes .unimenu-section__list {
  background-color: #b469f5;
}

.unimenu-section--horoscopes .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--horoscopes .unimenu-section__list .unimenu-section__link:hover {
  color: #8410e9;
}

.unimenu-section--digital {
  border-color: #99a4aa;
}

.unimenu-section--digital .unimenu-section__link {
  color: #99a4aa;
}

.unimenu-section--digital .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #99a4aa;
}

.unimenu-section--digital .unimenu-section__list {
  background-color: #e9eff4;
}

.unimenu-section--digital .unimenu-section__list .unimenu-section__link, .unimenu-section--digital .unimenu-section__list .unimenu-section__link:hover {
  color: #99a4aa;
}

.unimenu-section--advisor {
  border-color: #23aaff;
}

.unimenu-section--advisor .unimenu-section__link {
  color: #23aaff;
}

.unimenu-section--advisor .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #23aaff;
}

.unimenu-section--advisor .unimenu-section__list {
  background-color: #23aaff;
}

.unimenu-section--advisor .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--advisor .unimenu-section__list .unimenu-section__link:hover {
  color: #0073bc;
}

.unimenu-section--for-men {
  border-color: #ff5a00;
}

.unimenu-section--for-men .unimenu-section__link {
  color: #ff5a00;
}

.unimenu-section--for-men .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: #ff5a00;
}

.unimenu-section--for-men .unimenu-section__list {
  background-color: #ff5a00;
}

.unimenu-section--for-men .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--for-men .unimenu-section__list .unimenu-section__link:hover {
  color: #993600;
}

.unimenu-section--car {
  border-color: red;
}

.unimenu-section--car .unimenu-section__link {
  color: red;
}

.unimenu-section--car .unimenu-section__link.unimenu-section__link--arrow:after {
  border-top-color: red;
}

.unimenu-section--car .unimenu-section__list {
  background-color: red;
}

.unimenu-section--car .unimenu-section__list .unimenu-section__link {
  color: #fff;
}

.unimenu-section--car .unimenu-section__list .unimenu-section__link:hover {
  color: #900;
}

.unimenu-section--hp {
  float: left;
  width: calc(100% - 20px);
  margin: 20px 10px 0;
}

.component-menu-category {
  width: 940px;
  height: 78px;
  margin: 0 10px 10px;
  position: relative;
}

.component-menu-category .cmc-title {
  color: #fff;
  height: 75px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 50px;
  line-height: 88px;
  position: absolute;
  top: 0;
  left: 30px;
}

.component-menu-category .cmc-title a {
  color: #fff;
}

.component-menu-category .cmc-container {
  float: right;
  height: 25px;
  margin: 27px 20px 26px 0;
}

.component-menu-category .cmc-container .cmc-box {
  float: left;
  height: 25px;
  padding-bottom: 10px;
  position: relative;
}

.component-menu-category .cmc-container .cmc-box:hover .cmc-list {
  display: block;
}

.component-menu-category .cmc-container .cmc-box .cmc-link {
  color: #fff;
  float: left;
  text-transform: uppercase;
  border-right: 1px solid #fff;
  height: 25px;
  margin-right: 20px;
  padding-right: 20px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 30px;
  display: block;
}

.component-menu-category .cmc-container .cmc-box .cmc-link .cmc-arrow {
  float: right;
  margin: 10px 0 0 7px;
  display: block;
}

.component-menu-category .cmc-container .cmc-box:last-of-type .cmc-link {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.component-menu-category .cmc-container .cmc-box:last-of-type .cmc-list {
  left: auto;
  right: -20px;
}

.component-menu-category .cmc-container .cmc-box .cmc-list {
  box-sizing: border-box;
  z-index: 9999;
  background-color: #16212d;
  padding: 10px 20px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.component-menu-category .cmc-container .cmc-box .cmc-list .cmc-link {
  color: #e9eff4;
  float: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  border-right: none;
  width: 100%;
  height: 29px;
  margin-right: 0;
  padding-right: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 33px;
  display: block;
  overflow: hidden;
}

.component-menu-category.testy, .component-menu-category.celebrity {
  background: red;
  border-top-color: #540101;
}

.component-menu-category.testy .cmc-title a, .component-menu-category.celebrity .cmc-title a {
  color: #fff !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a {
  margin-right: 10px !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a:after, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a:after {
  top: 18px;
  right: 7px;
  background: url("//img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a.noarrow, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a.noarrow {
  margin-right: 0 !important;
}

.component-menu-category.testy .cmc-container .cmc-box:last-child > a.noarrow:after, .component-menu-category.celebrity .cmc-container .cmc-box:last-child > a.noarrow:after {
  content: none;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-link, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-link {
  color: #fff !important;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-list, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-list {
  background: red !important;
}

.component-menu-category.testy .cmc-container .cmc-box .cmc-list .cmc-link:hover, .component-menu-category.celebrity .cmc-container .cmc-box .cmc-list .cmc-link:hover {
  color: #540101 !important;
}

.br-header-category {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.br-header-category .br-box {
  background-color: #fff;
  min-width: 356px;
  max-width: 50%;
  height: 95px;
  position: absolute;
  top: 42px;
  left: 21px;
}

.br-header-category .br-box .br-logo {
  width: 22px;
  display: block;
  position: absolute;
  top: 19px;
  left: 20px;
}

.br-header-category .br-box .br-title {
  color: #16212d;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  height: 30px;
  margin: 20px 20px 0 61px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  top: 20px;
  left: 61px;
  overflow: hidden;
}

.br-header-category .br-box .br-title * {
  color: #16212d;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.br-header-category .br-box .br-subtitle {
  color: #96a2ae;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 16px;
  left: 61px;
}

.br-header-category .br-bg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.br-video-thumbnail {
  float: left;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.br-video-thumbnail:hover .br-title {
  text-decoration: underline;
}

.br-video-thumbnail:hover .br-overlay {
  opacity: .9;
}

.br-video-thumbnail .br-overlay {
  background-color: #161e2b;
  height: 35px;
  transition: opacity .2s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 40px 25px #161e2b;
}

.br-video-thumbnail .br-icon-play {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.br-video-thumbnail .br-icon-duration {
  position: absolute;
  bottom: 52px;
  left: 10px;
}

.br-video-thumbnail .br-duration {
  color: #98a3af;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 53px;
  left: 30px;
}

.br-video-thumbnail .br-title {
  color: #fff;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  right: 10px;
  overflow: hidden;
}

.media-icons-wrapper {
  background: rgba(152, 163, 175, .75);
  width: 28px;
  height: 22px;
  padding: 14px 11px;
  position: absolute;
  top: 130px;
  right: 0;
}

.media-icons-wrapper > img.icon {
  width: 28px !important;
  height: 21px !important;
  position: static !important;
}

.media-icons-wrapper.small {
  width: 18px;
  height: 16px;
  padding: 7px;
}

.media-icons-wrapper.small > img.icon {
  width: 16px !important;
  height: 16px !important;
}

.media-icons-wrapper.medium {
  height: 22px !important;
}

.media-icons-wrapper.large {
  width: 32px;
  height: 32px;
  padding: 14px;
}

.media-icons-wrapper.large > img.icon {
  width: 32px !important;
  height: 32px !important;
}

.media-icons-wrapper.play {
  width: 26px !important;
  height: 26px !important;
  padding: 12px !important;
}

.media-icons-wrapper.play > img.icon {
  width: 26px !important;
  height: 26px !important;
  position: static !important;
}

.article-container.middleArticle .media-icons-wrapper {
  left: 250px;
  right: auto;
}

.article-container.bigArticle .media-icons-wrapper {
  top: 190px;
}

.article-container.bigArticle .media-icons-wrapper.large {
  top: 180px;
  width: 32px !important;
  height: 32px !important;
  padding: 14px !important;
}

.article-container.bigArticle .media-icons-wrapper.large > img.icon {
  width: 32px !important;
  height: 32px !important;
}

.top-article-wrap .item-middle .media-icons-wrapper {
  left: 250px;
}

.top-article-wrap .item-big .media-icons-wrapper {
  top: 180px;
  width: 32px !important;
  height: 32px !important;
  padding: 14px !important;
}

.top-article-wrap .item-big .media-icons-wrapper > img.icon {
  width: 32px !important;
  height: 32px !important;
}

.linkCol .media-icons-wrapper {
  top: 108px;
}

.linkCol-art .videoFirstPic > a .media-icons-wrapper {
  top: 118px;
  left: 230px;
}

.vertVideoBig .linkCol > a .media-icons-wrapper {
  width: 26px !important;
  height: 26px !important;
  padding: 12px !important;
}

.vertVideoBig .linkCol > a .media-icons-wrapper img.icon {
  width: 26px !important;
  height: 26px !important;
}

.vertVideoSmall .linkCol > a .media-icons-wrapper {
  top: 52px;
  left: 88px;
}

.behavioral-tabcontent-wrap .item-big .content .media-icons-wrapper {
  top: -50px;
  left: 230px;
}

.nws-art.bigArticle .articlePhoto .media-icons-wrapper {
  top: 257px;
  right: 20px;
}

.videobox .item .media-icons-wrapper {
  top: 108px;
  right: 0;
}

.error404, .error410 {
  box-sizing: border-box;
  background: url("/images/blesk/background-error.png") no-repeat;
  width: 540px;
  min-height: 176px;
  margin: 20px auto 0;
  padding: 34px 37px 0;
}

.error404 .status, .error410 .status {
  color: #98a3af;
  float: right;
  text-align: center;
  width: 175px;
  font-family: tablet_gothic_condensed_extra, sans-serif;
  font-size: 60px;
  line-height: 105px;
}

.error404 .apologise, .error410 .apologise {
  color: #16212d;
  vertical-align: middle;
  text-align: center;
  width: 291px;
  height: 94px;
  padding-top: 12px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 24px;
  display: table-cell;
}

.error404 .apologise span, .error410 .apologise span {
  text-align: left;
  display: inline-block;
}

.error404 a.return-to-hp, .error410 a.return-to-hp {
  clear: both;
  text-align: center;
  margin: 50px 0;
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.error404 a.return-to-hp:hover, .error410 a.return-to-hp:hover {
  text-decoration: none;
}

.error404 a.return-to-hp:before, .error404 a.return-to-hp:after, .error410 a.return-to-hp:before, .error410 a.return-to-hp:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: red;
  width: 2px;
  height: 5px;
  display: block;
  position: absolute;
  top: 3px;
  right: -10px;
  transform: skewX(40deg);
}

.error404 a.return-to-hp:after, .error410 a.return-to-hp:after {
  transform-origin: 100% 0;
  top: 8px;
  transform: skewX(-40deg);
}

#notification-bubble {
  z-index: 9;
  background: url("https://img2.cncenter.cz/images/blesk/sprite/icons/icon-notification.png") no-repeat;
  width: 50px;
  height: 63px;
  position: fixed;
  bottom: 5px;
  right: 5px;
}

#notification-bubble:hover {
  cursor: pointer;
}

#notification-bubble:hover:before, #notification-bubble:hover:after {
  transition: transform .3s linear 50ms;
  transform: translateX(calc(-100% - 15px))scaleX(1);
}

#notification-bubble:hover:after {
  transition: transform 50ms linear;
  transform: scaleX(1);
}

#notification-bubble:before, #notification-bubble .unsubscribe-confirmation {
  content: "Odebírat MIMOŘÁDNÉ ZPRÁVY";
  transform-origin: 100%;
  color: #fff;
  white-space: nowrap;
  background-color: #000;
  border-radius: 5px;
  height: 44px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 44px;
  transition: transform .3s linear;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 15px))scaleX(0);
}

#notification-bubble .unsubscribe-confirmation {
  transform: translateX(calc(-100% - 15px))scaleX(1);
}

#notification-bubble:after, #notification-bubble .unsubscribe-confirmation:after {
  content: "";
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 12px solid #000;
  border-right-width: 12px;
  width: 0;
  height: 0;
  transition: transform 50ms linear .3s;
  display: block;
  position: absolute;
  top: 14px;
  left: -16px;
  transform: scaleX(0);
}

#notification-bubble .unsubscribe-confirmation:after {
  left: auto;
  right: -23px;
  transform: scaleX(1);
}

#notification-bubble.subscribed {
  transform-origin: 100% 100%;
  opacity: .5;
  transition: transform .175s ease-in-out, opacity .175s ease-in-out;
  transform: scale(.52);
}

#notification-bubble.subscribed:hover {
  transform-origin: 100% 100%;
  opacity: 1;
  transition: transform .175s ease-in-out, opacity .175s ease-in-out;
  transform: scale(1);
}

#notification-bubble.subscribed:before {
  content: "Nyní odebíráte notifikace";
}

#notification-bubble.to-unsubscribe {
  cursor: pointer;
}

#notification-bubble.to-unsubscribe:before {
  content: "Klikněte pro zrušení odběru notifikací";
  background-color: #eb212e;
  transition: transform .3s linear 50ms;
  transform: translateX(calc(-100% - 15px))scaleX(1);
}

#notification-bubble.to-unsubscribe:after {
  content: none;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 200000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, arial, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, arial, sans-serif;
  font-size: 26px;
  line-height: 36px;
}

.cookie-bar__title br {
  display: none;
}

.cookie-bar__text {
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: inline;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 15px 10px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 0;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

.box-special {
  background-color: #fff;
  border: 1px solid #cad5df;
  padding-bottom: 10px;
  position: relative;
}

.box-special article.list-article-horizontal-small {
  background: #fff;
  border-bottom: 1px solid #cad5df;
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 15px 0;
  position: relative;
}

.box-special article.list-article-horizontal-small:after, .box-special article.list-article-horizontal-small:before {
  content: "";
  clear: both;
  display: table;
}

.box-special article.list-article-horizontal-small:first-of-type {
  padding-top: 0;
}

.box-special article.list-article-horizontal-small:last-of-type {
  margin-bottom: 20px;
}

.box-special article.list-article-horizontal-small .image-container {
  float: left;
  width: 49%;
  height: auto;
  max-height: 180px;
  margin-right: 0;
  overflow: hidden;
}

.box-special article.list-article-horizontal-small .image-container img {
  width: 100%;
  height: auto;
}

.box-special article.list-article-horizontal-small .title-container {
  float: right;
  width: 46.5%;
  margin: 0;
  position: relative;
}

.box-special article.list-article-horizontal-small .title-container h2 {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3em;
}

.box-special__header {
  z-index: 1;
  color: #fff;
  background-color: red;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}

.box-special__header a {
  text-decoration: none;
}

.box-special__header a:hover, .box-special__header a:focus {
  text-decoration: underline;
}

.box-special__header-left {
  float: left;
  width: 50%;
}

.box-special__title {
  font-size: 22px;
  font-weight: bold;
  line-height: 20px;
}

.box-special__title a, .box-special__title:hover, .box-special__title:focus {
  color: #fff;
}

.box-special__subtitle {
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

.box-special__header-right {
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #c51a1a;
  width: 50%;
  min-height: 55px;
  padding-left: 10px;
}

.box-special__header-right a {
  color: #fff;
  text-transform: uppercase;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  position: relative;
}

.box-special__header-right a:after {
  content: "";
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #fff;
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 0;
}

.box-special__link {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.box-special__link:hover {
  text-decoration: none;
}

.box-special__link:after {
  content: "";
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid red;
  margin-left: 5px;
  display: inline-block;
}

.box-special--elections .box-special__header {
  color: #16212d;
  background-color: #f4f7f9;
}

.box-special--elections .box-special__subtitle a, .box-special--elections .box-special__title a {
  color: #16212d;
}

.box-special--elections .box-special__link {
  color: red;
}

.box-special--elections .box-special__header-right {
  border-color: #cad5df;
}

.box-special--elections .box-special__header-right a {
  color: #16212d;
  text-decoration: underline;
}

.box-special--elections .box-special__header-right a:hover, .box-special--elections .box-special__header-right a:focus {
  text-decoration: none;
}

.box-special--elections .box-special__header-right a:after {
  border-left-color: red;
}

.box-special--elections .list-article-horizontal-small .title-container h2 a {
  color: #16212d;
  text-decoration: none;
}

.box-special--elections .list-article-horizontal-small .title-container h2 a:hover, .box-special--elections .list-article-horizontal-small .title-container h2 a:focus {
  color: #16212d;
  text-decoration: underline;
}

#gdpr-form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  width: 100%;
  padding: 2px 5px;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: left;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  text-align: center;
  vertical-align: unset;
  min-width: 93px;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}

.title-basic {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}

.title-basic--small-red {
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  color: #fff;
  background: red;
  width: calc(100% - 20px);
  height: 30px;
  margin: 10px;
  padding-left: 10px;
  font-family: tablet_gothic_condensed;
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  overflow: hidden;
}

.title-basic.title-basic--small-red {
  color: #fff;
  font-size: 16px;
}

.article-third-party-articles .title-basic.title-basic--small-red {
  text-transform: uppercase;
  font-size: 15px;
}

.list-article {
  float: left;
  background-color: #fff;
  width: 100%;
  position: relative;
}

.list-article * {
  position: relative;
}

.list-article .list-article__title {
  color: #000;
  display: block;
}

.list-article .list-article__title:hover {
  color: red;
}

.list-article .list-article__title a {
  color: inherit;
}

.list-article .list-article__image {
  display: block;
}

.list-article .list-article__image img {
  float: left;
  display: block;
}

.list-article .list-article__image-ecommerce {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 90px;
  margin-bottom: 10px;
  display: flex;
}

.list-article .list-article__image-ecommerce img.lazyload.loaded {
  object-fit: cover;
  width: 150px;
  height: 90px;
}

.list-article .list-article__placeholder {
  text-align: center;
  background: #bebebe;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
}

.list-article .list-article__placeholder svg {
  height: 24px;
}

.list-article--horizontal-small {
  width: 300px;
  height: 90px;
  margin: 0 10px 10px;
}

.list-article--horizontal-small .list-article__title {
  float: left;
  width: 130px;
  height: 75px;
  margin: 8px 10px 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  overflow: hidden;
}

.list-article--horizontal-small .list-article__image {
  float: right;
  width: 150px;
  height: 90px;
}

.list-article--horizontal-small .list-article__category-name {
  z-index: 3;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  background-color: rgba(152, 163, 175, .8);
  width: 150px;
  height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-article--horizontal-small .list-article__category-name a {
  color: #fff;
}

.list-article--vertical-smaller {
  width: 140px;
  height: 193px;
  margin: 0 10px 20px;
}

.list-article--vertical-smaller .list-article__title {
  height: 91px;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

.list-article--vertical-smaller .list-article__category-name {
  z-index: 3;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  background-color: rgba(152, 163, 175, .8);
  width: 100%;
  height: 20px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  top: 62px;
  left: 0;
}

.list-article--vertical-smaller .list-article__category-name a {
  color: #fff;
}

.list-article--horizontal-middle {
  width: 100%;
}

#article.listed #subscription h2, #article.notlisted #subscription h2 {
  float: none;
  max-width: 100%;
  display: block;
  border-left: none !important;
}

#article.listed #subscription #subscription-offer, #article.notlisted #subscription #subscription-offer {
  margin-left: -40px;
}

.recentarticles-happening-right-now {
  clear: both;
  background-color: #fff;
  margin: 10px;
  padding: 13px 10px 10px;
}

.recentarticles-happening-right-now__title, .recentarticles-happening-right-now__link {
  float: left;
  color: #16212d;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: tablet_gothic_condensed;
  font-size: 15px;
}

.recentarticles-happening-right-now__link {
  float: right;
  color: red;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: tablet_gothic_condensed;
  font-size: 15px;
}

.recentarticles-happening-right-now .list-article--horizontal-small-no-image {
  border-bottom: 1px solid #d0d4d7;
  padding: 10px 0;
}

.recentarticles-happening-right-now .list-article--horizontal-small-no-image:last-of-type {
  border-bottom: none;
}

.recentarticles-happening-right-now .list-article__datetime {
  color: red;
  float: left;
  width: 32px;
  padding-top: 2px;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.recentarticles-happening-right-now .list-article__title {
  color: #16212d;
  float: right;
  width: calc(100% - 44px);
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.recentarticles-happening-right-now .list-article__title:hover {
  color: #083e89;
}

.recentarticles-happening-right-now .list-article__title a {
  color: inherit;
}

#paid-zone a.link--red {
  color: red;
}

.icon-premium {
  z-index: 1;
  box-sizing: border-box;
  color: #403014;
  text-align: center;
  background-color: #e8c157;
  width: 80px;
  height: 20px;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-premium.small {
  width: 76px;
  height: 16px;
}

.icon-premium.small .icon-premium__fallback {
  position: relative;
  top: -2px;
}

.icon-premium__x {
  color: #e50031;
}

.icon-premium__fallback {
  font-family: arial, sans-serif;
  line-height: 1;
}

.icon-premium__link {
  color: #403014;
  width: 100%;
  height: 100%;
}

.dataimg-icon-premium {
  z-index: 1;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.premium-x {
  background: #f8f8f9;
  margin: 0 0 30px;
}

.premium-x .subscription {
  width: 530px;
  margin: 0 auto;
}

.premium-x .subscription__headline {
  width: 95%;
}

.premium-x.recentarticles-premium {
  background: none;
  margin: 0;
}

.premium-x__archive {
  text-align: center;
}

.premium-x__archive-issue {
  width: 300px;
  margin: 0 0 20px;
}

.premium-x__archive-button {
  width: 230px;
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
}

.subscription-faq {
  background: #fff;
  border: 1px solid #d1d4e4;
  margin: 15px 0;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.subscription-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 15px;
  padding: 0 15px 15px 0;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question:hover {
  cursor: pointer;
}

.subscription-faq__question.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after {
  transform: rotate(0);
}

.breadcrumbs-paid-zone {
  margin: -35px 0 20px;
}

#paid-zone {
  clear: both;
  color: #2c2f3b;
  box-sizing: border-box;
  background: #fff;
  width: 590px;
  margin: 0 auto 30px;
  padding: 15px;
  position: relative;
}

#paid-zone table {
  width: 100%;
}

#paid-zone a, #paid-zone .back-to-hp {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone a:hover, #paid-zone .back-to-hp:hover {
  text-decoration: none;
}

#paid-zone ul {
  padding: 0;
}

#paid-zone ul.bullet-list {
  margin: -5px 0 15px;
}

#paid-zone ul.bullet-list li {
  padding-left: 17px;
  line-height: 27px;
  list-style-type: none;
  position: relative;
}

#paid-zone ul.bullet-list li:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

#paid-zone .align-center {
  text-align: center;
}

#paid-zone .error {
  color: red;
}

#paid-zone .success {
  color: #65c41e;
}

#paid-zone .grey-highlight {
  color: #aaa;
}

#paid-zone .table {
  border-top: 1px solid #d1d4e4;
  padding: 20px 0;
}

#paid-zone .table td {
  vertical-align: top;
  border: none;
  padding: 7px 0;
  line-height: 22px;
  position: relative;
}

#paid-zone .table label {
  margin: 0;
}

#paid-zone .user-bonuses + .table {
  border-top: none;
}

#paid-zone .profile-info .table + .table {
  padding-bottom: 4px;
}

#paid-zone .main-info {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
}

#paid-zone .main-info h1 {
  color: red;
  text-align: left;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .main-info h1.align-center {
  text-align: left;
}

#paid-zone .main-info p {
  text-align: left;
  margin: 25px 0 15px;
  font-size: 14px;
  font-weight: bold;
}

#paid-zone .info-text {
  border-bottom: 1px solid #d1d4e4;
  margin-top: -10px;
  margin-bottom: 30px;
  padding-bottom: 25px;
  line-height: 18px;
}

#paid-zone .info-text p {
  font-size: 12px;
}

#paid-zone .prihlaseni-info {
  float: right;
  margin: 40px 0 30px;
}

#paid-zone .logout {
  color: #2c2f3b;
  text-align: right;
  text-decoration: underline;
  position: absolute;
  top: 20px;
  right: 15px;
}

#paid-zone .logout:hover {
  text-decoration: none;
}

#paid-zone .order-form .form-field {
  margin: 0;
}

#paid-zone .backlink {
  margin: 15px 0;
}

#paid-zone .backlink.tos-links {
  margin-bottom: 20px;
}

#paid-zone .backlink.tos-links a:not(.main-button) {
  color: #2c2f3b;
}

#paid-zone .backlink div + .link-basic {
  margin-top: 10px;
}

#paid-zone .backlink h3 {
  font-family: arial, sans-serif;
  font-size: 13px;
}

#paid-zone .backlink h3 a {
  color: #2c2f3b;
  font-weight: normal;
  text-decoration: underline;
}

#paid-zone .backlink h3 a:hover {
  text-decoration: none;
}

#paid-zone .main-button, #paid-zone .button-secondary {
  color: #fff;
  background: red;
  border: none;
  border-radius: 2px;
  min-width: auto;
  height: 34px;
  margin: 0 auto;
  padding: 0 15px;
  font-family: arial, Verdana, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

#paid-zone .main-button:hover, #paid-zone .button-secondary:hover {
  color: #fff;
  text-decoration: underline;
}

#paid-zone .button-secondary {
  color: #2c2f3b;
  background: #d1d4e4;
}

#paid-zone .button-secondary:hover {
  background: #9aa0c4;
  transition: all .3s;
}

#paid-zone .main-button + .button-secondary {
  margin-left: 12px;
}

#paid-zone .subscription-sidenote {
  background: #fcf7db;
  margin: -5px 0 20px;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__type-order {
  color: #fff;
}

#paid-zone .well-content {
  margin: 0;
  padding: 0;
}

#paid-zone .well-content.info-text {
  border-top: 1px solid #d1d4e4;
  margin: 20px 0;
  padding: 20px 0;
}

#paid-zone #activation-activate .align-center.red {
  margin-bottom: 10px;
}

#paid-zone #activation-activate .default-form input.currency-input {
  text-align: center;
  height: 28px;
  line-height: 34px;
}

#paid-zone #activation-activate .default-form .prihlasit.main-button {
  height: 34px;
  line-height: 34px;
}

#paid-zone #activation-activate .well-content.info-text ol {
  margin: 10px 0 0 20px;
  list-style: decimal;
}

#paid-zone #activation-activate .well-content.info-text ol li {
  margin: 10px 0;
  font-size: 12px;
}

#paid-zone .subscription-notice {
  background: #eef4f7;
  width: 64%;
  margin: 25px 0 20px;
  line-height: 18px;
}

#paid-zone .subscription-notice p {
  padding: 10px;
}

#paid-zone .subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

#paid-zone .subscription-faq, #paid-zone .subscription-faq-100 .subscription-faq {
  width: 570px;
}

#paid-zone .back-to-hp {
  margin-top: 15px;
  display: inline-block;
}

#paid-zone .dataimg-icon-premium-master-card {
  width: 26px;
}

#paid-zone .dataimg-icon-premium-visa {
  width: 29px;
}

#paid-zone .dataimg-icon-premium-gopay {
  width: 50px;
}

#paid-zone .dataimg-icon-premium-sms {
  width: 19px;
  position: relative;
  top: -1px;
}

#paid-zone #subscription #subscription-offer {
  width: 100%;
  margin: 0;
  padding: 0;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets {
  margin-top: 0;
  position: relative;
  top: 3px;
}

#paid-zone #subscription #subscription-offer ul.subscription__payment-options-bullets li {
  margin: 0 0 0 10px;
  padding: 0 5px 0 0;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__icon:not(.dataimg-payment-sms) {
  margin: -2px 0 0;
}

#paid-zone #subscription #subscription-offer .subscription__types {
  flex-wrap: wrap;
  display: flex;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type {
  width: 245px;
  padding-bottom: 65px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type p.subscription__type-note br {
  display: none;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-order {
  width: 89%;
  position: absolute;
  bottom: 15px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher {
  background: #f8f8f8;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-title {
  margin-right: 20px;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-order {
  width: 170px;
  margin-right: 50px;
  position: static;
}

#paid-zone .subscription__voucher .subscription-notice {
  display: none;
}

#paid-zone .subscription__voucher .subscription__voucher-title {
  padding: 0 0 20px;
  font-size: 17px;
  line-height: 22px;
  display: block;
}

#paid-zone .subscription__voucher .subscription__voucher-preview {
  width: 100%;
  margin: 0 0 20px;
}

#paid-zone .subscription__voucher .order__success-title {
  color: red;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-title h1 {
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: red;
  width: 260px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription__voucher .order__success-contact {
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 12px;
  line-height: 18px;
}

#subscription-login .tos-links {
  width: 600px;
  margin: 0 auto 20px;
}

#activation-login #login-wrapper {
  margin: 30px 0 35px;
}

#blesk-login #login-wrapper .main-container .login-close {
  top: -45px;
  right: -15px;
}

#blesk-login #login-wrapper #paid-zone {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}

#blesk-login #login-wrapper #paid-zone .tos-links {
  width: 100%;
}

#order-subscription {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

#order-subscription .table .payment input {
  margin: 0 10px 0 0;
}

#order-subscription .table .payment td {
  padding: 8px 0;
}

#order-subscription .table .payment .table-header {
  padding-bottom: 10px;
}

#order-subscription .table .payment-row td {
  padding-bottom: 2px;
}

#order-subscription .subscription__note {
  width: 48%;
}

#order-subscription .payment-total {
  width: 100%;
  margin: 10px 0 25px;
  font-size: 18px;
  line-height: 21px;
}

#order-subscription .agreement {
  border-top: 1px solid #d1d4e4;
  padding-top: 25px;
  line-height: 20px;
}

#order-subscription .agreement a {
  color: #2c2f3b;
  text-decoration: underline;
}

#order-subscription .agreement a:hover {
  text-decoration: none;
}

#order-subscription .agreement .with-error, #order-subscription .agreement .with-error a {
  color: red;
}

#order-subscription .agreement .error-message {
  color: red;
  margin-top: 5px;
  margin-left: 22px;
  font-weight: bold;
  display: block;
}

#order-subscription .logout {
  top: 6px;
  right: 0;
}

#form-subscribe {
  text-align: center;
}

form.default-form .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

form.default-form .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  outline: none;
  width: 180px;
  margin: 10px 5px 10px 0;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

form.default-form .discount-code .discount-code__button {
  color: #0000ca;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 700;
  display: inline-block;
}

form.default-form .discount-code .discount-code__error {
  color: #e00a17;
}

form.default-form .price-note {
  font-size: 13px;
  line-height: 18px;
}

form.default-form .discount-code__price {
  color: #e00a17;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.subscription__voucher .order-form .discount-code {
  display: none;
}

#order-subscription-sms {
  width: 100%;
  margin: 0 auto;
  line-height: 18px;
}

#order-subscription-sms h1 {
  margin-bottom: 25px;
}

#order-subscription-sms ul.bullet-list {
  margin-top: 8px;
}

#order-subscription-sms #steps {
  border-bottom: 1px solid #d1d4e4;
  margin: 12px 0 25px;
  padding: 0 0 25px;
}

#order-subscription-sms #steps .for-1:before {
  content: "1.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-2:before {
  content: "2.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps .for-3:before {
  content: "3.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#order-subscription-sms #steps li {
  margin: 25px 0;
  padding: 0 0 0 18px;
  list-style-type: none;
  position: relative;
}

#order-subscription-sms #steps li:first-child {
  margin-top: 0;
  padding-right: 10px;
}

#order-subscription-sms #steps li:last-child {
  margin-bottom: 0;
}

#order-subscription-sms #steps li form {
  margin-top: 10px;
  position: relative;
}

#order-subscription-sms #steps li form div {
  position: absolute;
}

#order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 13px;
  font-weight: 700;
  line-height: 0;
  display: block;
  position: absolute;
  top: 24px;
  left: 10px;
}

#order-subscription-sms #steps li .error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  position: relative;
}

#order-subscription-sms #steps li .sms_input {
  float: left;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 92px;
  height: 34px;
  margin: 5px 10px 0 0;
  padding: 0 0 0 44px;
  font-size: 13px;
}

#order-subscription-sms #steps li .sms_input.error {
  border-color: red;
}

#order-subscription-sms #steps li .main-button {
  height: 36px;
  margin: 5px 0 0;
  line-height: 36px;
}

#order-subscription-sms #additional-info h2 {
  margin-bottom: 18px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

#order-subscription-sms #additional-info .subscription-sidenote {
  margin: 30px 0;
}

#activation-login.not-subscribed .main-info p {
  float: left;
  width: 75%;
  margin: 18px 0 30px;
  line-height: 21px;
}

#activation-login.not-subscribed .prihlaseni-info {
  margin: 21px 0 30px;
}

#activation-login.not-subscribed .subscription {
  clear: both;
}

#activation-login .recurring-date #recurring-info {
  color: red;
  cursor: pointer;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
}

#activation-login .recurring-date #recurring-info #recurring-info-popup {
  text-align: left;
  color: #2c2f3b;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  width: 160px;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

#paid-zone .tos-links {
  width: 570px;
  margin-top: 25px;
}

#paid-zone #login-wrapper .main-container {
  box-shadow: none;
  text-align: center;
  width: 570px;
  margin: 0;
  padding: 25px;
  display: block;
}

#paid-zone #login-wrapper .main-container a {
  color: #fff;
  background: #ed1c24;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container a:hover {
  background: #a60c12;
  text-decoration: none;
}

#paid-zone #login-wrapper .main-container .login-tabs {
  width: 282px;
  left: -1px;
}

#paid-zone #login-wrapper .main-container .login-tabs a.active {
  border: 1px solid #ccc;
  border-bottom: 0;
}

#paid-zone #login-wrapper .social-networks .button-facebook {
  width: 245px;
}

#paid-zone #login-wrapper .form-row .info {
  width: 230px;
}

.cnc-modal {
  z-index: 99999;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.cnc-modal-body {
  height: 690px;
}

.cnc-modal-open {
  overflow: hidden;
}

.cnc-modal-content {
  width: auto;
  max-width: 640px;
  margin: 30px auto;
  padding: 0;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
}

.cnc-modal-close {
  position: absolute;
  top: 20px;
  right: 45px;
}

.cnc-modal-close .dataimg-cross {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg {
  height: 20px;
}

.cnc-modal-close .dataimg-cross svg path {
  fill: #16212d;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@media (max-width: 340px) {
  .cnc-modal-close {
    right: 28px;
  }
}

@media (max-width: 430px) {
  .cnc-modal-close {
    right: 33px;
  }
}

.activation-steps {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: flex;
}

.activation-step {
  color: #2e2e2e;
  background: #ececec;
  width: calc(50% - 55px);
  padding: 9px 20px 8px;
  font-weight: 700;
  position: relative;
}

.activation-step:last-of-type {
  text-indent: 25px;
  max-width: calc(50% - 30px);
  left: -30px;
}

.activation-step:before {
  content: "";
  z-index: 1;
  border: 35px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #ececec;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.activation-step.active-step {
  color: red;
  background: #fafafa;
}

.activation-step.active-step:before {
  border-left: 30px solid #fafafa;
}

.activation-steps__step {
  font-size: 15px;
  line-height: 25px;
}

.activation-steps__info {
  font-size: 17px;
  line-height: 28px;
}

.trhak-premium {
  display: flex;
}

.trhak-premium__image {
  margin-right: 15px;
}

.trhak-activate .currency-input {
  width: 60%;
}

.trhak-activate .prihlasit.main-button {
  margin-top: 15px;
}

.trhak-activate .error-message {
  color: red;
  margin-top: 15px;
  display: block;
}

.trhak-activate #code2 {
  width: 35%;
}

.trhak-activate__image {
  margin-right: 25px;
}

.trhak.info-text {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.trhak-headline {
  margin: -15px 0 15px;
  display: block;
}

@media screen and (max-width: 640px) {
  .trhak-premium {
    display: block;
  }

  .trhak-premium__image {
    float: left;
    width: 75px;
    margin-top: 10px;
  }

  .trhak-activate__image {
    width: 70px;
    margin-right: 10px;
  }

  .trhak-headline {
    margin-top: -30px;
    line-height: 18px;
  }

  #paid-zone #activation-activate .trhak-premium.info-text ol {
    padding: 0;
  }

  #paid-zone #activation-activate .trhak-activate #form-activate .currency-input {
    width: calc(100% - 12px);
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
  }
}

#paid-zone {
  font-size: 13px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:after {
  top: 3.75px;
  left: 3.2px;
}

#paid-zone form.default-form .form-field input[type="radio"] + label:before {
  border-width: 1px;
  border-color: #aaa;
  top: 1px;
}

#paid-zone form.default-form .form-field input[type="checkbox"] + label:before {
  border-width: 1px;
  border-color: #aaa;
}

#paid-zone .red {
  color: red;
}

#paid-zone .table {
  clear: both;
}

#paid-zone .main-button {
  line-height: 36px;
}

#paid-zone .subscription {
  box-sizing: border-box;
  width: 690px;
  margin: 0 auto 20px -15px;
  padding: 30px 0;
}

#paid-zone .subscription a {
  color: red;
}

#paid-zone .subscription .subscription__type-order {
  color: #fff;
  text-decoration: none;
}

#paid-zone .subscription .subscription__type-order--white {
  color: red;
  background-color: #fff;
}

#paid-zone .subscription .subscription__type-order--white:hover {
  color: #fff;
  background-color: red;
}

#paid-zone .tos-links {
  border-top: 1px solid #d1d4e4;
  margin: 30px 0 20px;
  padding: 30px 0 1px;
}

#paid-zone .tos-links .title {
  text-align: left;
  margin-bottom: 0;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

#paid-zone .tos-links .title a {
  color: #2c2f3b;
  text-decoration: underline;
}

#paid-zone .tos-links .title a:hover {
  text-decoration: none;
}

#paid-zone .tos-links .link-basic {
  padding-left: 17px;
  position: relative;
}

#paid-zone .tos-links .link-basic:before {
  content: " ";
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.recentartlices-premium-celebrity {
  z-index: 10;
  width: 640px;
  margin-top: 40px;
  margin-left: -40px;
  padding: 0 40px 25px;
  position: relative;
}

.recentartlices-premium-celebrity .title-basic {
  margin-top: 0;
}

.recentartlices-premium-celebrity a {
  text-decoration: none !important;
}

.recentartlices-premium-celebrity .artPaid {
  z-index: 1;
  width: 97px;
  height: 21px;
  position: absolute;
  left: 0;
}

.recentartlices-premium-celebrity .recent-articles__next-articles {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.recentartlices-premium-celebrity .next-articles {
  text-align: center;
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 214px;
  height: 30px;
  padding: 12px 26px 10px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 40px;
  display: inline;
  text-decoration: none !important;
}

#recentarticles-premium-pane .recentartlices-premium-celebrity {
  margin-left: -50px;
}

.subscription-faq {
  background: #fff;
  width: 570px;
  margin: 20px auto;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 30px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.subscription-faq__questions {
  padding: 0;
}

.subscription-faq__answer {
  margin-top: 0;
  display: none;
}

.subscription-faq__answer:hover {
  cursor: default;
}

.subscription-faq__answer p {
  margin: 15px 0 0;
}

.subscription-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.subscription-faq__question, .subscription-types-switcher {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  font-size: 13px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after, .subscription-types-switcher:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question.active .subscription-faq__answer, .subscription-types-switcher.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after, .subscription-types-switcher.active:after {
  transform: rotate(0);
}

.subscription-faq__question strong, .subscription-types-switcher strong {
  display: block;
}

.subscription-faq__question strong:hover, .subscription-types-switcher strong:hover {
  cursor: pointer;
}

.subscription-types-switcher {
  border-bottom: 0;
  font-size: 13px;
}

.subscription-types-switcher:after {
  filter: invert(15%) sepia(82%) saturate(5998%) hue-rotate(358deg) brightness(105%) contrast(115%);
  top: 5px;
}

.subscription-types-switcher__holder {
  text-align: center;
  margin-top: 5px;
}

.subscription-types-switcher__holder ~ div, .subscription-types-switcher__holder ~ ul {
  display: none;
}

.subscription-types-switcher__holder.active ~ div, .subscription-types-switcher__holder.active ~ ul {
  display: block;
}

.subscription-types-switcher__holder strong:before {
  content: "Zobrazit";
}

.subscription-types-switcher__holder.active strong:before {
  content: "Skrýt";
}

.subscription-types-switcher__holder.active .subscription-types-switcher:after {
  transform: rotate(0);
}

@media only screen and (max-width: 480px) {
  .subscription-faq__contact a {
    text-decoration: underline;
  }

  .subscription-faq__contact a:hover, .subscription-faq__contact a:active {
    text-decoration: none;
  }
}

#blesk-premium-corporate {
  width: 620px;
  margin: 0 auto 30px;
  padding: 30px 0;
  font-family: arial, sans-serif;
}

#blesk-premium-corporate .header {
  text-align: center;
}

#blesk-premium-corporate .header .svg-title svg {
  width: auto;
  height: 45px;
}

#blesk-premium-corporate .header .headline, #blesk-premium-corporate .header .subtitle {
  text-align: center;
  float: left;
  width: 100%;
}

#blesk-premium-corporate .header .headline {
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

#blesk-premium-corporate .header .subtitle {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription {
  border-top: 1px solid #cad5df;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
}

#blesk-premium-corporate .body .ttFormCorporateSubscription .subscription-success-message {
  text-align: center;
  z-index: 1000;
  color: red;
  background: #fff;
  border: 1px solid red;
  border-radius: 5px;
  width: 620px;
  max-width: 90%;
  padding: 15px 0;
  font-size: 17px;
  display: block;
  position: fixed;
  top: 50%;
  box-shadow: 0 7px 24px rgba(0, 0, 0, .5);
}

#blesk-premium-corporate .body .form-field label, #blesk-premium-corporate .body .form-field .error-message {
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

#blesk-premium-corporate .body .form-field .error-message {
  color: red;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  display: none;
}

#blesk-premium-corporate .body .form-field.with-error .error-message {
  display: block;
}

#blesk-premium-corporate .body .subscription-type {
  float: left;
  cursor: pointer;
  background-color: #fff;
  width: 190px;
  height: 110px;
  margin-right: 25px;
  display: block;
}

#blesk-premium-corporate .body .subscription-type.selected {
  border: 1px solid red;
  width: 188px;
  height: 108px;
}

#blesk-premium-corporate .body .subscription-type:last-child {
  margin-right: 0;
}

#blesk-premium-corporate .body .subscription-type .main {
  margin: 10px;
  position: relative;
}

#blesk-premium-corporate .body .subscription-type .main input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

#blesk-premium-corporate .body .subscription-type .main label {
  float: right;
  width: 80%;
  max-height: 17px;
  margin: 7px 0 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price {
  color: red;
  float: right;
  width: 80%;
  margin-top: 15px;
  font-size: 30px;
  line-height: 30px;
}

#blesk-premium-corporate .body .subscription-type .main .price .price__currency {
  font-size: 20px;
}

#blesk-premium-corporate .body .subscription-type .main .dph {
  float: right;
  width: 80%;
  font-size: 13px;
  line-height: 20px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 11px;
  left: 7px;
}

#blesk-premium-corporate .body .subscription-type .customRadio .checkmark:after {
  content: "";
  background: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  top: 2px;
  left: 2px;
}

#blesk-premium-corporate .body .subscription-type .customRadio input:checked ~ .checkmark:after {
  display: block;
}

#blesk-premium-corporate .body input {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 12px 10px 11px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body input::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .email label {
  margin-top: 15px;
}

#blesk-premium-corporate .body .email input {
  width: 292px;
  margin-top: 10px;
}

#blesk-premium-corporate .body .message {
  margin: 25px 0;
}

#blesk-premium-corporate .body .message #message {
  color: #000;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 598px;
  height: 117px;
  min-height: 117px;
  margin-top: 10px;
  padding: 13px 11px 12px;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

#blesk-premium-corporate .body .message #message::placeholder {
  color: #aaa;
  opacity: 1;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
}

#blesk-premium-corporate .body .captcha label {
  margin-bottom: 6px;
}

#blesk-premium-corporate .body .captcha input {
  width: 122px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row .submit-mob-clear {
  clear: initial;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row div.captcha-img {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  max-height: 40px;
  margin-right: 15px;
  padding: 4px;
}

#blesk-premium-corporate .body .captcha .captcha-sub-row input.submit {
  float: right;
  color: #fff;
  background-color: red;
  border: 0;
  border-radius: 2px;
  width: 190px;
  max-height: 40px;
  padding: 12px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

#blesk-premium-corporate .body .corporate-order {
  text-align: center;
  border-bottom: 1px solid #cad5df;
  padding-top: 15px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 20px;
}

.paid-zone__corporate-activation #paid-zone {
  box-sizing: border-box;
  width: 600px;
}

.paid-zone__corporate-activation #paid-zone .content {
  background-color: #fff;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper .main-info p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.paid-zone__corporate-activation #paid-zone .content .sub-wrapper #subscription-login {
  margin-bottom: -15px;
  margin-left: -15px;
}

.paid-zone__corporate-activation #paid-zone a.main-button {
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 38px;
}

.paid-zone__corporate-activation #paid-zone p {
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone header.main-info {
  text-align: center;
  background-color: #fff;
  padding-bottom: 0;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1 {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-family: arial, sans-serif;
  font-size: 25px;
  line-height: 32px;
}

.paid-zone__corporate-activation #paid-zone header.main-info h1.align-center {
  text-align: center;
}

.paid-zone__corporate-activation #paid-zone .info-text {
  background: #fff;
  border: 0;
  margin: 15px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.paid-zone__corporate-activation #paid-zone .info-text p {
  margin: 0 0 15px;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone .info-text ol {
  margin: 15px 0;
  padding: 0 0 0 15px;
  list-style: decimal;
}

.paid-zone__corporate-activation #paid-zone .info-text ol li {
  margin: 0 0 20px;
  line-height: 1.6;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper {
  background: #e9eff4;
  width: 600px;
  padding: 30px 0 0;
}

.paid-zone__corporate-activation #paid-zone #login-wrapper .main-container {
  margin-bottom: -5px;
}

.paid-zone__corporate-activation #paid-zone #form-activate {
  margin-top: 18px;
  padding: 0 0 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field {
  width: 312px;
  margin: 0 auto 20px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="text"] {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid red;
  width: 200px;
  height: 42px;
  padding: 0 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 42px;
  display: inline-block;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field input[type="submit"] {
  color: #fff;
  float: right;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 113px;
  height: 42px;
  max-height: 40px;
  margin: 0 0 5px -5px;
  padding: 0 0 2px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}

.paid-zone__corporate-activation #paid-zone #form-activate .form-field .error-message {
  float: left;
}

.paid-zone__corporate-activation .paid-zone__faq {
  box-sizing: border-box;
  width: 600px;
  margin: 40px auto;
}

.subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription--article * {
  box-sizing: border-box;
}

.subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

.subscription--article .subscription__logo svg, .subscription--article .subscription__logo img {
  max-height: 16px;
}

.subscription--article .subscription__logo svg:first-child, .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

.subscription--article .subscription__logo svg:last-child, .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

.subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.subscription--article .subscription__button-wrapper {
  color: #fff;
}

.subscription--article .subscription__button-wrapper span, .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

.subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

.subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

.subscription--article .subscription__type-order:hover {
  color: #fff;
}

.subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

.subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article .article-body .subscription--article, #article.listed .subscription--article {
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  background-image: linear-gradient(111deg, #1f1e26, #27272e);
  width: 540px;
  padding: 48px 50px;
  position: relative;
}

@media (max-width: 750px) {
  #article .article-body .subscription--article, #article.listed .subscription--article {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#article .article-body .subscription--article.fade-out:before, #article.listed .subscription--article.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(233, 239, 244, 0) 0 2%, #e9eff4 70%, #e9eff4);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

#article .article-body .subscription--article *, #article.listed .subscription--article * {
  box-sizing: border-box;
}

#article .article-body .subscription--article .subscription__logo, #article.listed .subscription--article .subscription__logo {
  width: 100%;
  height: auto;
  display: flex;
}

#article .article-body .subscription--article .subscription__logo svg, #article .article-body .subscription--article .subscription__logo img, #article.listed .subscription--article .subscription__logo svg, #article.listed .subscription--article .subscription__logo img {
  max-height: 16px;
}

#article .article-body .subscription--article .subscription__logo svg:first-child, #article .article-body .subscription--article .subscription__logo img:first-child, #article.listed .subscription--article .subscription__logo svg:first-child, #article.listed .subscription--article .subscription__logo img:first-child {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

#article .article-body .subscription--article .subscription__logo svg:last-child, #article .article-body .subscription--article .subscription__logo img:last-child, #article.listed .subscription--article .subscription__logo svg:last-child, #article.listed .subscription--article .subscription__logo img:last-child {
  opacity: .6;
}

#article .article-body .subscription--article .subscription__headline, #article.listed .subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 26px;
  margin-bottom: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

#article .article-body .subscription--article .subscription__description, #article.listed .subscription--article .subscription__description {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#article .article-body .subscription--article .subscription__button-wrapper, #article.listed .subscription--article .subscription__button-wrapper {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button-wrapper span, #article .article-body .subscription--article .subscription__button-wrapper .subscription__button--link, #article.listed .subscription--article .subscription__button-wrapper span, #article.listed .subscription--article .subscription__button-wrapper .subscription__button--link {
  font-size: 12px;
}

#article .article-body .subscription--article .subscription__button-wrapper .subscription__type-order, #article.listed .subscription--article .subscription__button-wrapper .subscription__type-order {
  margin-right: 15px;
  display: inline-block;
}

#article .article-body .subscription--article .subscription__type-order, #article.listed .subscription--article .subscription__type-order {
  text-align: center;
  color: #fff;
  background-color: #f66533;
  border: none;
  border-radius: 0;
  width: 190px;
  height: 45px;
  margin: 20px 0 0;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
  display: block;
  position: static;
}

#article .article-body .subscription--article .subscription__type-order:hover, #article.listed .subscription--article .subscription__type-order:hover {
  color: #fff;
}

#article .article-body .subscription--article .subscription__button--link, #article.listed .subscription--article .subscription__button--link {
  color: #fff;
  text-decoration: underline;
}

#article .article-body .subscription--article .subscription__button--link:hover, #article.listed .subscription--article .subscription__button--link:hover {
  color: #fff;
  text-decoration: none;
}

#article.listed .subscription--article {
  width: 100%;
}

#blesk-login .form-row input[type="file"].text {
  height: auto;
  padding: 10px 0;
  font-size: 14px;
}

#blesk-login .form-row input.text, #blesk-login .form-row label.form-input-group {
  font-size: 14px;
}

#blesk-login #login-wrapper #paid-zone {
  position: static;
}

#blesk-login #login-wrapper .login-tabs a:not(.active) {
  color: #fff;
}

#blesk-login #login-wrapper .login-button {
  background: red;
}

#login-wrapper h1, #login-wrapper h2, #login-wrapper a, #login-wrapper .form-row .required:after {
  color: red;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.article-container.bigArticle .wrapper .articlePhoto a .loading-placeholder, .article-container.bigArticle .wrapper .articlePhoto a.autofun .loading-placeholder {
  height: 240px;
}

.article-container.middleArticle .wrapper a.articlePhoto .loading-placeholder, .article-container.smallArticle .wrapper a.articlePhoto .loading-placeholder {
  height: 180px;
}

.middle.bigArticle .wrapper .articlePhoto a .loading-placeholder, .middle.bigArticle .wrapper .articlePhoto a.autofun .loading-placeholder {
  height: 240px;
}

.middle.middleArticle .wrapper a.articlePhoto .loading-placeholder, .middle.smallArticle .wrapper a.articlePhoto .loading-placeholder {
  height: 180px;
}

.list-article-small .list-article-small-thumbnail .loading-placeholder {
  height: 90px;
}

.lazyload.loaded {
  opacity: 1;
}

.autoCZ.loading-placeholder-small .loading-placeholder {
  height: 85px;
}

.item-loaded-error {
  padding-bottom: 0 !important;
}

.e-edition {
  color: #16202d;
  font-size: 17px;
  line-height: 1.5;
}

.e-edition__main-image-wrapper {
  width: 100%;
  margin-bottom: 23px;
  display: block;
}

.e-edition__main-image {
  width: 100%;
}

.e-edition__form-wrapper {
  margin: 20px 0 30px;
}

.e-edition__social-wrapper:before, .e-edition__social-wrapper:after {
  content: "";
  display: table;
}

.e-edition__social-wrapper:after {
  clear: both;
}

.e-edition-form {
  background: #fff;
  padding: 30px;
}

.e-edition-form__inputs-group {
  width: 260px;
  margin-bottom: 20px;
}

.e-edition-form__text-label {
  margin-bottom: 20px;
  display: block;
}

.e-edition-form__text-label:last-child {
  margin-bottom: 0;
}

.e-edition-form__text-input {
  box-sizing: border-box;
  color: #6e7480;
  background: #e8ebf1;
  border: none;
  width: 100%;
  padding: 13px 17px;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.e-edition-form__input--invalid {
  border: 1px solid red;
}

.e-edition-form__input--invalid + .e-edition-form__conditions-text {
  color: red;
}

.e-edition-form__error-message--empty-value, .e-edition-form__error-message--invalid-format, .e-edition-form__error-message--invalid-number {
  display: none;
}

.e-edition-form__input--empty-value ~ .e-edition-form__error-message--empty-value, .e-edition-form__input--invalid-format ~ .e-edition-form__error-message--invalid-format, .e-edition-form__input--invalid-number ~ .e-edition-form__error-message--invalid-number {
  display: block;
}

input::-webkit-outer-spin-button {
  margin: 0;
}

input::-webkit-inner-spin-button {
  margin: 0;
}

.e-edition-form__error-message {
  color: red;
  padding: 5px 5px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  display: none;
}

.e-edition-form__conditions {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.e-edition-form__checkbox-label {
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 20px;
  display: block;
  position: relative;
}

.e-edition-form__checkbox-label:last-child {
  margin-bottom: 0;
}

.e-edition-form__checkbox {
  z-index: 1;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.e-edition-form__conditions a {
  color: red;
  text-decoration: none !important;
}

.e-edition-form__conditions a:hover {
  text-decoration: underline !important;
}

.e-edition-form__button {
  color: #fff;
  cursor: pointer;
  background-color: red;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 21px;
  display: block;
}

.e-edition__sent-form-info {
  margin: 30px 0;
}

.e-edition__sent-form-info--errors {
  border-bottom: 1px solid #e8ebf1;
  margin-bottom: 0;
}

.e-edition__errors-list:last-child {
  margin-bottom: 0 !important;
}

.sent-form-info {
  background-color: #fff;
  padding: 30px;
}

.sent-form-info p {
  margin-bottom: 20px;
}

.sent-form-info p:last-of-type {
  margin-bottom: 0;
}

.sent-form-info__title {
  margin: 0 0 15px;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}

.e-edition-social {
  color: #98a3af;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 17px;
  font-size: 16px;
  line-height: 1.1;
  display: flex;
}

.e-edition-social__list {
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.e-edition-social svg {
  width: 24px;
  height: 24px;
}

.e-edition-faq {
  background: #fff;
  padding: 15px 15px 30px;
}

.e-edition-faq a {
  text-decoration: underline;
}

.e-edition-faq a:hover {
  text-decoration: none;
}

.e-edition-faq__headline {
  margin: 10px 0 20px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.e-edition-faq__questions {
  padding: 0;
}

.e-edition-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.e-edition-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  list-style: none;
  position: relative;
}

.e-edition-faq__question:hover {
  cursor: pointer;
}

.e-edition-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.e-edition-faq__question.active:after {
  transform: rotate(0);
}

.e-edition-faq__question.active .e-edition-faq__answer {
  display: block;
}

.e-edition-faq__answer {
  margin-top: 0;
  display: none;
}

.e-edition-faq__answer p {
  margin: 15px 0 0;
}

.e-edition-wrapper .inner_7 {
  float: none;
  margin-left: 80px;
}

.e-edition__title {
  margin: 0 0 6px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.1;
}

.e-edition__leadsection {
  margin: 0 0 23px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.e-edition__faq {
  box-sizing: border-box;
  border: none;
  width: calc(100% + 90px) !important;
  margin-top: 65px !important;
  margin-left: -90px !important;
}

.e-edition-faq__headline {
  font-family: arial, sans-serif !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.e-edition-faq__question {
  font-size: 14px;
  line-height: 18px;
}

.user-bonuses {
  line-height: 1.2;
}

.user-bonuses *, .user-bonuses :before, .user-bonuses :after {
  box-sizing: border-box;
}

.user-bonuses__group {
  margin-bottom: 30px;
}

.user-bonuses__group:last-child {
  margin-bottom: 0;
}

.user-bonuses__main-title {
  margin: 0 0 15px;
  font-weight: 700;
}

.user-bonuses__type-title {
  margin-bottom: 5px !important;
}

.user-bonuses__list {
  border: 1px solid #dcdcdc;
  margin: 0;
  list-style: none;
  padding: 15px 30px 15px 15px !important;
}

.user-bonuses__item {
  margin-bottom: 35px;
}

.user-bonuses__item:last-child {
  margin-bottom: 0;
}

.user-bonus {
  align-items: flex-start;
  display: flex;
}

.user-bonus__image-wrapper {
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 65px;
  display: flex;
  overflow: hidden;
}

.user-bonus__image {
  object-fit: contain;
  flex-basis: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.user-bonus__body {
  flex-basis: calc(100% - 75px);
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
}

.user-bonus__info {
  width: 100%;
  padding: 0 30px 0 0;
  line-height: 1.3;
}

.user-bonus__name {
  font-weight: 700;
}

.user-bonus__link {
  color: red;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.user-bonus__link:hover {
  text-decoration: none;
}

.user-bonus__code-wrapper {
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px 35px;
  position: relative;
}

.user-bonus__code-wrapper:hover, .user-bonus__code-wrapper:focus {
  border-color: #c00;
}

.user-bonus__code-wrapper--has-code:before {
  content: "Kód byl zkopírován";
  z-index: 1;
  color: #fff;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  background: #65c61e;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.user-bonus__code-wrapper--copied {
  border-color: #65c61e;
  transition: border-color .5s ease-in-out;
}

.user-bonus__code-wrapper--copied:before {
  opacity: 1;
}

.user-bonus__codeurl-wrapper {
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  margin: auto 0 auto auto;
  position: relative;
}

.user-bonus__codeurl-wrapper .user-bonus__codeurl {
  text-align: center;
  border: 1px solid red;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px;
  display: block;
  overflow: hidden;
}

.user-bonus__get-code-button {
  z-index: 1;
  text-align: left;
  color: #fff;
  border-radius: inherit;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .8);
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-bonus__get-code-button:hover:before, .user-bonus__get-code-button:focus:before {
  background-color: #c00;
  width: 80px;
}

.user-bonus__get-code-button:hover:after, .user-bonus__get-code-button:focus:after {
  border-bottom-color: #c00;
  border-right-width: 35px;
  left: 80px;
}

.user-bonus__get-code-button:before, .user-bonus__get-code-button:after {
  content: "";
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
}

.user-bonus__get-code-button:before {
  background-color: red;
  width: 86px;
  height: 100%;
  left: 0;
}

.user-bonus__get-code-button:after {
  border-top: none;
  border-bottom: 28px solid red;
  border-left: none;
  border-right: 30px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  left: 86px;
}

.user-bonus__code-wrapper--has-code .user-bonus__get-code-button {
  display: none;
}

.user-bonus__get-code-button-text {
  z-index: 2;
  position: relative;
}

.user-bonus__cut-code-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.user-bonus__code {
  text-align: center;
  pointer-events: none;
  border: none;
  max-width: 100%;
  padding: 0;
}

.user-bonus__code-wrapper--has-code .user-bonus__code {
  pointer-events: auto;
}

.user-bonus__address {
  text-align: right;
  margin-left: auto;
  line-height: 20px;
}

.user-bonus__address-title {
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 3px !important;
}

.user-bonus__code-valid-to-wrapper {
  z-index: 1;
  text-align: center;
  color: #b0b0b0;
  width: 100%;
  padding: 4px 0 0;
  font-size: 10px;
  line-height: 1.1;
  position: absolute;
  top: 100%;
  left: 0;
}

.user-bonus__no-code-error {
  text-align: center;
  color: red;
  align-self: center;
  font-weight: 700;
}

.user-bonus__no-code-error--hidden {
  display: none;
}

.user-bonus-popup-overlay {
  z-index: 1252;
  background-color: rgba(0, 0, 0, .2);
  align-items: flex-start;
  max-height: 100vh;
  padding: 50px 0;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.user-bonus-popup-overlay--hidden {
  display: none;
}

.user-bonus-popup {
  z-index: 1253;
  background-color: #fff;
  border-radius: 3px;
  width: 470px;
  margin: auto;
  padding: 30px 30px 30px 40px;
  font-size: 13px;
  line-height: 1.3;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.user-bonus-popup__close-button {
  cursor: pointer;
  background: #fff url("https://img2.cncenter.cz/images/default/user-bonus/close-btn.svg") center / 16px no-repeat;
  border: none;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 0;
  display: block;
  position: absolute;
  top: -7px;
  right: -8px;
}

.user-bonus-popup__header {
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.user-bonus-popup__image-wrapper {
  width: 160px;
  height: 80px;
}

.user-bonus-popup__info {
  flex-basis: calc(100% - 160px);
  padding-left: 30px;
}

.user-bonus-popup__code-section {
  text-align: center;
  margin-bottom: 30px;
}

.user-bonus-popup__code-section--hidden {
  display: none;
}

.user-bonus-popup__code-wrapper {
  width: 220px;
  max-width: none;
  margin: 4px auto;
}

.user-bonus-popup__cut-code-button {
  background-size: contain;
  left: 13px;
}

.user-bonus-popup__code {
  text-align: center;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.user-bonus-popup__code-valid-to-wrapper {
  text-align: center;
  display: block;
  position: static;
}

.user-bonus-popup__no-code-error {
  margin-bottom: 30px !important;
}

.user-bonus-popup__requirements {
  overflow: auto;
}

.user-bonus-popup__requirements p {
  margin-bottom: 1em !important;
}

.user-bonuses {
  border-top: 1px solid #d1d4e4;
  padding: 20px 0 10px;
}

.user-bonuses__list {
  padding-bottom: 20px !important;
}

.user-bonus__code-wrapper {
  height: 30px;
}

.user-bonus__get-code-button:after {
  border-top-width: 28px;
  border-bottom-width: 28px;
}

.user-bonus__code {
  height: 15px;
  font-size: 13px;
  line-height: 15px;
}

.promo-magazine {
  box-sizing: border-box;
  text-align: center;
  background-color: red;
  width: 300px;
  min-height: 330px;
  margin: 10px auto 0;
  padding: 15px;
}

.promo-magazine__cover-link {
  width: 208px;
  display: inline-block;
}

.promo-magazine__cover-image {
  border: 7px solid #fff;
  width: 194px;
  margin: auto;
  display: inline-block;
  box-shadow: 0 0 10px rgba(50, 50, 50, .5);
}

.promo-magazine__link {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 15px 0 0;
  padding-right: 10px;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.promo-magazine__link:after {
  content: "";
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid #fff;
  margin: 0 0 1px 10px;
  display: block;
  position: absolute;
  top: 3px;
  right: 0;
}

.promo-magazine--bydleni {
  background: #ff7300 url("/images/blesk/bydleni/pozadi.jpg?v=92") no-repeat;
}

.promo-magazine--hobby {
  background-color: #00b373;
}

.specials-menu {
  color: #1e1e1e;
  align-items: center;
  margin: 15px 0 5px;
  padding: 0 10px;
  display: flex;
}

.specials-menu__secondary-logo {
  margin-right: auto;
}

.specials-menu__list {
  display: flex;
}

.specials-menu__item {
  margin-right: 10px;
}

.specials-menu__item:last-child {
  margin-right: 0;
}

.specials-menu__link {
  color: inherit;
  background-color: #fff;
  border: 1px solid #cad5df;
  padding: 9px 15px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: block;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot");
  src: local(Roboto Bold), local(Roboto-Bold), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#Roboto") format("svg");
}

.subcategory-menu {
  color: #1e1e1e;
  border-bottom: 1px solid #cdcdcd;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 0 30px;
  padding: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
}

.subcategory-menu__logo-link {
  text-transform: uppercase;
  color: inherit;
  font-family: tablet_gothic_condensed, Times New Roman, serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 67px;
  text-decoration: none;
  display: flex;
}

.subcategory-menu__logo-image {
  margin-bottom: 10px;
}

.subcategory-menu__list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.subcategory-menu__item {
  padding: 15px 14px 14px;
  position: relative;
}

.subcategory-menu__item:last-child {
  margin-right: 0;
}

.subcategory-menu__item--hidden {
  display: none;
}

.subcategory-menu__item--with-submenu {
  cursor: pointer;
  padding-right: 23px;
  position: relative;
}

.subcategory-menu__item--with-submenu:hover {
  background-color: #eee;
}

.subcategory-menu__item--with-submenu:hover:after {
  transition: transform .3s linear;
  transform: translateY(-50%)scaleY(-1);
}

.subcategory-menu__item--with-submenu:after {
  content: "";
  background-image: url("https://img2.cncenter.cz/images/blesk-zpravy/dist/svg/arrow-down-dark-grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform .3s linear;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%)scaleY(1);
}

.subcategory-menu__link {
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  display: block;
}

.subcategory-menu__link:hover {
  text-decoration: underline;
}

.subcategory-menu__submenu {
  z-index: 21;
  background-color: #eee;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.subcategory-menu__submenu[data-side="left"] {
  left: 0;
  right: auto;
}

.subcategory-menu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  display: block;
}

.subcategory-menu__submenu-item--hidden {
  display: none;
}

.subcategory-menu__submenu-link {
  border-bottom: 1px solid #dcdcdc;
  padding: 8px 5px 6px;
}

.subcategory-menu__submenu-item:last-child .subcategory-menu__submenu-link {
  border-bottom: none;
}

.subcategory-menu {
  margin-top: 30px;
  margin-bottom: 20px;
}

.subcategory-menu--resize-to-grid {
  width: initial;
  margin-left: 10px;
  margin-right: 10px;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  overflow: hidden !important;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

.blesk-logo {
  background-color: #ed1c24;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-logo__image {
  display: block;
}

@keyframes show-navigation {
  from {
    opacity: 0;
    transform: translateY(-300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide-navigation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blesk-full-nav {
  z-index: 10002;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, .95);
  width: 100%;
  height: 100%;
  padding: 80px 0 30px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media (max-width: 1099px) {
  .blesk-full-nav {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav {
    padding-top: 20px;
  }
}

.blesk-full-nav, .blesk-full-nav *, .blesk-full-nav:before, .blesk-full-nav:after, .blesk-full-nav :before, .blesk-full-nav :after {
  box-sizing: border-box;
}

.blesk-full-nav--show {
  opacity: 1;
  animation: .2s ease-in-out show-navigation;
  display: block;
}

.blesk-full-nav--hide {
  opacity: 0;
  animation: .2s ease-in-out hide-navigation;
}

.blesk-full-nav a {
  color: inherit;
}

.blesk-full-nav a:focus {
  outline: none;
}

.blesk-full-nav__container {
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__container {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__container {
    width: 100%;
    max-width: 480px;
  }
}

.blesk-full-nav__input {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0 15px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.blesk-full-nav__input:focus {
  outline: none;
}

.blesk-full-nav__input--search {
  width: 550px;
  height: 48px;
  padding-right: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__input--search {
    width: 100%;
  }
}

.blesk-full-nav__link-with-icon {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.blesk-full-nav__link-with-icon:hover {
  text-decoration: underline;
}

.blesk-full-nav__link-with-icon:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__link-with-icon {
    font-size: 15px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__link-with-icon img {
    margin-left: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon img {
    margin-right: 10px;
  }
}

.blesk-full-nav__header {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.blesk-full-nav__header .logo-2024 svg {
  width: auto;
  height: 48px;
}

.blesk-full-nav__logo {
  width: 136px;
  height: 48px;
}

.blesk-full-nav__logo:focus {
  outline: none;
}

.blesk-full-nav__search {
  margin: auto auto auto 60px;
  position: relative;
}

@media (max-width: 1099px) {
  .blesk-full-nav__search {
    flex: 1;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__search {
    order: 1;
    min-width: 100%;
    margin: 20px 0 0;
  }
}

.blesk-full-nav__search-icon {
  opacity: .65;
  cursor: pointer;
  background: none;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.blesk-full-nav__search-icon:focus {
  outline: none;
}

.blesk-full-nav__close-button {
  cursor: pointer;
  will-change: transform;
  will-change: opacity;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  transition: transform .2s ease-in-out;
  display: flex;
}

.blesk-full-nav__close-button:hover {
  opacity: .8;
}

.blesk-full-nav__close-button:active {
  opacity: .6;
}

.blesk-full-nav__close-button:hover {
  transform: rotate(90deg);
}

.blesk-full-nav__close-button:focus {
  outline: none;
}

.blesk-full-nav__close-button svg {
  width: 25px;
  height: 25px;
}

.blesk-full-nav__close-button path {
  fill: #fff;
}

.blesk-full-nav__main {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__main {
    float: left;
    margin-right: 35px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__main {
    order: 1;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__main {
    width: 745px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__list-section {
    min-width: 160px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-section:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section:last-child {
    margin-bottom: 0;
  }
}

.blesk-full-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .blesk-full-nav__list {
    flex-wrap: wrap;
    display: flex;
  }
}

.blesk-full-nav__lists-group {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-full-nav__lists-group {
    min-width: 355px;
    margin-bottom: -35px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group {
    flex-direction: column;
  }
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section {
  margin-bottom: 35px;
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section:nth-child(2n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group .blesk-full-nav__list-section {
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-title {
  color: #fff;
  border-bottom: 2px solid #ed1c24;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__list-title a {
  text-decoration: none;
  color: inherit !important;
}

.blesk-full-nav__list-title a:hover {
  text-decoration: underline;
}

.blesk-full-nav__list-item {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-item {
    width: 50%;
    margin-bottom: 15px;
  }
}

.blesk-full-nav__list-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__list-link {
  text-decoration: none;
}

.blesk-full-nav__list-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-link {
    font-size: 15px;
  }
}

.blesk-full-nav__sidebar--projects {
  clear: right;
  margin-bottom: 30px;
  padding-top: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--projects {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar {
    float: right;
    width: 160px;
    padding-top: 25px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
}

.blesk-full-nav__sidebar--specials {
  margin-bottom: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--specials {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group {
    display: flex;
  }
}

.blesk-full-nav__sidebar-group:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group--other {
    margin-bottom: 15px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-group--other {
    margin-right: 0;
  }
}

.blesk-full-nav__sidebar-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-list {
    flex-wrap: wrap;
    display: flex;
  }

  .blesk-full-nav__sidebar-list--projects {
    margin-right: -30px;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__sidebar-list--projects {
    margin-right: 0;
  }
}

.blesk-full-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 20px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
}

@media (min-width: 1100px) {
  .blesk-full-nav__premium-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__premium-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__stardance-tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 108px 18px;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 18px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__zazrak-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9274978_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 63px 19px;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 19px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__zazrak-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__zazrak-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
  justify-content: flex-start;
  text-decoration: none;
  position: relative;
}

.blesk-full-nav__ikiosek-link:hover, .blesk-full-nav__bazar-link:hover {
  text-decoration: underline;
}

@media (max-width: 1099px) {
  .blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
    width: auto;
  }
}

.blesk-full-nav__bazar-link {
  margin-top: 20px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__bazar-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-right: 10px;
  }

  .blesk-full-nav__sidebar-specials-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

.blesk-full-nav__sidebar-specials-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__sidebar-specials-link {
  background-color: #fff;
  border-radius: 2px;
  padding: 0 15px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
  color: #1e1e1e !important;
}

.blesk-full-nav__sidebar-specials-link:hover {
  text-decoration: underline;
}

.blesk-full-nav__sidebar-projects-item {
  margin-bottom: 20px;
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-projects-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-projects-item {
    align-items: center;
    width: 25%;
    padding-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-projects-item {
    width: calc(50% - 10px);
    padding-right: 0;
  }

  .blesk-full-nav__sidebar-projects-item:nth-child(odd) {
    margin-right: 20px;
  }
}

.blesk-full-nav__footer {
  border-top: 1px solid rgba(255, 255, 255, .5);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__footer {
    margin-top: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__footer {
    flex-direction: column;
    order: 1;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__footer {
    flex-flow: column wrap;
    align-items: center;
  }
}

.blesk-full-nav__socials {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__socials {
    order: 2;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__socials-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-full-nav__socials-item {
  margin-right: 15px;
}

.blesk-full-nav__socials-item:last-child {
  margin-right: 0;
}

.blesk-full-nav__socials-link {
  will-change: opacity;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-full-nav__socials-link:hover {
  opacity: .8;
}

.blesk-full-nav__socials-link:active {
  opacity: .6;
}

.blesk-full-nav__socials-link:focus {
  outline: none;
}

.blesk-full-nav__contacts {
  align-items: center;
  font-size: 15px;
  line-height: 17px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__contacts {
    order: 2;
  }
}

.blesk-full-nav__contacts-phone {
  border-left: 1px solid #fff;
  margin-left: 10px;
  padding-left: 10px;
}

.blesk-full-nav__applications {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications {
    flex-direction: column;
    order: 1;
    align-items: center;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__applications-titles {
  flex-direction: column;
  margin-right: 12px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-titles {
    margin-right: 0;
  }
}

.blesk-full-nav__applications-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__applications-title > a {
  text-decoration: none;
}

.blesk-full-nav__applications-title > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-subtitle {
    text-align: center;
  }
}

.blesk-full-nav__applications-subtitle > a {
  text-decoration: none;
}

.blesk-full-nav__applications-subtitle > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-stores {
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-stores {
    margin-top: 32px;
  }
}

.blesk-full-nav__applications-google {
  margin-right: 8px;
  line-height: 0;
}

.blesk-full-nav__applications-apple {
  line-height: 0;
}

.blesk-header {
  z-index: 10001;
  color: #fff;
  width: 100%;
  min-width: 1080px;
  height: 126px;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.blesk-header, .blesk-header *, .blesk-header:before, .blesk-header:after, .blesk-header :before, .blesk-header :after {
  box-sizing: border-box;
}

.blesk-header--responsive {
  min-width: 0;
}

@media (min-width: 1100px) {
  .blesk-header--responsive {
    min-width: 1080px;
    height: 125px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1099px) {
  .blesk-header--responsive {
    height: auto;
  }
}

.blesk-header img {
  display: block;
}

.blesk-header a:hover {
  color: inherit;
}

.blesk-header a:focus {
  outline: none;
}

.blesk-header__link {
  color: #fff;
  text-decoration: none;
}

.blesk-header__link:not([href]):hover {
  text-decoration: none;
}

.blesk-header__top {
  background-color: #ed1c24;
}

@media (max-width: 767px) {
  .blesk-header__top {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.blesk-header__bottom {
  background-color: #ed1c24;
}

@media (min-width: 768px) {
  .blesk-header__bottom {
    background-color: #fff;
    height: 44px;
  }
}

.blesk-header__container {
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .blesk-header--full-width .blesk-header__container {
    width: auto;
    min-width: 1110px;
    max-width: 1620px;
    padding: 0 30px;
  }

  .blesk-header__container {
    width: 1080px;
  }
}

.blesk-header__magazines {
  height: 0;
}

@media (min-width: 1100px) {
  .blesk-header__magazines {
    height: 34px;
  }
}

@media (max-width: 1099px) {
  .blesk-header__magazines {
    display: none;
  }

  .blesk-header--breaking-visible .blesk-header__magazines {
    display: block;
  }
}

.blesk-header__magazines--hide {
  display: none !important;
}

.blesk-header__inner {
  align-items: center;
  padding: 4px 0 0;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    justify-content: space-between;
    padding: 8px 0;
  }
}

.blesk-header__logo {
  margin: 4px 30px 10px 0;
}

@media (max-width: 767px) {
  .blesk-header__logo {
    display: none;
  }
}

.blesk-header__day-info {
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-header__day-info {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .blesk-header__day-info {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .blesk-header__day-info {
    font-size: 11px;
  }
}

.blesk-header__day-info a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .blesk-header__date {
    padding-right: 10px;
  }

  .blesk-header__name-day {
    border-left: 1px solid #fff;
    padding-left: 10px;
  }
}

.blesk-header__weather {
  color: #fff;
  will-change: opacity;
  align-self: stretch;
  align-items: center;
  padding: 0 10px 0 30px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__weather:hover {
  opacity: .8;
}

.blesk-header__weather:active {
  opacity: .6;
}

.blesk-header__weather:hover {
  text-decoration: none;
}

.blesk-header__weather:after {
  content: "";
  z-index: 1;
  border-top: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #fff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%)rotate(45deg);
}

@media (min-width: 768px) {
  .blesk-header__weather {
    margin-right: 30px;
  }
}

.blesk-header__weather-icon {
  height: 26px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.blesk-header__daily-feed {
  will-change: opacity;
}

.blesk-header__daily-feed:hover {
  opacity: .8;
}

.blesk-header__daily-feed:active {
  opacity: .6;
}

@media (min-width: 768px) {
  .blesk-header__daily-feed {
    display: none;
  }
}

.blesk-header-nav .blesk-header__daily-feed {
  width: 22px;
  height: 22px;
  margin-inline-start: auto;
  margin-inline-end: 16px;
  position: relative;
}

.blesk-header-nav .blesk-header__daily-feed svg {
  width: 100%;
  height: 100%;
}

.blesk-header__login {
  color: #fff;
  will-change: opacity;
  flex-direction: column;
  align-items: center;
  max-height: 18px;
  font-size: 9px;
  line-height: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__login:hover {
  opacity: .8;
}

.blesk-header__login:active {
  opacity: .6;
}

.blesk-header__login--desktop {
  margin-top: -2px;
}

.blesk-header__login:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-header__login {
    display: none;
  }
}

.blesk-header-nav .blesk-header__login {
  color: #fff;
  flex-direction: row;
  max-height: none;
  font-size: 11px;
  font-weight: 400;
  display: flex;
}

.blesk-header-nav .blesk-header__login:hover {
  color: #fff;
}

@media (min-width: 768px) {
  .blesk-header-nav .blesk-header__login {
    display: none;
  }
}

.blesk-header__login:has(.blesk-header__login-text--initials) {
  text-align: center;
  color: red;
  background: #fff;
  border-radius: 51%;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-weight: 700;
  line-height: 18px;
}

.blesk-header__login:has(.blesk-header__login-text--initials):hover {
  color: red;
  text-decoration: none;
}

.blesk-header__login-image {
  border-radius: 51%;
  margin-bottom: 2px;
}

@media (max-width: 767px) {
  .blesk-header__login-image {
    width: 22px;
    height: 22px;
  }

  .blesk-header-nav .blesk-header__login-image {
    order: 1;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 0;
  }
}

.blesk-header__search-icon {
  cursor: pointer;
  will-change: opacity;
  background: none;
  border: none;
  min-width: 18px;
  margin-left: 15px;
  padding: 0;
  transition: opacity .2s linear;
}

.blesk-header__search-icon:hover {
  opacity: .8;
}

.blesk-header__search-icon:active {
  opacity: .6;
}

.blesk-header__search-icon:focus {
  outline: none;
}

@media (max-width: 767px) {
  .blesk-header__search-icon {
    display: none;
  }

  .body--article .blesk-header__top .cnc-menu nav, .body--article .blesk-header__inner, .body--article .blesk-header-nav__menu-list, .body--article .blesk-header__search-icon, .body--article .blesk-header-nav__ikiosek-link, .body--article .blesk-header-nav__premium-link {
    display: none !important;
  }
}

.blesk-header-magazines .cnc-menu {
  height: 34px;
  margin-bottom: 0;
  line-height: 34px;
  box-shadow: none !important;
}

.blesk-header-magazines .cnc-menu:after {
  content: none !important;
}

@media (min-width: 1100px) {
  .blesk-header-magazines .cnc-menu {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

.blesk-header-magazines .cnc-menu nav {
  z-index: auto;
  background: none;
  width: auto;
  min-height: auto;
  top: auto;
  left: auto;
}

.blesk-header-magazines .cnc-menu li ul {
  top: 34px;
}

.blesk-header-magazines .cnc-menu li ul li a {
  padding: 0;
}

.blesk-header-magazines .cnc-menu__list {
  align-items: center;
  display: flex;
}

.blesk-header-magazines .cnc-menu__list:after {
  content: none;
}

.blesk-header-magazines .cnc-menu__list a {
  padding: 0 20px;
  color: rgba(255, 255, 255, .6) !important;
  cursor: pointer !important;
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, .2) !important;
  margin: 0 !important;
}

.blesk-header-magazines .cnc-menu__list a:hover {
  color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:hover:before, .blesk-header-magazines a[href*="javascript"]:hover:after {
  background-color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:before, .blesk-header-magazines a[href*="javascript"]:after {
  pointer-events: none !important;
  background-color: rgba(255, 255, 255, .6) !important;
}

.blesk-header-magazines li:last-child a {
  border-right: none !important;
}

.blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
  height: 34px !important;
}

@media (max-width: 959px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    height: 75px !important;
  }
}

@media (max-width: 321px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    max-height: 94px;
  }
}

.blesk-header-nav {
  color: #1e1e1e;
  will-change: transform;
  background-color: #fff;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav {
    background-color: #ed1c24;
    justify-content: space-between;
  }
}

.blesk-header-nav a {
  color: inherit;
  font-family: inherit;
}

.blesk-header-nav a:focus {
  outline: none;
}

@media (min-width: 768px) {
  .blesk-header-nav__logo {
    min-width: 182px;
    height: 74px;
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__logo {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.blesk-sticky-header .blesk-header-nav__logo {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-right: 30px;
  display: flex;
}

.blesk-header-nav__hamburger-button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 0 0 0 17px;
  position: relative;
}

.blesk-header-nav__hamburger-button:focus {
  outline: none;
}

.blesk-header-nav__hamburger-button:before {
  content: "";
  color: #1e1e1e;
  background-color: currentColor;
  width: 13px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 4px, 0 -4px;
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button:before {
    color: #fff;
    width: 20px;
    height: 3px;
    box-shadow: 0 6px, 0 -6px;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__hamburger-button {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button {
    order: -1;
    width: 14px;
    font-size: 0;
  }
}

.blesk-header-nav__menu-list {
  align-items: center;
  margin: 0 auto 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-sticky-header .blesk-header-nav__menu-list {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .blesk-header-nav__menu-list {
    display: none;
  }
}

.blesk-header-nav__menu-item {
  margin-right: 23px;
}

.blesk-sticky-header .blesk-header-nav__menu-item {
  white-space: nowrap;
  order: 0;
}

.blesk-sticky-header .blesk-header-nav__menu-item--show {
  visibility: visible;
}

.blesk-sticky-header .blesk-header-nav__menu-item--hidden {
  visibility: hidden;
  order: 1;
  display: block;
}

.blesk-header-nav__menu-item:last-child {
  margin-right: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav__menu-item:nth-child(n+8) {
    display: none;
  }
}

.blesk-header-nav__menu-link {
  text-decoration: none;
}

.blesk-header-nav__menu-link:hover {
  text-decoration: underline;
}

.blesk-header-nav__menu-link.menu-link_soutez {
  color: #000;
  text-transform: uppercase;
  background-color: #ffcf00;
  padding: 5px;
}

.blesk-header-nav__menu-link.menu-link_soutez:hover {
  color: #000;
}

.blesk-header-nav__search-icon {
  display: none;
}

@media (min-width: 768px) {
  .blesk-sticky-header .blesk-header-nav__search-icon {
    display: block;
  }
}

.blesk-header-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  width: 83px;
  min-width: 83px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

.blesk-sticky-header .blesk-header-nav__premium-link {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .blesk-header-nav__premium-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__premium-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 108px;
  min-width: 108px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__tipovacka-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__tipovacka-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__zazrak-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9274978_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 63px;
  min-width: 63px;
  height: 19px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__zazrak-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__zazrak-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__secondary-logo {
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo {
  color: inherit;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 26px;
  line-height: 29px;
  display: block;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-logo {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  margin: -5px 0 0;
  display: block;
}

.blesk-header-nav__secondary-menu-list {
  margin-right: auto;
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-menu-list {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu {
  overflow-x: unset;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item.blesk-header-nav__menu-item--hidden, .blesk-sticky-header .subcategory-menu__list ~ .blesk-header-nav__menu-list {
  display: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item {
  color: #000;
  margin-right: 25px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:last-child {
  margin-right: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--hidden {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--with-submenu:hover {
  background: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:not(.subcategory-menu__item--with-submenu) {
  padding-right: 0;
}

.blesk-sticky-header .subcategory-menu__item {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__link {
  font-size: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
}

.blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.blesk-sticky-header {
  z-index: 9999;
  align-items: center;
  min-height: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@media (min-width: 768px) {
  .blesk-sticky-header {
    padding-left: 0;
  }
}

.blesk-sticky-header, .blesk-sticky-header *, .blesk-sticky-header:before, .blesk-sticky-header:after, .blesk-sticky-header :before, .blesk-sticky-header :after {
  box-sizing: border-box;
}

.writer {
  background-color: #fff;
  margin: 0 0 10px;
  padding: 15px;
}

.writer__header {
  border-bottom: 1px solid #cad5df;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  padding: 0 0 10px;
  display: flex;
}

.writer-header__left {
  align-items: center;
  margin: 0;
  display: flex;
}

.writer__image {
  width: 70px;
  height: 70px;
}

.writer__image .loading-placeholder {
  border-radius: 50%;
}

.writer__name {
  color: red;
  margin: 0 0 0 20px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 19px;
}

.writer-header__right {
  flex-wrap: nowrap;
  margin: 0;
  display: flex;
}

.writer__description {
  color: #16212d;
  font-size: 16px;
  line-height: 26px;
}

.writer-list {
  margin: 0;
  padding-top: 0;
  position: relative;
  left: 350px;
}

.writer-list--opened .writer-list__inner {
  background-color: #16212d;
}

.writer-list--opened .writer-list__arrow-down {
  transform: rotate(180deg);
}

.writer-list--opened .writer-list__list {
  opacity: 1;
  display: block;
}

.writer-list__inner {
  z-index: 10;
  text-align: right;
  flex-direction: column;
  width: 270px;
  transition: all .4s ease-in-out;
  display: flex;
  position: absolute;
  top: -10px;
}

.writer-list__header {
  color: red;
  cursor: pointer;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: center;
  width: 120px;
  padding: 13px 0 7px;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.writer-list__arrow-down {
  border-top: 7px solid red;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  margin: 0 15px 0 5px;
  transition: all .6s;
}

.writer-list__list {
  opacity: 0;
  padding: 10px 20px 12px 15px;
  display: none;
}

.writer-list__author {
  border-bottom: 1px solid #4f5561;
  align-items: center;
  display: flex;
}

.writer-list__author--last {
  border: none;
}

.writer-list__author-photo {
  padding: 6px 0;
}

.writer-list__author-photo img {
  border-radius: 15px;
  width: 30px;
  height: 30px;
}

.writer-list__author-name {
  text-align: left;
  width: 195px;
  margin: 0 0 0 10px;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.writer-list__author-name a {
  color: #fff;
}

h2.headAuthArt {
  margin: 0 0 0 10px;
  padding: 0;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.actual-edition {
  text-align: center;
  background-color: #fff;
  border: 1px solid #cad5df;
  width: 300px;
  margin: 0 auto;
  padding: 30px 0;
}

@media only screen and (max-width: 427px) {
  .actual-edition {
    margin-top: 15px;
  }
}

.actual-edition a {
  width: 100%;
  display: block;
}

.actual-edition a:hover {
  text-decoration: none;
}

.actual-edition h2 {
  letter-spacing: normal;
  text-align: center;
  color: #000;
  height: 31px;
  margin: auto auto 10px;
  font-family: tablet_gothic_condensed;
  font-size: 27px;
  font-style: normal;
  font-weight: bold;
  font-stretch: condensed;
}

.actual-edition .actual-edition__btn {
  color: #fff;
  letter-spacing: normal;
  background-color: red;
  width: 197px;
  margin: 10px auto auto;
  padding: 12px 0 13px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.15;
}

.actual-edition .actual-edition__btn:after {
  content: "▸";
  float: right;
  margin-top: -14px;
  margin-right: 14px;
}

.moje-zdravi-links {
  background-color: #fff;
  width: 300px;
  margin: 0 auto;
  padding: 35px 0 30px;
  display: block;
}

@media only screen and (min-width: 427px) {
  .moje-zdravi-links {
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
  }
}

@media only screen and (max-width: 427px) {
  .moje-zdravi-links {
    width: initial;
    margin: 10px;
    padding: 10px;
  }
}

@media only screen and (max-width: 321px) {
  .moje-zdravi-links {
    padding-bottom: 20px;
  }
}

.moje-zdravi-links__logo {
  background-image: url("/images/moje-zdravi/dist/svg/logo.svg");
  background-repeat: no-repeat;
  background-size: 170px 29px;
  width: 100%;
  height: 29px;
  display: block;
}

.moje-zdravi-links__items:after {
  content: "";
  clear: both;
  display: table;
}

.moje-zdravi-links__item {
  text-align: left;
  float: left;
  background-position: 15px 5px;
  width: 50%;
  height: 50px;
  margin: 20px 0 0;
}

@media only screen and (min-width: 321px) and (max-width: 376px) {
  .moje-zdravi-links__item {
    background-position: 25px 5px;
    width: calc(50% - 15px);
    padding-left: 15px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 426px) {
  .moje-zdravi-links__item {
    background-position: 35px 5px;
    width: calc(50% - 25px);
    padding-left: 25px;
  }
}

.moje-zdravi-links__item-title {
  color: #7200fe;
  float: left;
  text-transform: uppercase;
  min-width: 50%;
  padding-left: 45px;
  font-family: tablet_gothic_condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  font-stretch: condensed;
  line-height: 1.94;
  display: inline-block;
}

.moje-zdravi-links__item-subtitle {
  color: #000;
  padding-left: 5px;
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
}

.moje-zdravi-links__item--disease {
  background-image: url("/images/moje-zdravi/dist/svg/disease.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.moje-zdravi-links__item--priznaky {
  background-image: url("/images/moje-zdravi/dist/svg/priznaky.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.moje-zdravi-links__item--bylinky {
  background-image: url("/images/moje-zdravi/dist/svg/bylinky.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.moje-zdravi-links__item--sthetoscope {
  background-image: url("/images/moje-zdravi/dist/svg/sthetoscope.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.infobox.mff-kv {
  box-sizing: border-box;
  float: left;
  background-color: #fff;
  width: 300px;
  margin: 10px 10px 0;
  padding: 15px 20px 20px;
}

.infobox.mff-kv .infobox__title {
  float: left;
  text-transform: uppercase;
  color: red;
  word-spacing: 1px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 14px;
  position: relative;
}

.infobox.mff-kv .infobox__body {
  float: left;
  font-size: 15px;
  line-height: 1.2em;
  position: relative;
}

.infobox.mff-kv .infobox__body p:not(:last-child) {
  margin-bottom: 20px;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.organization {
  border-bottom: 1px solid #c8cdd2;
}

.organization p {
  line-height: 20px;
}

.organization h2, .organization h3 {
  color: #eb1e2d;
  margin: 10px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

.organization h2 {
  margin-top: 20px !important;
  font-size: 22px !important;
}

.organization h3:not(.organization__title) {
  font-size: 18px;
}

.organization ul li {
  padding: 5px 0;
}

.organization ul {
  margin: 0;
  padding: 0;
}

.organization__title {
  cursor: pointer;
  color: #eb1e2d;
  text-transform: uppercase;
  outline: none;
  min-height: 0;
  margin: 15px 0;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2em;
  display: block;
  position: relative;
}

.organization__title-arrow {
  border: 3px solid #000;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 15px;
  transform: rotate(-45deg);
}

.organization__title-arrow--down {
  transform: rotate(45deg);
}

.organization__detail {
  color: #1e2d3c;
  padding: 15px 10px;
  font-family: arial, sans-serif;
  font-size: 20px;
  line-height: 1.2em;
}

.organization__detail--visible {
  display: block;
}

.organization__slide-fade-enter-active {
  transition: all .3s;
}

.organization__slide-fade-leave-active {
  transition: all .5s;
}

.organization__slide-fade-enter, .organization__slide-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.organizations-carousel {
  width: 100%;
  display: block;
}

.organizations-carousel__item {
  text-align: center;
  color: #929ba3;
  z-index: 0;
  background-color: #eff2f5;
  border: 1px solid #cad5df;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 30px;
  margin: 0 5px 0 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
}

.organizations-carousel .glide__track {
  position: relative;
}

.glide__arrow {
  background: linear-gradient(270deg, rgba(9, 9, 121, 0) 0%, #fff 100%);
}

.organizations-carousel .glide__arrow {
  border: 0;
  width: 30px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.glide__arrow--right {
  background: linear-gradient(90deg, rgba(9, 9, 121, 0) 0%, #fff 100%);
}

.organizations-carousel .glide__arrow--right {
  right: 0;
  left: initial;
}

.organizations-carousel .glide__arrow-left-icon {
  border: 3px solid #eb1e2d;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  transform: rotate(135deg);
}

.organizations-carousel .glide__arrow-right-icon {
  border: 3px solid #eb1e2d;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
  transform: rotate(-45deg);
}

.organizations-carousel .glide__slide--active {
  border-bottom: 1px solid #eb1e2d;
}

.koronahelpdesk-artobj {
  background-color: #fff;
  border: 1px solid #000;
  margin: 5px 0;
  font-family: Karma, arial, sans-serif;
}

.koronahelpdesk-artobj a {
  color: #000;
  text-decoration: none !important;
  transition: none !important;
}

.koronahelpdesk-artobj a:hover, .koronahelpdesk-artobj a:focus {
  color: #d0112b !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
}

.koronahelpdesk-artobj__info {
  display: flex;
}

.koronahelpdesk-artobj__info a:hover, .koronahelpdesk-artobj__info a:focus {
  text-decoration: none;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description {
  box-sizing: border-box;
  text-align: center;
  background-color: #d0112b;
  width: 278px;
  padding: 24.5px 20px;
  position: relative;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description:before, .koronahelpdesk-artobj__info .koronahelpdesk-artobj__description:after {
  content: "";
  background-color: #d0112b;
  position: absolute;
  left: -1px;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description:before {
  width: calc(100% + 1px);
  height: 1px;
  top: -1px;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description:after {
  width: 1px;
  height: 100%;
  top: 0;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__title {
  color: #fff;
  font-weight: 700;
  line-height: initial;
  padding: 0;
  margin: 0 0 4px !important;
  font-family: Karma, arial, sans-serif !important;
  font-size: 25px !important;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__title:hover, .koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__title:focus {
  text-decoration: none;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__perex {
  color: #fff;
  height: 20px;
  padding: 0;
  font-size: 15px;
  font-weight: 700;
  margin: 0 !important;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__perex:hover, .koronahelpdesk-artobj__info .koronahelpdesk-artobj__description .koronahelpdesk-artobj__perex:focus {
  text-decoration: none;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__links {
  border-bottom: thin solid #dcdcdc;
  justify-content: center;
  align-items: center;
  width: 302px;
  margin: 0 0 0 20px;
  padding: 17px 0;
  display: flex;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__link {
  height: 65px;
  margin-right: 50px;
  font-weight: 700;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__link-text {
  height: 20px;
  font-size: 15px;
  display: inline-block;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__link:last-child {
  margin: 0;
}

.koronahelpdesk-artobj__info .koronahelpdesk-artobj__link:hover > span, .koronahelpdesk-artobj__info .koronahelpdesk-artobj__link:focus > span {
  text-decoration: underline !important;
}

.koronahelpdesk-artobj__articles {
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px 20px 15px;
  display: flex;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article {
  max-width: 415px;
  display: flex;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-info {
  flex-direction: column;
  margin: 0 0 0 12px;
  display: flex;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-category {
  color: #aaa;
  padding: 0;
  font-size: 13px;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-category:hover, .koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-category:focus {
  color: #aaa;
  text-decoration: underline;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-title {
  padding: 0;
  line-height: 22px;
  margin: 0 !important;
  font-family: Karma, arial, sans-serif !important;
  font-size: 15px !important;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-title a:hover, .koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article .koronahelpdesk-artobj__article-title a:focus {
  color: #000;
  text-decoration: underline;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary {
  width: 200px;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary a:hover, .koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary a:focus {
  color: #000;
  text-decoration: none;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary a:hover .koronahelpdesk-artobj__article-perex, .koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary a:focus .koronahelpdesk-artobj__article-perex {
  text-decoration: underline;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary .koronahelpdesk-artobj__article-title {
  font-size: 25px;
  line-height: 32px;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article--primary .koronahelpdesk-artobj__article-perex {
  padding: 0;
  font-size: 13px;
  font-weight: 700;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__article:last-child {
  margin: 0;
}

.koronahelpdesk-artobj__articles .koronahelpdesk-artobj__more {
  box-sizing: border-box;
  border-left: 1px solid #dcdcdc;
  height: 80px;
  padding: 30px 0 30px 38px;
  font-size: 15px;
}

.koronahelpdesk-artobj__icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 56px;
  height: 42px;
  margin: 0 auto 4px;
  display: block;
}

.koronahelpdesk-artobj__icon--business {
  background-image: url("https://img2.cncenter.cz/images/e15/dist/svg/koronavirus/company.svg");
}

.koronahelpdesk-artobj__icon--citizen {
  background-image: url("https://img2.cncenter.cz/images/e15/dist/svg/koronavirus/citizen.svg");
}

.koronahelpdesk-artobj__icon--right {
  width: 8px;
  height: 10px;
  margin: 0;
  display: inline-block;
}

.koronahelpdesk-artobj__icon--right-black {
  background-image: url("https://img2.cncenter.cz/images/e15/dist/svg/koronavirus/icon-arrow-black.svg");
}

.koronahelpdesk-artobj__icon--right-white {
  background-image: url("https://img2.cncenter.cz/images/e15/dist/svg/koronavirus/icon-arrow-white.svg");
}

#article.listed .enquiry-bars__question, #article .article-body .enquiry-bars__question, .enquiry-bars__question {
  float: none;
  margin: 0 0 24px;
  font-family: tablet_gothic_condensed, Times New Roman, serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}

#article.listed .enquiry-bars-result-notice__link, #article .article-body .enquiry-bars-result-notice__link, .enquiry-bars-result-notice__link {
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: red;
  border-radius: 4px;
  width: 142px;
  height: 40px;
  margin: 24px auto 0;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}

.enquiry-bars {
  clear: both;
  background-color: #fff;
  padding: 15px;
}

.enquiry-bars__title {
  color: #5c6773;
  border-top: 4px solid red;
  margin-bottom: 24px;
  padding-top: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.enquiry-bars-answers__answer {
  background-color: #ffe6e6;
  border-left: 4px solid red;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
}

.enquiry-bars-answers__answer:last-child {
  margin-bottom: 24px;
}

.enquiry-bars-answers__answer--hover:hover {
  cursor: pointer;
  border: 1px solid #a81919;
  border-left-width: 4px;
}

.enquiry-bars-answers__answer--hover:hover .enquiry-bars-answer__statement {
  padding: 7px 15px 7px 16px;
}

.enquiry-bars-answer__indicator {
  background-color: #f6b3bf;
  width: 0;
  height: 44px;
  transition: width 1s linear;
  position: absolute;
}

@keyframes enquiry-bars-indicator-slide {
  from {
    width: 0;
  }

  to {
    width: var(--to-width);
  }
}

.enquiry-bars-answer__statement {
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.enquiry-bars-answer__statement--selected {
  font-weight: 700;
}

.enquiry-bars__result-notice {
  background-color: #ffe6e6;
  margin-bottom: 24px;
  padding: 24px 32px;
}

.enquiry-bars-result-notice__text {
  text-align: center;
  font-family: arial, "sans-serif";
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.enquiry-bars__footer {
  border-bottom: 1px solid #ccd5de;
  justify-content: space-between;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
}

.enquiry-bars-footer__vote-notice {
  color: red;
  font-weight: 700;
}

.enquiry-bars-footer__total-votes {
  text-align: right;
  color: #5c6773;
  margin-left: auto;
}

.enquiry-bars-answers__answer, .enquiry-bars__footer {
  font-size: 17px;
}

.enquiry-bars-result-notice__text {
  font-size: 17px;
  line-height: 26px;
}

.enquiry-bars__footer {
  border-color: #cad5df;
}

#article.listed .enquiry-bars__question, #article .article-body .enquiry-bars__question {
  font-size: 30px;
  line-height: 35px;
}

#article.listed .enquiry-bars-result-notice__link, #article .article-body .enquiry-bars-result-notice__link {
  text-transform: none;
  border-radius: 2px;
  font: 700 18px / 28px tablet_gothic_condensed, Times New Roman, serif;
  text-decoration: none;
}

#article.listed .enquiry-bars-result-notice__link:hover, #article .article-body .enquiry-bars-result-notice__link:hover {
  text-decoration: underline;
}

.domino .enquiry-bars__title {
  border-color: #ff5a00;
}

.domino .enquiry-bars-answers__answer {
  background-color: #ffefe6;
  border-color: #ff5a00;
}

.domino .enquiry-bars-answers__answer--hover:hover {
  border-color: #cc4800;
}

.domino .enquiry-bars-answer__indicator {
  background-color: #ffceb3;
}

.domino .enquiry-bars-footer__vote-notice {
  color: #ff5a00;
}

.domino .enquiry-bars__result-notice {
  background-color: #ffefe6;
}

#article.listed .domino .enquiry-bars-result-notice__link, #article .domino .article-body .enquiry-bars-result-notice__link {
  background-color: #ff5a00;
}

.jade .enquiry-bars__title {
  border-color: #00b373;
}

.jade .enquiry-bars-answers__answer {
  background-color: #c6f7e5;
  border-color: #00b373;
}

.jade .enquiry-bars-answers__answer--hover:hover {
  border-color: #008f5c;
}

.jade .enquiry-bars-answer__indicator {
  background-color: #9ae8cc;
}

.jade .enquiry-bars-footer__vote-notice {
  color: #00b373;
}

.jade .enquiry-bars__result-notice {
  background-color: #c6f7e5;
}

#article.listed .jade .enquiry-bars-result-notice__link, #article .jade .article-body .enquiry-bars-result-notice__link {
  background-color: #00b373;
}

.widget-product-banner {
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-banner .product-image {
  width: 30%;
  margin-right: 3%;
}

.widget-product-banner .product-image .product-image-thumbnail {
  border: 1px solid #f5f5f5;
  width: 100%;
}

.widget-product-banner .product-description {
  flex-direction: column;
  align-items: flex-start;
  width: 67%;
  display: flex;
}

.widget-product-banner .product-description .product-name .product-link {
  color: #d00000;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: underline;
}

.widget-product-banner .product-description .product-availability {
  font-size: 1.3em;
}

.widget-product-banner .product-description .product-availability .product-price-range {
  white-space: nowrap;
  font-weight: 700;
}

.widget-product-banner .product-description .product-compare-link {
  color: #fff;
  text-transform: uppercase;
  background: #d00000;
  border-radius: 5px;
  margin-top: 7px;
  padding: 2px 10px;
  font-size: 1.2em;
  font-weight: 700;
  display: inline-block;
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .widget-product-banner .product-description .product-name .product-link {
    font-size: 16px;
    line-height: 25px;
  }

  .widget-product-banner .product-description .product-availability {
    font-size: 13px;
    line-height: 25px;
  }

  .widget-product-banner .product-description .product-compare-link {
    font-size: 12px;
    line-height: 25px;
  }
}

.widget-product-banner-with-shops {
  border-top: 2px solid #d00000;
  border-bottom: 2px solid #d00000;
  flex-direction: column;
  margin: 20px 0;
  padding: 2em 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-banner-with-shops .product-name {
  margin: 0 0 2em;
}

.widget-product-banner-with-shops .product-name .product-link {
  color: #d00000;
  font-size: 2em;
  font-weight: 700;
  line-height: 2.4em;
  text-decoration: underline;
}

.widget-product-banner-with-shops .product-image {
  border: 1px solid #f5f5f5;
}

.widget-product-banner-with-shops .product-image img {
  max-width: 100%;
}

.widget-product-banner-with-shops .product-shops .product-shop {
  border-top: 1px solid #dcdcdc;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: .7em 0;
  padding: .7em 0;
  display: flex;
}

.widget-product-banner-with-shops .product-shops .product-shop:first-of-type {
  border-top: 0;
}

.widget-product-banner-with-shops .product-shops .product-shop .shop-logo {
  width: 80px;
}

.widget-product-banner-with-shops .product-shops .product-shop .shop-price-button {
  color: #fff;
  text-transform: none;
  background: #d00000;
  border-radius: 5px;
  padding: .2em 2.3em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 2em;
  display: inline-block;
  text-decoration: none !important;
}

.widget-product-banner-with-shops .product-shops .product-show-more {
  text-align: center;
  color: #d00000;
  font-size: 1.7em;
  font-weight: 700;
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .widget-product-banner-with-shops {
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: 25px 0;
  }

  .widget-product-banner-with-shops .product-name {
    margin: 0 0 20px;
  }

  .widget-product-banner-with-shops .product-name .product-link {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex {
    flex-direction: row;
    display: flex;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-image {
    order: 2;
    width: 46%;
    margin-left: 2%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-image img {
    border: 1px solid #dcdcdc;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops {
    width: 52%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop {
    border-top: 1px solid #dcdcdc;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 0;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop:first-of-type {
    border-top: 0;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop .shop-logo img {
    width: 100%;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-shop .shop-price-button {
    text-align: center;
    min-width: 130px;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 22px;
  }

  .widget-product-banner-with-shops .widget-product-banner-with-shops--flex .product-shops .product-show-more {
    font-size: 16px;
  }
}

.widget-product-price-range {
  font-size: 1em;
  line-height: inherit;
  display: inline-block;
  color: #d00000 !important;
}

.widget-product-price-range-button {
  color: #d00000;
  flex-justify-content: flex-start;
  flex-align-items: center;
  flex-flow: wrap;
  width: 100%;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-price-range-button .product-price {
  color: #000;
  font-size: 2em;
  font-weight: 700;
}

.widget-product-price-range-button .product-price-range {
  margin: 0 .5em;
  font-size: 2em;
  text-decoration: underline;
  color: #d00000 !important;
}

.widget-product-price-range-button .product-compare-link {
  color: #fff;
  text-transform: none;
  background: #d00000;
  border-radius: 5px;
  margin: .5em 0 0;
  padding: .2em 2.3em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 2em;
  display: inline-block;
  text-decoration: none !important;
}

@media screen and (min-width: 768px) {
  .widget-product-price-range-button .product-price, .widget-product-price-range-button .product-price-range {
    font-size: 16px;
  }

  .widget-product-price-range-button .product-compare-link {
    text-transform: uppercase;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 22px;
  }
}

.widget-product-most-popular {
  border-top: 2px solid #d00000;
  border-bottom: 2px solid #d00000;
  flex-direction: column;
  margin: 20px 0;
  padding: 2em 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-most-popular .most-popular-title {
  color: #d00000;
  margin: 0 0 1em;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.2em;
}

.widget-product-most-popular .product {
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2em;
  display: flex;
}

.widget-product-most-popular .product .product-image {
  border: 1px solid #f5f5f5;
  width: 30%;
  margin-right: 3%;
}

.widget-product-most-popular .product .product-description {
  flex-direction: column;
  align-items: flex-start;
  width: 67%;
  display: flex;
}

.widget-product-most-popular .product .product-description .product-name {
  color: #d00000;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: underline;
}

.widget-product-most-popular .product .product-description .product-availability {
  font-size: 1.3em;
}

.widget-product-most-popular .product .product-description .product-availability .product-price-range {
  white-space: nowrap;
  font-weight: 700;
}

.widget-product-most-popular .product .product-description .product-compare-link {
  color: #fff;
  text-transform: uppercase;
  background: #d00000;
  border-radius: 5px;
  margin-top: 7px;
  padding: 2px 10px;
  font-size: 1.2em;
  font-weight: 700;
  display: inline-block;
  text-decoration: none !important;
}

.widget-product-most-popular .product-show-more {
  text-align: center;
  color: #d00000;
  font-size: 1.7em;
  font-weight: 500;
  text-decoration: underline;
  display: block;
}

.widget-product-most-popular-mini {
  flex-direction: column;
  margin: 20px 0;
  font-size: 10px;
  line-height: 20px;
  display: flex;
}

.widget-product-most-popular-mini .most-popular-title {
  margin: 0 0 1em;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 1.2em;
}

.widget-product-most-popular-mini ul {
  margin: 0;
  padding-left: 30px;
  list-style: outside;
}

.widget-product-most-popular-mini .product {
  margin-bottom: .5em;
  font-size: 1.6em;
  line-height: 1.4em;
}

.widget-product-most-popular-mini .product:before {
  display: none;
}

.widget-product-most-popular-mini .product .product-name {
  color: #d00000;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.3em;
  text-decoration: underline;
}

.widget-product-most-popular-mini .product .product-price-range {
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .widget-product-most-popular {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .widget-product-most-popular .most-popular-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
  }

  .widget-product-most-popular .product .product-description .product-name {
    font-size: 16px;
    line-height: 25px;
  }

  .widget-product-most-popular .product .product-description .product-availability {
    font-size: 13px;
    line-height: 25px;
  }

  .widget-product-most-popular .product .product-description .product-compare-link {
    font-size: 12px;
    line-height: 25px;
  }

  .widget-product-most-popular .product-show-more, .widget-product-most-popular-mini .most-popular-title, .widget-product-most-popular-mini .product .product-name, .widget-product-most-popular-mini .product .product-price-range {
    font-size: 16px;
    line-height: 25px;
  }
}

.tag-promo {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}

.tag-promo__title {
  color: #000;
  font-family: Tablet Gothic Condensed, Arial, Helvetica, sans-serif;
  font-size: 31px;
  font-weight: 700;
  line-height: 31px;
}

.tag-promo__image, .tag-promo__image > img {
  width: 100%;
}

.tag-promo__description {
  color: #323232;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.tag-promo__description [data-show-more-hidden="true"] {
  display: none;
}

.tag-promo__description [data-show-more-hidden="false"] {
  display: inline;
}

.tag-promo__show-more {
  color: #323232;
  cursor: pointer;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.tag-promo__divider {
  background: #ccc;
  width: 100%;
  height: 1px;
}

